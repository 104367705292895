import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import MultilineTab from './MultiLine/MultilineTab';
import SinglelineLayout from './SingleLine/SinglelineLayout';
import './ItemDetail.scss'
import CommentsControl from './Comments/CommentsControl';
import ScrollingControl from './ScrollingControl';
import { stateConstants } from '../_constants/state.constants';
import ReleaseTagControl from './ReleaseTagControl';
import SprintControl from './SprintControl';
import DescriptionControl from './DescriptionControl';
import UniqueFieldControl from './UniqueFieldControl';
import HansoftURLLinkControl from './HansoftURLLinkControl'
import LinkedToField from '../Fields/LinkedToField';
import { findFieldByProject } from '../_reducers/singleline.reducer';
import { getProjectInfo } from "../_reducers/project.reducer";
import { isValidObject } from '../_helpers/validation_functions'
import { ImgPipeline, WatchIcon } from '../_images/images';
import Relation from './components/relation';
import { isTaskVisibleInTodo } from "../_reducers/todos.reducer";
import { getLoggedInResourceID, subscribeWithParams, unsubscribeFromSubscriptionId, setField } from "../DDPJS/DDPJS";

import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'

import 'react-reflex/styles.css'
import Attachments from '../Fields/containers/Attachments';
import { Loader } from 'semantic-ui-react';
import { setErrorMessage } from '../MessageDialog/MessageUtilities';
import { MsgLevelEnum, MessageEnum } from '../MessageDialog/MessageIndex';

class RedirectNoLongerVisible extends React.Component {
  componentDidMount() {
    setErrorMessage(MsgLevelEnum.INFORMATION, MessageEnum.NO_LONGER_ASSIGNED);
  }

  render() {
    return <Redirect to={"/"}/>
  }
}

class ItemDetail extends React.Component {
	constructor(props) {
    	super(props);
	
		const taskId = this.props.match.params.id;
		const databaseGUIDHash = this.props.match.params.databaseGUIDHash;

		this.state = {
			height: 0,
			initialLoadDone: false,
			taskId: taskId,
			databaseGUIDHash: databaseGUIDHash,
			subscriptionId: this.subscribeToTask(taskId, databaseGUIDHash),
		};

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.onResizePane = this.onResizePane.bind(this);
		this.layoutState = this.getLayoutState();
	}

	subscribeToTask(taskId, databaseGUIDHash) {
		return subscribeWithParams("Tasks", [ taskId ], {
			onReady: () => {
				this.props.dispatch({
					type: stateConstants.SET_CURRENT_TASK_ID,
					currentTaskID: taskId,
					databaseGUIDHash: databaseGUIDHash,
				});

				this.setState({
					initialLoadDone: true,
				});
			},
			onNoSub: () => {
				setErrorMessage(MsgLevelEnum.INFORMATION, MessageEnum.TASK_NOT_FOUND);

				this.setState({
					taskId: null,
					initialLoadDone: true,
				})

				this.props.dispatch({
					type: stateConstants.CLEAR_CURRENT_TASK_ID,
				});
			},
		});	
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.taskId !== this.props.match.params.id) {
			if (this.state.subscriptionId)
				unsubscribeFromSubscriptionId(this.state.subscriptionId);

			const newTaskId = this.props.match.params.id;
			const newDatabaseGUIDHash = this.props.match.params.databaseGUIDHash;

			this.setState({
				initialLoadDone: false,
				taskId: newTaskId,
				databaseGUIDHash: newDatabaseGUIDHash,
				subscriptionId: this.subscribeToTask(newTaskId, newDatabaseGUIDHash),
			});
		}
	}

	componentDidMount() {
    	this.updateWindowDimensions();
    	window.addEventListener('resize', this.updateWindowDimensions, false);
  	}
  
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions, false);
		if (this.state.subscriptionId)
			unsubscribeFromSubscriptionId(this.state.subscriptionId);

		this.props.dispatch({type: stateConstants.CLEAR_CURRENT_TASK_ID });	
	}

	updateWindowDimensions() {
		let height = window.innerHeight;
		this.setState({ height: height });
  	}

	onResizePane(event) {
    	const { name, flex } = event.component.props;
    	this.layoutState[name].flex = flex;

    	localStorage.setItem(
      		"hs-reflex-itemdetails",
      		JSON.stringify(this.layoutState),
    	);
  	}

	getLayoutState () {
    	const item = localStorage.getItem("hs-reflex-itemdetails");
    	if (item)
      		return JSON.parse(item);

    	return {
    		singleline: {
        		flex: 0.4,
      		},
      		multiline: { 
        		flex: 0.3,
      		},
      		comments: {
        		flex: 0.3,
      		},
    	};
	}

	render() {
		if ((this.props.appState.databaseGUIDHash !== this.state.databaseGUIDHash) || this.state.taskId === null) {
			return <Redirect to={"/"}/>;
		} else if (!this.state.initialLoadDone) {
			return (
				<div className="ItemDetail-Page">
					<Loader active/>
				</div>
			);
		}

    const splitPaneRef = this.refs.SplitPane;
    let myPanelStyle = {...PanelStyle}
    let offset = 0
    if (isValidObject(splitPaneRef)) {
      offset = splitPaneRef.offsetTop + splitPaneRef.parentElement.offsetTop
    }
    const listHeight =  this.state.height - offset
    myPanelStyle.height = listHeight.toString() + 'px'

    const items = this.props.todos;
    const currentId = this.props.match.params.id;

    const currentItem = items.find(function(item) {
      return item.$ID === currentId;
    });

    if (!currentItem)
      return <RedirectNoLongerVisible/>;

    const myElement = document.getElementById('split_pane_container');
    let top = 0;
    if (myElement) {
      const clientRect = myElement.getBoundingClientRect();
      top = clientRect.top;
    }


    let linkedToField = null;
    const projectID = currentItem ? parseInt(currentItem.$ProjectID, 10) : null;
    if (projectID) {
      const projectData = getProjectInfo(projectID);
      linkedToField = findFieldByProject("LinkedTo", projectID, projectData[1] ? projectData[1].id : -1);
    }
    
    return (
      <div className="ItemDetail-Page">
        <div className="itemdetail-topbar">
          <div style={{display: "flex"}}>
            {isValidObject(currentItem)?<ReleaseTagControl task={currentItem} milestones={this.props.milestones}/>:<div />}
            {isValidObject(linkedToField)?
                <LinkedToField key={linkedToField.id} {...linkedToField} itemID={currentItem.$ID} projectID={parseInt(currentItem.$ProjectID, 10)} linkedTo={currentItem.getProperty('LinkedTo')} />
              :
                null
            }
            {isValidObject(currentItem) ?
                <Attachments key={"AttachedDocuments"} task={currentItem} attachedDocuments={currentItem.fields.AttachedDocuments ? currentItem.fields.AttachedDocuments.AttachedDocuments : []}/>
              :
                null
            }
          </div>
          <div style={{display: "flex", flexFlow: "row nowrap"}}>
            {isValidObject(currentItem)?
              <div className="watch" onClick={() => {
                setField("CommentsOptions", currentItem.$ID, currentItem.toggleWatching(getLoggedInResourceID()));
              }}>
                <WatchIcon/>
                <div>{currentItem.isWatching(getLoggedInResourceID()) ? "Unwatch" : "Watch"}</div>
              </div> : null}
            {isValidObject(currentItem)?<HansoftURLLinkControl task={currentItem} theme={this.props.theme} />:null}
            <div>
              {isValidObject(currentItem) && isTaskVisibleInTodo(currentItem, getLoggedInResourceID()) ?
                  <ScrollingControl task={currentItem} />
                :
                  <div/>
              }
            </div>
          </div> 
        </div>
        
          <div style={RightFacingDivStyle}>
            {isValidObject(currentItem)?<DescriptionControl task={currentItem} $FieldID={'Description'} />:<div />}
          </div> 
          <div style={{flex: "0 0 auto", margin: "0 16px 24px"}}>
            {isValidObject(currentItem)?<SprintControl task={currentItem} sprints={this.props.sprints}/>:<div />}     
            {isValidObject(currentItem) && currentItem.fields.LinkedToPipeline ?
                <Relation icon={ImgPipeline} text={currentItem.fields.LinkedToPipelineTaskName}/>
              :	
                null
            }
          </div>
          <div className="itemdetails-panes">
            <ReflexContainer orientation="vertical">
              <ReflexElement style={{overflowX: "hidden"}} name="singleline" onResize={this.onResizePane} flex={this.layoutState.singleline.flex}>
                <div className="pane-content">
                  {isValidObject(currentItem)?<SinglelineLayout task={currentItem} milestones={this.props.milestones}/>:<div />}
                </div>
              </ReflexElement>

              <ReflexSplitter className="pane-splitter">
                <div className="splitter-content"/>
              </ReflexSplitter>

              <ReflexElement style={{overflowX: "hidden"}} name="multiline" onResize={this.onResizePane} flex={this.layoutState.multiline.flex}>
                <div className="pane-content">
                  {isValidObject(currentItem)?<LeftPanel task={currentItem} top={top} multilinefields={this.props.multilinefields}/>:<div />}
                </div>
              </ReflexElement>

              <ReflexSplitter className="pane-splitter">
                <div className="splitter-content"/>
              </ReflexSplitter>

              <ReflexElement style={{overflowX: "hidden"}} name="comments" onResize={this.onResizePane} flex={this.layoutState.comments.flex}>
                <div className="pane-content">
                  {isValidObject(currentItem)?<CommentsControl task={currentItem} />:<div />}
                </div>
              </ReflexElement>
            </ReflexContainer>
          </div>   
      </div>
    );
  }
}

class LeftPanel extends React.Component {

  render() {
    const currentItem = this.props.task;

    return (
      <div>
        {isValidObject(currentItem)?<UniqueFieldControl task={currentItem} />:<div />}
        {isValidObject(currentItem)?<MultilineTab task={currentItem} multilinefields={this.props.multilinefields}/>:<div />}
      </div>
    )
  }
}

const PanelStyle = {
  margin: '10px',
  overflowY:'auto',
  overflowX:'hidden'
}

const RightFacingDivStyle = {
  paddingRight: '8x',
  margin: "0 16px 16px",
}

const mapStateToProps = state => ({
  currentuser: state.currentuser,
  todos: state.todos,
  milestones: state.milestones,
  sprints: state.sprints,
  multilinefields: state.multilinefields,
  appState: state.appState,
});

export default ItemDetail = connect(mapStateToProps)(ItemDetail);
