import { store } from '../_helpers/store';
import { findWorkflow, getConnectedStatuses } from '../_reducers/workflow.reducer'
import { filterFieldsByProject } from '../_reducers/singleline.reducer'
import { getProjectInfo } from '../_reducers/project.reducer'
import { isValidObject } from '../_helpers/validation_functions'

export const workflowService = {
  markFieldAsRequiredForWorkflowState
};

function markFieldAsRequiredForWorkflowState(fieldID, itemID, projectID, workflowID, bCheckForReadOnly) {

  const projectData = getProjectInfo(projectID);
  if (!projectData || projectData.length < 2 || !projectData[1])
    return false;
  const workflow = findWorkflow(projectData[1].id, workflowID);
  if (!isValidObject(workflow))
    return false;

  const state = store.getState();
  let currentItem = state.todos.find(function(item) {
    return item.$ID === itemID;
  });
  if (!isValidObject(currentItem)) {
    return false;
  }

  const workflowStatus = currentItem.getProperty('WorkflowStatus');
  if (!isValidObject(workflowStatus)) {
    return false;
  }
  const connectedStatuses = getConnectedStatuses(workflow, workflowStatus)
  if (!isValidObject(connectedStatuses)) {
    return false;
  }

  for (let statusIndex = 0; statusIndex < connectedStatuses.length; statusIndex++) {
    if ('RequiredFields' in connectedStatuses[statusIndex]) {
      if (!Array.isArray(connectedStatuses[statusIndex].RequiredFields)) {
        continue;
      }
      for (let reqIndex = 0; reqIndex < connectedStatuses[statusIndex].RequiredFields.length; reqIndex++) {
        if (connectedStatuses[statusIndex].RequiredFields[reqIndex].FieldID === fieldID) {
          // being used to check for read only status
          if (bCheckForReadOnly) {
            return isItemFieldSet(fieldID, projectData[0].id, currentItem)?false:true 
              && "ResourcesCanEditAllFieldsThatMustBeSetForThisTransition" in connectedStatuses[statusIndex].RequiredFields[reqIndex]
              && connectedStatuses[statusIndex].RequiredFields[reqIndex].ResourcesCanEditAllFieldsThatMustBeSetForThisTransition;
          // being used to check for required field
          } else {
            return isItemFieldSet(fieldID, projectData[0].id, currentItem)?false:true;
          }
        }
      }
    }
  }

  return false;
}

function isItemFieldSet(fieldID, projectID, currentItem) {

  const fields = filterFieldsByProject( projectID )
  const field = fields.find(function(field) { return field.id === this.fieldname}, {fieldname: fieldID} )
  if (!isValidObject(field))
    return true;

  const property = currentItem.getProperty(fieldID);

  switch (field.Type) {
    case 'Enum':
    case 'BugPriority':
    case 'SprintPriority':
      if (fieldID.startsWith('CC')) {
        if (property === 0 || property === null) {
          return false;
        }
        return true;
      } else {
        if (property === 1) {
          return false;
        }
        return true;       
      }
    case 'MultiEnum':
    case 'InlineMultiEnum':
    case 'Resources':
    case 'MilestoneMultiEnum':
      if (property.length === 0) {
        return false;
      }
      return true;
    case 'String':
    case 'Hyperlink':
    case 'Integer':
    case 'Float':
    case 'Hours':
    case 'DateTime':
    case 'DateTimeTime':
      let myProperty = property
      if (typeof property === 'string') {
        myProperty = myProperty.trim()
      }
      if (myProperty === null || myProperty === '' ) {
        return false;
      }
      return true;
    default:
      return true;
  }
}