import React from 'react';

import { formatYYYYMMDD } from "../_helpers/date_time_format";
import { getMilestoneFromID, getSortedMilestoneList } from "../_helpers/milestones";

import {   
  ImgReleaseOrange
} from '../_images/images'
import RelationList from './components/relationlist';

export default class ReleaseTagControl extends React.Component {
  render() {
    if ('task' in this.props === false) 
      return null;

    let { LinkedToMilestone } = this.props.task.fields;
    if (!LinkedToMilestone || LinkedToMilestone.length === 0)
      return null;

    let milestoneValues = [];
    for (let index = 0; index < LinkedToMilestone.length; ++index)
      milestoneValues.push(getMilestoneFromID(LinkedToMilestone[index], this.props.milestones));

    let displayValues = [];
    milestoneValues = getSortedMilestoneList(milestoneValues);

    for (let index = 0; index < milestoneValues.length; ++index) {
      const milestone = milestoneValues[index];
      if (milestone) {
        const milestoneDate = new Date(milestone.fields.Date.$date);
        const dateAsString = milestone.fields.$ID === -128 ? null : formatYYYYMMDD(milestoneDate);

        displayValues.push({
          icon: ImgReleaseOrange,
          text: milestone.fields.Name,
          subText: dateAsString,
        });
      }
    }
    
    return <RelationList icon={ImgReleaseOrange} items={displayValues}/>;
  }
}