import React from "react";
import { Popup } from "semantic-ui-react";

import "./gauge.scss";

class Gauge extends React.Component {
    render() {
		let height = this.props.maxHeight;

		let lightSize = ((height - 32) / 3) * 0.8;
		let gaugeWidth = lightSize + 40;
		let lowerBoundIsGreater = this.props.data.lowerBound > this.props.data.upperBound;
		
		let gaugeColor = "red";
		if (lowerBoundIsGreater) {
			if (this.props.data.value < this.props.data.lowerBound)
				gaugeColor = "yellow";
			else if (this.props.data.value >= this.props.data.lowerBound)
				gaugeColor = "green";
		} else {
			if (this.props.data.value < this.props.data.upperBound && this.props.data.value > this.props.data.lowerBound)
				gaugeColor = "yellow";
			else if (this.props.data.value <= this.props.data.lowerBound)
				gaugeColor = "green";
		}

		return (
			<div className="gaugecontainer" style={{maxHeight: this.props.maxHeight, minHeight: this.props.maxHeight}}>
				<Popup
					content={`Number of items: ${this.props.data.value}`}
					className="gauge-tooltip"
					position="top center"
					trigger={
						<div className="gauge" style={{width: gaugeWidth}}>
							<div style={{height: lightSize, width: lightSize}} className={`light upper ${gaugeColor === "red" ? "is-active" : ""}`}/>
							<div style={{height: lightSize, width: lightSize}} className={`light middle ${gaugeColor === "yellow" ? "is-active" : ""}`}/>
							<div style={{height: lightSize, width: lightSize}} className={`light lower ${gaugeColor === "green" ? "is-active" : ""}`}/>
						</div>
					}
				/>
				{this.props.data.legend === "notVisible" ?
						null
					:
						<div className="legend">
							<div className="entry">
								<div className="icon upper"/>						
								<div>{this.props.data.measure} {`${lowerBoundIsGreater ? "<=" : ">="} ${this.props.data.upperBound}`}</div>
							</div>
							<div className="entry">
								<div className="icon middle"/>						
								<div>{this.props.data.measure} {`${lowerBoundIsGreater ? "<" : ">"} ${this.props.data.lowerBound}`}</div>
							</div>
							<div className="entry">
								<div className="icon lower"/>						
								<div>{this.props.data.measure} {`${lowerBoundIsGreater ? ">=" : "<="} ${this.props.data.lowerBound}`}</div>
							</div>
						</div>
				}
			</div>
		);
    }    
}

export default Gauge;