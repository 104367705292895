const noReleaseMilestone = {
    fields: {
        $ID: -128,
        Name: "No release",
        Date: {
            $date: null,
        },
    },
};

export function getMilestoneFromID(milestoneID, milestones) {
    if (milestoneID === -128)
        return noReleaseMilestone;

    for (let index = 0; index < milestones.length; ++index) {
        if (parseInt(milestones[index].$ID) === milestoneID)
            return milestones[index];
    }		
}

export function getSortedMilestoneList(milestoneList) {
    milestoneList.sort(function(milestoneA, milestoneB) {
        if (milestoneA.fields.Date.$date < milestoneB.fields.Date.$date)
            return -1;
        else if (milestoneA.fields.Date.$date > milestoneB.fields.Date.$date)
            return 1;
        else if (milestoneA.fields.Name < milestoneB.fields.Name)
            return -1;
        else if (milestoneA.fields.Name > milestoneB.fields.Name)
            return 1;
        return 0;
    });

    return milestoneList;
}