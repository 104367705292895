import React from 'react';
import { connect } from 'react-redux';

import { filterConstants } from '../_constants/filter.constants';

class SearchField extends React.Component {

  state = { 
    searchValue: this.props.filteroptions.stringfilter
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.filteroptions.stringfilter !== this.props.filteroptions.stringfilter) {
      this.setState({ searchValue : this.props.filteroptions.stringfilter });
    }
  }

  componentWillReceiveProps(nextProps) {
    let stringfilter = nextProps.filteroptions.stringfilter;
    this.setState({searchValue: stringfilter});
  }

  onChange = (value) => {
    this.setState({searchValue: value});
  }

  clickSearchButton() {
    const message = { type: filterConstants.STRING_FILTER, stringfilter: this.state.searchValue };
    const { dispatch } = this.props;
    dispatch(message);
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.clickSearchButton(event)
    }
  }

  render() {
    return (
      <input
        className="searchinput"
        placeholder="Search..."
        onChange={event => { this.onChange(event.target.value)}}
        onKeyPress={this.handleKeyPress}
        value={this.state.searchValue}
      />
    );
  }
}

const mapStateToProps = state => ({
  filteroptions: state.filteroptions,
})

export default connect(mapStateToProps)(SearchField);