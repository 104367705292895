import { convertToRaw } from 'draft-js';
import { convertRawContentToTaggedText } from"../../_services/TaggedTextToDraftjs";

import WYSIWYGEditor from '../../Fields/WYSIWYGEditor'

export default class CommentEditor extends WYSIWYGEditor {

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  getComment() {
    return convertRawContentToTaggedText(convertToRaw(this.state.editorState.getCurrentContent()));
  }

}
