export function displayTimeFilter(duedatefilter) {
  if (duedatefilter.length === 1) {
    return getTextForDueDateID(duedatefilter[0]);
  } else if (duedatefilter.length > 1) {
    let filterString = getTextForDueDateID(duedatefilter.sort()[0]);
    filterString += ',...';
    return filterString;
  } else {
    return '';
  }
}

function getTextForDueDateID(id) {
  switch (id) {
    case 5:
      return 'Later';
    case 4:
      return 'Next Week';
    case 3:
      return 'This Week';
    case 2:
      return 'Today';
    case 1:
      return 'Past Due';
    default: 
      return '<unknown>'
  }
}

export function displayProjectFilter(projectfilter) {
  let filterString = 'Project: ';
  if (projectfilter.length === 1) {
    return filterString + projectfilter[0];
  } else if (projectfilter.length > 1) {
    filterString += projectfilter.sort()[0];
    filterString += ',...';
    return filterString;
  } else {
    return '';
  }
}

export function displayStatusFilter(statusfilter) {
  let filterString = 'Status: ';
  if (statusfilter.length === 1) {
    return filterString + getTextForStatus(statusfilter[0]);
  } else if (statusfilter.length > 1) {
    filterString += getTextForStatus(statusfilter.sort()[0]);
    filterString += ',...';
    return filterString;
  } else {
    return '';
  }
}

function getTextForStatus(id) {
  switch (id) {
    case 6:
      return 'To be deleted';
    case 5:
      return 'Blocked';
    case 4:
      return 'Completed';
    case 3:
      return 'In progress';
    case 2:
      return 'Not done';
    default: 
      return '<unknown>'
  }
}

export function displayPriorityFilter(priorityfilter) {
  let filterString = 'Priority: ';
  if (priorityfilter.length === 1) {
    return filterString + getTextForPriority(priorityfilter[0]);
  } else if (priorityfilter.length > 1) {
    filterString += getTextForPriority(priorityfilter.sort()[0]);
    filterString += ',...';
    return filterString;
  } else {
    return '';
  }
}

function getTextForPriority(id) {
  switch (id) {
    case 6:
      return 'Very High';
    case 5:
      return 'High';
    case 4:
      return 'Medium';
    case 3:
      return 'Low';
    case 2:
      return 'Very Low';
    case 1:
      return '(No Priority Set)';
    default: 
      return '<unknown>'
  }
}