import React from 'react';
import { connect } from 'react-redux';
import { List, Button, Menu } from 'semantic-ui-react';

import './Comments.scss'

import { subComments, unsubComments, postComment, editComment } from '../../DDPJS/DDPJS';
import CommentItem from './CommentItem'
import CommentEditor from './CommentEditor'
import { commentConstants } from '../../_constants/comment.constants'
import { MessageEnum, MsgLevelEnum } from '../../MessageDialog/MessageIndex'
import { setErrorMessage } from '../../MessageDialog/MessageUtilities'
import * as VC from "../../_services/versioncontrol";

class CommentsControl extends React.Component {

  constructor(props) {
    super(props);  
    this.state = { 
      comment: '',
      replyMode: false,
      editMode: false,
      editCommentID: '-1',
      TaskCommentsID: subComments(this.props.task.$ID),
      child: null
     };
     this.clickCancelButton = this.clickCancelButton.bind(this);
     this.clickPostButton = this.clickPostButton.bind(this);
     this.clickReplyButton = this.clickReplyButton.bind(this);
     this.handleChildClick = this.handleChildClick.bind(this);
     this.editorCallbackFunction = this.editorCallbackFunction.bind(this);
     this.versionControlFileInfo = this.versionControlFileInfo.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.task.$ID !== prevProps.task.$ID) {
      unsubComments(this.state.TaskCommentsID);
      const { dispatch } = this.props;
      dispatch({type: commentConstants.RESET});
      this.setState({ 
        comment: '',
        replyMode: false,
        TaskCommentsID: subComments(this.props.task.$ID),
      });
    }
  }

  componentWillUnmount() {
    unsubComments(this.state.TaskCommentsID);
    const { dispatch } = this.props;
    dispatch({type: commentConstants.RESET});
  }

  clickCancelButton(event) {
    this.setState({
      replyMode: false,
      editMode: false,
      editCommentID: '-1'  
    })
  }

  clickPostButton(event) {

    const childComment = this.state.child.getComment();
    if (this.state.editMode) {
      editComment(this.props.task.$ID, this.state.editCommentID, childComment);
    } else {
      if (childComment.trim().length > 0) {
        postComment(this.props.task.$ID, '-1', childComment);
      } else {
        setErrorMessage( MsgLevelEnum.INFORMATION, MessageEnum.EMPTY_MESSAGE );
      }
    }

    this.setState({
      replyMode: false,
      editMode: false,
      comment: '',
      editCommentID: '-1'  
    })
  }

  clickReplyButton(event) {
    this.setState({
      replyMode: true,
      comment: '',
    });
  }

  renderCommentsButtons(length) {
    if (!this.state.replyMode) {
      return (
        <Menu.Menu position='right'>
          <div className="comment-button is-positive" onClick={(event) => this.clickReplyButton(event)}>{length === 0 ? "Add comment" : "Reply"}</div>
        </Menu.Menu>
      )
    } else {
      return (
        <Menu.Menu position='right'>
          <Button className="Comment-Button-Negative" onClick={ (e) => this.clickCancelButton(e) } >Cancel</Button>
          <Button className="Comment-Button-Positive" onClick={ (e) => this.clickPostButton(e) } >Post</Button>
        </Menu.Menu>
      )
    }
  }

  handleChildClick(childData) {
    this.setState({
      replyMode: true,
      editMode: true,
      comment: childData.Text,
      editCommentID: childData.id
    })
  }

  sortComments(first, second) {
    if (!('id' in first)) return 0;
    if (!('id' in second)) return 0;
    if (parseInt(first.id, 10) > parseInt(second.id, 10)) return -1;
    if (parseInt(first.id, 10) < parseInt(second.id, 10)) return 1;
    return 0;
  }

  editorCallbackFunction = (ref) => {
    this.setState({child: ref})
    if (this.state.replyMode && ref !== null && typeof ref.focus === 'function') {
      ref.focus()
    }
  }

  versionControlFileInfo(tokenUrl) {
    const urlParts = tokenUrl.split("/");
    return VC.mapFileIDToInfo(this.props.task, parseInt(urlParts[urlParts.length - 1]));
  }

  render() {
    const comments = this.props.comments;
    comments.sort(this.sortComments);

    let children = comments.map(function(childData,childIndex) {
      return (<CommentItem key={childIndex} {...childData} handler={this.handleChildClick.bind(null, childData)} versionControlFileInfo={this.versionControlFileInfo} />);
    }.bind(this));

    const fieldData = {
      id: this.props.task.$ID, 
      fieldID: 'Comment',   // fake id being used for comments
      readOnly: false, 
      fieldValue: this.state.comment
    };

    // this.setState({child: ref})

    const that = this

    return (
      <div style={CommentControlStyle} id='comments_link'>
        <div className="commentcontrol-title">
          <div className="commentcontrol-title-text">Comments</div>
          {this.renderCommentsButtons(comments.length)}
        </div>
        { !this.state.replyMode
            ? <div />
            : <CommentEditor {...fieldData } task={this.props.task} onRef={ref => (that.editorCallbackFunction(ref))} />
        }
        <div style={CommentListStyle}>
          <List selection verticalAlign='middle'>
            {comments.length === 0 && !this.state.replyMode ?
              <div className="comment-emptystate">No comments posted yet.</div>
              :
              null
            }
            {children}
          </List>
        </div>
      </div>
    )
  }
}

const CommentControlStyle = {
  margin: '10px'
}

const CommentListStyle = {
  width: '100%', 
  overflowY:'auto'
}

const mapStateToProps = state => ({
  comments: state.comments,
  todos: state.todos,
})

export default CommentsControl = connect(mapStateToProps)(CommentsControl);
