import React from "react";

import { history } from "../../_helpers/history";
import DashboardHeader from "../components/dashboardheader";

class DashboardHeaderContainer extends React.Component {
    render() {
		return (
			<DashboardHeader 
				dashboards={this.props.dashboards} 
				selectedDashboardId={this.props.pageId}
				theme={this.props.theme}
				onSelectDashboard={(pageId) => {
					history.push(`/dashboards/${this.props.databaseGUIDHash}/${pageId}`);
				}}
			/>
		);
    }    
}

export default DashboardHeaderContainer;