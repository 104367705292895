import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual'

import BaseField from './BaseField'
import {setField} from '../DDPJS/DDPJS';
import { cannotSetToNotAssigned } from '../_reducers/workflow.reducer'
import { findSprint } from '../_reducers/sprint.reducer'
import { findResource, getResourceArrayForProject } from '../_reducers/resource.reducer'
import { MessageEnum, MsgLevelEnum } from '../MessageDialog/MessageIndex'
import { setErrorMessage } from '../MessageDialog/MessageUtilities'
import { getProjectInfo } from '../_reducers/project.reducer'
import { ImgUserGreen } from '../_images/images';
import SingleSelectField from './components/SingleSelect';
import { isValidObject } from '../_helpers/validation_functions'
import { findFieldByProject} from "../_reducers/singleline.reducer";
import { unsubscribeFromSubscriptionId, subscribeWithParams } from "../DDPJS/DDPJS";

export function sortUsers(first, second) {
  const firstSortName = first.sortValue ? first.sortValue : first.text;
  const secondSortName = second.sortValue ? second.sortValue : second.text;

  if (firstSortName.toUpperCase() < secondSortName.toUpperCase()) return -1;
  if (firstSortName.toUpperCase() > secondSortName.toUpperCase()) return 1;
  return 0;
}

class AllocationsField extends BaseField {

  subcriptionParameters = {
    projectID : this.props.task.$ProjectID,
    sprintID : -1,
    bIncludeGroups : true
  };

  state = {
    value: createValueArray(this.getValue()),
    currentID: this.props.task.$ID,
    subscriptionId: subscribeWithParams("ProjectResources", this.subcriptionParameters),
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isEqual(prevState.value, createValueArray(this.getValue())) === false) {
      this.setState({value: createValueArray(this.getValue())});
    }
    if (prevState.currentID !== this.props.task.$ID) {
      this.setState({currentID: this.props.task.$ID});
    }
  }

  componentWillUnmount() {
		if (this.state.subscriptionId)
			unsubscribeFromSubscriptionId(this.state.subscriptionId);
	}

  onChange = (event, value) => {
    if (this.readOnlyFlag()) {
      return;
    }
  
    const bCannotSetToNotAssigned = this.props.task.getProperty('WorkflowStatus')
      ?cannotSetToNotAssigned(this.getProjectID(), this.props.task.getProperty('Workflow'), this.props.task.getProperty('WorkflowStatus')):false;

    if (value.length === 0 && bCannotSetToNotAssigned) {
      setErrorMessage( MsgLevelEnum.INFORMATION, MessageEnum.WORKFLOW_PROHIBITS_NOT_ASSIGNED );
      return;
    }
      
    this.setState({value: value});

    const allocationArray = createAllocationArray(value, this.getValue());
    setField(this.getFieldDefinition().id, this.getItemID(), allocationArray);
  }

  render() {
    if (!isValidObject(this.getFieldDefinition())) {
      return (null);
    }

    if (this.state.currentID !== this.props.task.$ID) {
      return (null)
    }

    let users = [];
    let myResources = [];

    if (this.props.task.isInSprint()) {
      const projectData = getProjectInfo(this.props.task.$ProjectID);
      if (projectData[1] !== null) {
        const mySprint = findSprint(projectData[1].id, this.props.task.getSprintID())
        if (isValidObject(mySprint)) {
          myResources = mySprint.getResources();
        }
      }
    } else {
      myResources = getResourceArrayForProject(this.props.task.$ProjectID)
    }

    const currentValue = this.state.value
    const bIsQATask = this.props.task.isQATask()

    for (let index = 0; index < myResources.length; ++index) {
      const myResource = findResource(this.props.task.$ProjectID, myResources[index].ID);
      if (!isValidObject(myResource) || myResource.isSDKUser()) {
       continue
      }
      if (!bIsQATask && myResource.isQAUser()) {
        continue
      }
      const value = parseInt(myResource.id, 10);

      users.push({
        image: `${window.location.origin}/versioncontrol/Avatars/${myResource.id}/Avatar_64.PNG`,
        fallbackImage: ImgUserGreen,
        selected: currentValue.indexOf(value) !== -1,
        text: myResource.Name,
        sortValue: myResource.SortName,
        value: value,
      });      
    }

    let displayValue = "";
    for (let index = 0; index < currentValue.length; ++index) {
      const myResource = findResource(this.props.task.$ProjectID, currentValue[index]);
      if (!isValidObject(myResource)) {
        continue
      }
      if (!displayValue)
        displayValue = myResource.Name;
      else
        displayValue += "; " + myResource.Name;
    }

    users.sort(sortUsers);

    let displayName = this.getFieldDefinition().DisplayName;
    if (this.props.task.fields.CommittedToProjectID !== undefined) {
      let committedProjectField = findFieldByProject(this.props.$FieldID, this.getPlanningProjectID());
      if (committedProjectField)
        displayName = committedProjectField.DisplayName;
    }

    return (
      <SingleSelectField
        subscriptionId={this.state.subscriptionId}
        text={displayValue}
        fieldName={displayName}
        disabled={this.readOnlyFlag()}
        isRequiredField={this.isWorkflowRequiredField()}
        multiSelection={true}
        onSelectionChanged={(newValues) => {
          this.onChange(null, newValues);
        }}
        findData={{
          onFindTextChanged : (newFindText) => {
            this.onFindTextChanged(newFindText, "ProjectResources", this.subcriptionParameters);
          }
        }}
        options={users}
      />
    );
  }
}

function createValueArray(valueArray) {
  let value = [];
  for (let validx = 0; validx < valueArray.length; validx++) {
    value.push(valueArray[validx][0]);
  } 
  return value;
};

function createAllocationArray(valueArray, propsArray) {
  let value = [];
  for (let validx = 0; validx < valueArray.length; validx++) {
    value.push([valueArray[validx], getOriginalAllocationValue(valueArray[validx], propsArray)]);
  } 
  return value;
};

function getOriginalAllocationValue(id, array) {
  let allocation = 100;
  for (let index = 0; index < array.length; index++) {
    if (array[index][0] === id) {
      return array[index][1];
    }
  } 
  return allocation; 
}

const mapStateToProps = (state, props) => {
  return {
    resources: state.resources,
    sprints: state.sprints,
    assignments: props.task.getProperty(props.$FieldID), // Ensure shallow equals re-render
  };
}

export default AllocationsField = connect(mapStateToProps)(AllocationsField);
