import React from "react";

import Input from "./Input";
import ItemDetailsField from "./ItemDetailsField";
import BaseField from "../BaseField";
import { setField } from "../../DDPJS/DDPJS";

export default class TimeSpent extends BaseField {
	state = {
		value: this.getValue(),
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.value !== this.getValue()) {
			this.setState({
				value: this.getValue(),
			});
		}
	}

	render() {
        if (!this.getFieldDefinition())
            return null;

        let control = (
			<div className="timespent-container">
				<div className="timespent-label">Add time to {this.state.value}h:</div>
				<div className="timespent-input">
					<Input
						value={""}
						number={true}
						decimal={true}
						readOnly={this.readOnlyFlag()}
						onChange={(newValue) => {								
							if (!isNaN(newValue) && newValue !== 0) {
								let timeSpent = this.state.value || 0;
								timeSpent += newValue;

								if (timeSpent < 0)
									timeSpent = 0;

								this.setState({
									value: timeSpent,
								});

								if (timeSpent !== this.state.value)
									setField(this.getFieldDefinition().id, this.getItemID(), timeSpent);
							}

							return "";
						}}
					/>
				</div>
			</div>
		);

        
        return (
            <ItemDetailsField
                fieldName={this.getFieldDefinition().DisplayName}
                isRequiredField={this.isWorkflowRequiredField()}
                children={control}
            />        
        );
	}
}