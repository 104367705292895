import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

import ItemDetailsField from "./ItemDetailsField";
import FloatingSelection from "./FloatingSelection";

export default class SingleSelectField extends React.Component {
	constructor(props) {
		super(props);
		this.onEdit = this.onEdit.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onSelectionChanged = this.onSelectionChanged.bind(this);
	}

	state = {
		isEditing: false,
	};

	onEdit() {
		if (this.props.disabled)
			return;

		this.setState({
			isEditing: true,
		});
	}

	onSelectionChanged(newValue, changedMultiSelection) {
		this.onClose();
		if (this.props.multiSelection && !changedMultiSelection)
			return; // Ignore the user simply pressing "Ok" without changing selection.
			
		this.props.onSelectionChanged(newValue);
	}

	onClose() {
		this.setState({
			isEditing: false,
		});
	}

	render() {
		let singleSelectClasses = ["singleselect"];
		if (this.props.disabled) {
			singleSelectClasses.push("is-disabled");
			singleSelectClasses.push("js-is-disabled");
		}

		let control = (
			<div className="singleselect-container">
				<div className={singleSelectClasses.join(" ")} onClick={() => this.onEdit()}>
					<div className="singleselect-content js-singleselect-content" ref={element => this.tableCellRef = element}>
						{this.props.customDisplayValue ? 
								this.props.customDisplayValue
							:
							<span>
								{this.props.icon ? <img className="singleselect-icon" src={this.props.icon} alt={""}/> : null}
								{this.props.text}
							</span>
						}						
					</div>
				</div>
				{this.state.isEditing ?
					<Popup
						open={true}
						content={<FloatingSelection subscriptionId={this.props.subscriptionId} options={this.props.options} multiSelection={this.props.multiSelection} findData={this.props.findData} onSelectionChanged={this.onSelectionChanged} onClose={this.onClose} fitToElement={!!this.props.fieldName ? this.tableCellRef : null}/>}
						context={this.tableCellRef}
						onClose={this.handleClose}
						position="bottom center"
					>
					</Popup>
				: 
					null
				}
			</div>
		);

		if (!!this.props.fieldName) {
			return (
			   <ItemDetailsField fieldName={this.props.fieldName} isRequiredField={this.props.isRequiredField} children={control}/>
			);
		}

		return control;      
	}
}

SingleSelectField.propTypes = {
	subscriptionId: PropTypes.string,
	fieldName: PropTypes.string,
	disabled: PropTypes.bool,
	isRequiredField: PropTypes.bool,
	multiSelection: PropTypes.bool,
	customDisplayValue: PropTypes.element,
	onSelectionChanged: PropTypes.func.isRequired,
	findData: PropTypes.shape({
		onFindTextChanged : PropTypes.func
	}),
	options: PropTypes.arrayOf(PropTypes.shape({
		image: PropTypes.string,
		seperator: PropTypes.bool,
		selected: PropTypes.bool,
		text: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		exclusive: PropTypes.bool,
	})),
};