import DDPJS from 'ddp.js'

import { store } from '../_helpers/store';
import { userActions } from '../_actions/user.actions';
import { todoConstants } from '../_constants/todo.constants';
import { ddpConstants } from '../_constants/DDP.constants';
import { loginErrorConstants } from '../_constants/loginError.constants';
import { stateConstants } from '../_constants/state.constants';
import { multilineConstants } from '../_constants/multiline.constants';
import { projectConstants } from '../_constants/project.constants';
import { resourceConstants } from '../_constants/resource.constants';
import { singlelineConstants } from '../_constants/singleline.constants';
import { commentConstants } from '../_constants/comment.constants';
import { sprintConstants } from '../_constants/sprint.constants';
import { groupConstants } from '../_constants/group.constants';
import { workflowConstants } from '../_constants/workflow.constants';
import { milestoneConstants } from '../_constants/milestone.constants';
import { CLEAR_CACHE, PRODUCT, SERVICE, REMOVE_FIELD } from '../_constants/general.constants';
import { createRefreshToken, deleteRefreshToken } from '../_tokens/refresh.token';
import { history } from '../_helpers/history';
import { MessageEnum, MsgLevelEnum } from '../MessageDialog/MessageIndex'
import { setErrorMessage, setCustomMessage, clearErrorMessage } from '../MessageDialog/MessageUtilities'
import { getDisplayNameForCustomField, getDisplayNameForDefaultField } from "../_helpers/fields";
import { extractFromString } from '../_helpers/projectid_parser';
import * as VC from "../_services/versioncontrol";
import { dashboardConstants } from '../_constants/dashboard.constants';
import { chartResultSetsConstants } from '../_constants/chartresultsets.constants';

// Connection data. For the web client, I felt it best to control connection and reconnection myself,
// but this might change. For now, since the web service doesn't re-authenticate, reconnecting doesn't help.
const DDPOptions = {
  endpoint: window.location.hostname === 'localhost' ? 'ws://localhost:8086' : 'wss://' + window.location.host + '/websocket',
  // use the following string for connecting to local WebService project
  //endpoint: window.location.hostname === 'localhost' ? 'ws://localhost:8086' : 'ws://' + window.location.hostname + ':8086',
  SocketConstructor: WebSocket,
  autoConnect: false,
  autoReconnect: false
}

// Create the DDPJS object as a singleton object
let DDPConn = new DDPJS(DDPOptions);

// Local variable for username
let gUsername;
let gLosenord;

// local variable to check for deliberate disconnect
let bDisconnectAction = false;

let bRefreshAction = false;

let intervalID = 0;


let startedSyncAt = -1;

const RENEW_TOKEN_RATE = 300000; // milliseconds

// After the connection to the DDP service is established, this meessage is returned. I used this to set the state to Connected
DDPConn.on('connected', () => {
  console.log('Connected to ' + SERVICE);
  const datatoken = localStorage.getItem('hansoftauth');
  store.dispatch({type: stateConstants.SET_CONNECTED });
  let loginid;

  if (datatoken) {
    console.log('Data Token found with authToken')
    const subdata = JSON.parse(datatoken);
    const authToken = subdata.authToken;
    gUsername = subdata.username;
    console.log('Authenticate authToken');
    loginid = DDPConn.method('authenticate', [ authToken ]);    
  } else { 
    console.log('Authenticating with username: ' + gUsername);
    loginid = DDPConn.method('authenticate', [ gUsername, gLosenord]);
    gLosenord = null;
  }
  bRefreshAction = false;
  console.log('ID for the authentication method: ' + loginid);
  store.dispatch({type: ddpConstants.LOGINID, loginid: loginid});
})

// This is called after a request to the DDP service is completed and returned.
DDPConn.on('result', message => {
  const state = store.getState();

  if (message.id !== undefined && onMethodCompleteCallbacks[message.id]) {
    onMethodCompleteCallbacks[message.id](message);
    delete onMethodCompleteCallbacks[message.id];
  } 

  // everything is ok
  if ('result' in message && 'success' in message.result) {
    console.log('Result object found in message.');
    if (message.result.success) {
      console.log('Method was successful.');
      // if the return id is the same as the login request, record the username
      if (message.id === state.ids.loginid) {
        console.log('ID of the returned message matches cached ID from original authentication call.');
        intervalID = setInterval(renewToken, RENEW_TOKEN_RATE);
        console.log('logon intervalID = ' + intervalID);
        if (gUsername) {
          store.dispatch({type: CLEAR_CACHE});
          clearErrorMessage()
          createRefreshToken()
          console.log('Starting application with username: ' + gUsername);
          saveAuthToken(gUsername, message.result.authToken, message.result.authResourceID)
          store.dispatch(userActions.login(gUsername));
          message.type = loginErrorConstants.CLEAR_ERROR;
          store.dispatch(message);
          console.log('Begins subscriptions.');
          subscribe();
        }
      } else if (message.id === state.ids.renewid) {
        saveAuthToken(gUsername, message.result.authToken, message.result.authResourceID)
      }

      if (onLoginCallback) {
        onLoginCallback();
        onLoginCallback = null;
      }

    // ooops, not ok
    } else {
      if (message.id === state.ids.loginid || message.id === state.ids.renewid) {
        console.log('Login was NOT successful. ERROR');
        // login failed, record error for display
        console.log(message)
        logoffHansoft();
        if (message.id === state.ids.loginid) {
          message.type = loginErrorConstants.SET_ERROR;
          store.dispatch(message);
        }
        const datatoken = localStorage.getItem('hansoftauth');
        // if there was a token and we failed to log in, it probably has expired. In any case, take user back to login and cleanup
        if (datatoken) {
          clearAuthToken();
          deleteRefreshToken();
          setErrorMessage( MsgLevelEnum.WARNING, MessageEnum.SESSION_HAS_EXPIRED );
          history.push('/login');
        }
      } else {
        console.log(message)
        let myErrorMessage = '<an unknown error occurred>'
        if ('error' in message) {
          myErrorMessage = message.error.error + ': ' + message.error.reason
        } else if ('result' in message) {
          if ('RequiredFieldsMissing' in message.result) {
            if (Array.isArray(message.result.RequiredFieldsMissing)) {
              myErrorMessage = 'Before setting another status you must fill in this data:'
              for (let fieldIndex = 0; fieldIndex < message.result.RequiredFieldsMissing.length; fieldIndex++) {
                myErrorMessage += '\n'
                if (message.result.RequiredFieldsMissing[fieldIndex].indexOf("CC_") === 0)
                  myErrorMessage += getDisplayNameForCustomField(message.result.RequiredFieldsMissing[fieldIndex], message.result.ProjectID);
                else
                  myErrorMessage += getDisplayNameForDefaultField(message.result.RequiredFieldsMissing[fieldIndex]);
              }
            }
          }
        }
        setCustomMessage(MsgLevelEnum.INFORMATION, myErrorMessage)
      }
    }
  // we received something from the web service, but it's not identified as an error or not
  } else {
    console.log('The message object returned does not contain a result and success value');

    let mysteryMessage = ''

    if ('error' in message && 'error' in message.error) {
      mysteryMessage = message.error.error;
    } else {
      mysteryMessage = 'An unknown error has occurred in the web service'
    }

    if ('error' in message && 'reason' in message.error) {
      mysteryMessage = mysteryMessage + ': ' + message.error.reason
    } 

    setCustomMessage(MsgLevelEnum.INFORMATION, mysteryMessage)
  }
})

function renewToken() {
  const state = store.getState();
  if (state.appState.isconnected) {
    const renewid = DDPConn.method('renew', []); 
    store.dispatch({type: ddpConstants.RENEWID, renewid: renewid});
    console.log('Renew auth token.');
  } else {
    console.log('Renew token skipped, not connected.');
  }
}

function saveAuthToken(username, authToken, authResourceID) {

  // This is stored in the browser storage to handle refresh page
  const subdata = {
    product: 'hansoft', 
    authToken: authToken, 
    resourceID: authResourceID,
    username: username
  };

  localStorage.setItem('hansoftauth', JSON.stringify(subdata)); 

  VC.requestAccess(authToken);
}

// The web service has been disconnected from
DDPConn.on('disconnected', message => {
  console.log(PRODUCT + ' has disconnected from the ' + SERVICE + '.')
  const state = store.getState();
  //If we're not already connected, it means there is a connection issue
  if (!state.appState.isconnected) {
    console.log(PRODUCT + ' was unable to connect to the ' + SERVICE + '.')
    const connectError = { error: 'Cannot connect to ' + SERVICE + '.' };
    const connectResult = { authresult: -1 }; 
    store.dispatch({type: loginErrorConstants.SET_ERROR, result: connectResult, error: connectError });
  } else if (bRefreshAction) {
    console.log('Disconnected from ' + PRODUCT + ' from browser refresh.')
    reconnectHansoft();
    bRefreshAction = false;
  } else if (!bDisconnectAction) {
    console.log(PRODUCT + ' was unexpectedly disconnected from the ' + SERVICE + '.')
    setErrorMessage( MsgLevelEnum.WARNING, MessageEnum.NO_LONGER_CONNECTED );
    unsubscribe();   
    store.dispatch({type: CLEAR_CACHE});
    store.dispatch(userActions.logout());
    store.dispatch({type: loginErrorConstants.CLEAR_ERROR});
    clearAuthToken();
    deleteRefreshToken();
    history.push(`/login`)
  } 
  bDisconnectAction = false;
  store.dispatch({type: stateConstants.CLEAR_CONNECTED });
})

DDPConn.on('added', message => {
  if (message.collection === "ProjectTasks" || message.collection === "Task") {
    message.type = todoConstants.ADD_TODO;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectMeta_")) {
    if ('id' in message) {
      if (message.id === '$Project') {
        message.type = projectConstants.ADD_PROJECT;
        store.dispatch(message);
      } else if ('fields' in message) {
        if ('Type' in message.fields) {
          if (message.fields.Type === "MultiLine") {
            message.type = multilineConstants.ADD_MULTILINE;
            store.dispatch(message);
          } else {
            message.type = singlelineConstants.ADD_SINGLELINE;
            store.dispatch(message);
          }
        }
      }
    }
  } else if (message.collection.startsWith("ProjectResources_")) {
    message.type = resourceConstants.ADD_RESOURCE;
    store.dispatch(message);
  } else if (message.collection.startsWith("TaskComments_")) {
    const state = store.getState();
    let myTaskID = -1;
    if ('collection' in message) 
      myTaskID = extractFromString("TaskComments_", message.collection);
    if (myTaskID === parseInt(state.appState.currentTaskID)) {
      message.type = commentConstants.ADD_COMMENT;
      store.dispatch(message);
    } 
  } else if (message.collection.startsWith("ProjectSprints_")) {
    message.type = sprintConstants.ADD_SPRINT;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectWorkflows_")) {
    message.type = workflowConstants.ADD_WORKFLOW;
    store.dispatch(message);
  } else if (message.collection.startsWith("ResourceGroups")) {
    message.type = groupConstants.ADD_GROUP;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectMilestones_")) {
    message.type = milestoneConstants.ADD_MILESTONE;
    store.dispatch(message);
  } else if (message.collection.startsWith("Dashboards")) {
    message.type = dashboardConstants.ADD_DASHBOARD;
    store.dispatch(message);
  } else if (message.collection.startsWith("ChartResultSets")) {
    message.type = chartResultSetsConstants.ADD_RESULTSET;
    store.dispatch(message);
  } else {
    console.log('Unknown add message for ' + PRODUCT);
  }
})

DDPConn.on('changed', message => {
  if (message.collection === "ServerConnectionState") {
    const state = store.getState();
    if (state.appState.serverVersion === "") {
      store.dispatch({type: stateConstants.SET_SERVER_VERSION, serverVersion: message.fields.serverVersion });
    } else if (state.appState.serverVersion !== message.fields.serverVersion) {
      console.log("Page was refreshed for server update.")
      window.location.reload();
    }

    if (message.fields && message.fields.hasDashboardsAccess !== undefined)
      store.dispatch({ type: stateConstants.SET_DASHBOARDS_ACCESS, hasDashboardsAccess: message.fields.hasDashboardsAccess });
    
    if (message.fields && (message.fields.databaseGUIDHash || message.fields.databaseServerURL)) {
      store.dispatch({type: stateConstants.SET_DATABASE_GUID_HASH, 
          databaseGUIDHash: message.fields.databaseGUIDHash || "",
          databaseServerURL: message.fields.databaseServerURL || "",
      });
    }

    if (message.fields && message.fields.featureToggles) {
      store.dispatch({
        type: stateConstants.SET_FEATURE_TOGGLES,
        featureToggles: message.fields.featureToggles,
      });
    }
    
    if (message.fields && !message.fields.active) {
      logoffHansoft();
      if (localStorage.getItem("hansoftauth")) {
        clearAuthToken();
        deleteRefreshToken();
        setErrorMessage( MsgLevelEnum.WARNING, MessageEnum.NO_LONGER_CONNECTED );
        history.push("/login");
      }
    }
  } else if (message.collection === "ProjectTasks" || message.collection === "Task") {
    message.type = todoConstants.UPDATE_TODO;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectMeta_")) {
    if ('id' in message) {
      if (message.id === '$Project') {
        message.type = projectConstants.UPDATE_PROJECT;
        store.dispatch(message);
      } else {
        message.type = projectConstants.UPDATE_PROJECTMETA;
        store.dispatch(message);
      }
    }
  } else if (message.collection.startsWith("ProjectResources_")) {
    message.type = resourceConstants.UPDATE_RESOURCE;
    store.dispatch(message);
  } else if (message.collection.startsWith("TaskComments_")) {
    message.type = commentConstants.UPDATE_COMMENT;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectSprints_")) {
    message.type = sprintConstants.UPDATE_SPRINT;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectWorkflows_")) {
    message.type = workflowConstants.UPDATE_WORKFLOW;
    store.dispatch(message);
  } else if (message.collection.startsWith("ResourceGroups")) {
    message.type = groupConstants.UPDATE_GROUP;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectMilestones_")) {
    message.type = milestoneConstants.UPDATE_MILESTONE;
    store.dispatch(message);
  } else if (message.collection.startsWith("Dashboards")) {
    message.type = dashboardConstants.UPDATE_DASHBOARD;
    store.dispatch(message);
  } else {
    console.log('Unknown update message for ' + PRODUCT);
  }
})
  
DDPConn.on('removed', message => {
  if (message.collection === "ProjectTasks" || message.collection === "Task") {
    message.type = todoConstants.REMOVE_TODO;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectMeta_")) {
    if ('id' in message && message.id !== "$ProjectID") {
      message.type = REMOVE_FIELD;
      store.dispatch(message);
    } else {
      message.type = projectConstants.REMOVE_PROJECT;
      store.dispatch(message);
    }
  } else if (message.collection.startsWith("ProjectResources_")) {
    message.type = resourceConstants.REMOVE_RESOURCE;
    store.dispatch(message);
  } else if (message.collection.startsWith("TaskComments_")) {
    message.type = commentConstants.REMOVE_COMMENT;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectSprints_")) {
    message.type = sprintConstants.REMOVE_SPRINT;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectWorkflows_")) {
    message.type = workflowConstants.REMOVE_WORKFLOW;
    store.dispatch(message);
  } else if (message.collection.startsWith("ResourceGroups")) {
    message.type = groupConstants.REMOVE_GROUP;
    store.dispatch(message);
  } else if (message.collection.startsWith("ProjectMilestones_")) {
    message.type = milestoneConstants.REMOVE_MILESTONE;
    store.dispatch(message);
  } else if (message.collection.startsWith("Dashboards")) {
    message.type = dashboardConstants.REMOVE_DASHBOARD;
    store.dispatch(message);
  } else if (message.collection.startsWith("ChartResultSets")) {
    message.type = chartResultSetsConstants.REMOVE_RESULTSET;
    store.dispatch(message);
  } else {
    console.log('Unknown remove message for ' + PRODUCT);
  }
})

function handleInitialSub(subs) {
  const state = store.getState();
  if (state.appState && state.appState.loadingMode) {
    for (let subId of subs)
      delete initialSubscriptionsPending[subId];
  
    if (Object.keys(initialSubscriptionsPending).length === 0) {
      let finishedSyncAt = new Date().getTime();
      console.log(`Finished initial sync in ${(finishedSyncAt - startedSyncAt) / 1000}s`);
      store.dispatch({type: stateConstants.CLEAR_LOADING_MODE });
    }
  }
}

let onReadyCallbacks = {};
let onMethodCompleteCallbacks = {};
let onNoSubCallbacks = {};

function handleCallbacks(callbacks, subscriptions) {
	for (const subId of subscriptions) {
		const callbacksforSub = callbacks[subId];
		if (callbacksforSub === undefined)
			continue;
		
		for (const callback of callbacksforSub) {
			try {
				callback();
			} catch (error) {
				// Ignore errors, but allow other callbacks to still fire.
			}
		}
	}
}

DDPConn.on("ready", message => {
  handleInitialSub(message.subs);
  handleCallbacks(onReadyCallbacks, message.subs);
});

DDPConn.on("nosub", message => {
  console.log(message);
  handleInitialSub([message.id]);
  handleCallbacks(onNoSubCallbacks, [message.id]);
});

export function hasDashboardsAccess() {
  const state = store.getState();
  return state && state.appState && state.appState.hasDashboardsAccess;
}

// Handles the login process to authenticate the user, using the DDP method
// authenticate

let onLoginCallback;

function loginHansoft(username, password, callback) {
  console.log('Login ' + PRODUCT)
  DDPConn.connect();  
  gUsername = username;
  gLosenord = password;
  onLoginCallback = callback;
}

function flagReconnect() {
  console.log('Flag reconnect ' + PRODUCT)
  bRefreshAction = true;
  bDisconnectAction = true;
  unsubscribe();
  store.dispatch({type: CLEAR_CACHE});
  DDPConn.disconnect();
}

function reconnectHansoft() {
  console.log('Reconnect ' + PRODUCT)
  DDPConn.connect();   
}

// Handles unsubscribing and disconnecting. I'm sort of controlling this from the web client
// (when I think naturually the subscription would remain persistent) due to the need for
// authentication and current limitations in the web service
function logoffHansoft() {
  console.log('logoff intervalID = ' + intervalID);
  clearInterval(intervalID);
  console.log('Logoff from ' + PRODUCT)
  bDisconnectAction = true;
  unsubscribe();
  store.dispatch({type: CLEAR_CACHE});
  DDPConn.disconnect();
  store.dispatch(userActions.logout());
}

function clearCookie(cookieName) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function clearAuthToken() {
  localStorage.removeItem('hansoftauth');
  clearCookie("hsauth");
}

let initialSubscriptionsPending = {};

function registerSubscription(subName) {
  let subId = DDPConn.sub(subName);
  initialSubscriptionsPending[subId] = true;
  return subId;
}

// Handles subscriptions to the three main subs. I may change this to move MyWork later after the other two are done for performance reasons
// (right now the list redraws as new data comes in)

let pendingSubscriptions = [];

function subscribe() {

  store.dispatch({type: stateConstants.SET_LOADING_MODE });

  startedSyncAt = new Date().getTime();
  
  const serverConnectionStateId = registerSubscription("ServerConnectionState");
  const myWorkID = registerSubscription('MyWork');

  // This is stored in the browser storage to handle refresh page
  const subdata = {
    product: 'hansoft', 
    workID: myWorkID.toString(),
    serverConnectionStateId: serverConnectionStateId.toString(),
  };

  localStorage.setItem('hansoftdata', JSON.stringify(subdata));

  for (const pending of pendingSubscriptions)
    pending();
}

// unsubscribes from the subs, I cached the ids for use here
function unsubscribe() {
  const datatoken = localStorage.getItem('hansoftdata');

  if (datatoken) {
    const subdata = JSON.parse(datatoken);
    DDPConn.unsub(subdata.workID);
    DDPConn.unsub(subdata.serverConnectionStateId);
  }
  localStorage.removeItem('hansoftdata');
}

function subscribeToChartResultSet(chartId) {
  return DDPConn.sub("ChartResultSet", [ chartId ]);
}

// Comments are done on a per item basis, otherwise same as above
function subComments(id) {
  const params = [ id ];
  return DDPConn.sub('TaskComments', params);
}

// Only used in tests...
window.subComments = function(taskId, resolve) {
  const subId = subComments(taskId);
  DDPConn.on("nosub", message => {
    if (message.id === subId)
      resolve(message);
  });
}

function unsubComments(taskCommentID) {
  DDPConn.unsub(taskCommentID);
}

function subscribeWithParams(subscription, params, options) {
  const subId = DDPConn.sub(subscription, params);
  if (options) {
	if (options.onReady) {
		if (onReadyCallbacks[subId] === undefined)
			onReadyCallbacks[subId] = [];

		onReadyCallbacks[subId].push(options.onReady);
	}

	if (options.onNoSub) {
		if (onNoSubCallbacks[subId] === undefined)
			onNoSubCallbacks[subId] = [];

		onNoSubCallbacks[subId].push(options.onNoSub);	
	}
  }

  return subId;
}

function unsubscribeFromSubscriptionId(subscriptionId) {
	if (subscriptionId === null || subscriptionId === undefined)
		return;
	
	DDPConn.unsub(subscriptionId);
	delete onReadyCallbacks[subscriptionId];
	delete onNoSubCallbacks[subscriptionId];
}

export function fetchMoreItems(subId, numItems) {
  DDPConn.method("fetchMoreItems", [subId, numItems]);
}

// DDP method used to save field data
function setField(field, id, value) {
  if (!field || field.trim().length === 0) {
    console.log('Empty field name during setField for task ID: ' + id + ' and value: ' + value);
  }
  DDPConn.method('SetTaskField', [id, field, value]);
}

// DDP method used to save field data
function postComment(id, parent, value) {
  let myParent = parent;
  if (typeof parent === 'string')
    myParent = parseInt(parent, 10);
  DDPConn.method('TaskPostComment', [id, myParent, value]);
}

// DDP method used to save field data
function editComment(id, postID, value) {
  let nPostID = -1;
  if (typeof postID === 'number') {
    nPostID = postID;
  } else if (typeof postID === 'string') {
    nPostID = parseInt(postID, 10);
  }
  DDPConn.method('TaskEditComment', [id, nPostID, value]);
}

export function getLoggedInResourceID() {
  const hansoftAuth = localStorage.getItem("hansoftauth");
  if (!hansoftAuth)
    return -1;
  return JSON.parse(hansoftAuth).resourceID || -1;
}

export function isFeatureToggledOn(feature) {
  const state = store.getState();
  return state.appState.featureToggles && state.appState.featureToggles.indexOf(feature) !== -1;
}

export function createTask(createOptions, ddpOptions) {
  const methodId = DDPConn.method("CreateBug", [{
    ProjectID: createOptions.projectId,
    Fields: createOptions.fields,
  }]);

  if (ddpOptions.onComplete)
    onMethodCompleteCallbacks[methodId] = ddpOptions.onComplete;
}

export {
  loginHansoft, 
  flagReconnect, 
  reconnectHansoft,
  logoffHansoft, 
  clearAuthToken,
  subscribe, 
  unsubscribe, 
  setField, 
  subComments, 
  unsubComments, 
  postComment, 
  editComment,
  subscribeToChartResultSet,
  unsubscribeFromSubscriptionId,
  subscribeWithParams,
}