export const todoConstants = {
  ADD_TODO: 'ADD_TODO',
  UPDATE_TODO: 'UPDATE_TODO',
  REMOVE_TODO: 'REMOVE_TODO',
  REMOVE_SPECIFIC_TASK: 'REMOVE_SPECIFIC_TASK',
  RESET: 'RESET_TODO'
};

export const dueDateConstants = {
  PAST_DUE: 1,
  TODAY: 2,
  THIS_WEEK: 3,
  NEXT_WEEK: 4,
  LATER: 5
};

export function getDueDateGroupName(groupEnum) {
  switch(groupEnum) {
    case dueDateConstants.PAST_DUE:
      return 'Past Due';
    case dueDateConstants.TODAY:
      return 'Today';
    case dueDateConstants.THIS_WEEK:
      return 'This Week';
    case dueDateConstants.NEXT_WEEK:
      return 'Next Week';
    case dueDateConstants.LATER:
      return 'Later';
    default:
      return '';
  }
}