import React from 'react';
import isEqual from 'lodash/isEqual'

import BaseField from './BaseField'
import {setField} from '../DDPJS/DDPJS';
import { getEnumIcon } from '../_images/images';
import SingleSelectField from './components/SingleSelect';
import { isValidObject } from '../_helpers/validation_functions'

export default class MultiEnumField extends BaseField {

  state = {
    originalValue: this.getValue(),
    value: this.getValue()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isEqual(prevState.value, this.getValue()) === false) {
      this.setState({originalValue: this.getValue(), value: this.getValue()});
    }
  }

  onChange = (event, value) => {
    if (this.readOnlyFlag()) {
      return
    }

    let myValue = value;

    this.setState({originalValue: myValue, value: myValue})
    setField(this.getFieldDefinition().id, this.getItemID(), myValue)
  }

  getEnumList() {
    if (this.getFieldDefinition().Type === 'MultiEnum') {
      return this.getFieldDefinition().Enum
    } else if (this.getFieldDefinition().Type === 'InlineMultiEnum') {
      return this.getValue().Enum
    }
  }

  getValueArray() {
    if (this.getFieldDefinition().Type === 'MultiEnum') {
      let value = this.getValue();
      if (value) {
        if (typeof value.indexOf === "function")
          return value;
        else
          return [value];
      }
    } else if (this.getFieldDefinition().Type === 'InlineMultiEnum') {
      return this.getValue().Value
    }
  }

  render() {
    if (!isValidObject(this.getFieldDefinition())) {
      return (null);
    }
    
    const enumLength = this.getEnumList() ? this.getEnumList().length : 0;
    let options = [];

    let currentValues = this.getValueArray();
    let displayIcon;
    let displayValue = "";

    function addToDisplayValue(name) {
      if (displayValue)
        displayValue += " " + name;
      else 
        displayValue = name;
    }

    let selectedValuesLookup = {};

    for (let index = 0; index < enumLength; ++index) {
      const value = this.getEnumList()[index][0];
      const isSelected = currentValues.indexOf(value) !== -1;

      options.push({
        image: getEnumIcon(this.getEnumList()[index][2]),
        selected: isSelected,
        text: this.getEnumList()[index][1],
        value: value,
      });

      if (isSelected) {
        selectedValuesLookup[value] = {
          icon: getEnumIcon(this.getEnumList()[index][2]),
          text: this.getEnumList()[index][1],
        };
      }
    }

    for (let index = 0; index < currentValues.length; ++index) {
      const lookupValue = selectedValuesLookup[currentValues[index]];
      if (!lookupValue)
        continue; // Item has been deleted.
        
      if (!displayIcon)
        displayIcon = selectedValuesLookup[currentValues[index]].icon;
      addToDisplayValue(selectedValuesLookup[currentValues[index]].text);
    }

    return (
      <SingleSelectField
        icon={displayIcon}
        text={displayValue}
        fieldName={this.getFieldDefinition().DisplayName}
        disabled={this.readOnlyFlag()}
        isRequiredField={this.isWorkflowRequiredField()}
        multiSelection={true}
        onSelectionChanged={(newValues) => {
          this.onChange(null, newValues);
        }}
        options={options}
      />
    );
  }
}
