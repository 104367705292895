import { errormessageConstants } from '../_constants/errormessage.constants';
import { MessageEnum, MsgLevelEnum } from '../MessageDialog/MessageIndex'

const errormessage = (state = { iserror: false, messageID: MessageEnum.NO_ERROR, msgLevel: MsgLevelEnum.INFORMATION, customMessage: '' }, action) => {
  switch (action.type) {
    case errormessageConstants.SET_ERROR_MESSAGE:
     return {...state, iserror: true, messageID: action.messageID, msgLevel: action.msgLevel }
    case errormessageConstants.SET_CUSTOM_MESSAGE:
     return {...state, iserror: true, messageID: action.messageID, msgLevel: action.msgLevel, customMessage: action.customMessage }
    case errormessageConstants.CLEAR_ERROR_MESSAGE:
     return {...state, iserror: false, messageID: MessageEnum.NO_ERROR, msgLevel: MsgLevelEnum.INFORMATION, customMessage: '' }
    default:
      return state
  }
}

export default errormessage