import assignIn from "lodash/merge";

import { milestoneConstants } from "../_constants/milestone.constants";
import { CLEAR_CACHE } from "../_constants/general.constants";
import { extractFromString } from "../_helpers/projectid_parser";

function buildObject(milestone, fields) {
	let newObject = {
		...milestone,
		fields: {},
	};

	assignIn(newObject.fields, fields);
	return newObject;
}

const milestones = (state = [], action) => {
	let projectID;
	if ("collection" in action) 
	  projectID = extractFromString("ProjectMilestones_", action.collection);

	switch (action.type) {
		case milestoneConstants.ADD_MILESTONE: {
			let milestone = { $ProjectID: projectID, $ID: action.id };
			return [
				...state,
				buildObject(milestone, action.fields),
			];
		}
		case milestoneConstants.UPDATE_MILESTONE: {
			return state.map(milestone => {
				if (milestone.$ID === action.id && milestone.$ProjectID === projectID)
					return buildObject(milestone, action.fields);
				return milestone;
			});
		}
		case milestoneConstants.REMOVE_MILESTONE: {
			const index = state.findIndex(milestone => milestone.$ID === action.id && milestone.$ProjectID === projectID);
			if (index >= 0) {
				return [
					...state.slice(0, index),
					...state.slice(index + 1)
				];
			} else {
				return state;
			}
		}
		case CLEAR_CACHE:
			return [];
		default:
			return state;
	}
}

export default milestones;