import React from 'react';

import BaseField from './BaseField'
import { findWorkflow } from '../_reducers/workflow.reducer'
import ItemDetailsField from './components/ItemDetailsField';
import { isValidObject } from '../_helpers/validation_functions'

export default class ReadOnlyField extends BaseField {

  state = {
    value: this.getValue()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.value !== this.getValue()) {
      this.setState({value: this.getValue()})
    }
  }

  getDisplayedValue() {
    if (this.getFieldDefinition().Type === 'Workflow') {
      const workflow = findWorkflow(this.getPlanningProjectID(), this.state.value)
      if (isValidObject(workflow) && 'fields' in workflow && 'Name' in workflow.fields) {
        return workflow.fields.Name;
      } else {
        return ''
      }
    }

    if (this.props.$FieldID === 'Duration') {
      return this.state.value.toString() + 'd';
    } else {
      return this.state.value
    }
  }

  render() {
    if (!isValidObject(this.getFieldDefinition())) {
      return (null)
    }

    if (this.props.$FieldID === 'Duration' && this.props.task.isInSprint()) {
      return (null)
    } 

    return (
      <ItemDetailsField
        fieldName={this.getFieldDefinition().DisplayName}
        isRequiredField={this.isWorkflowRequiredField()}      
        children={
          <input
            readOnly
            value={this.getDisplayedValue()}
          />
        }
      />
    );
  }
}

