import React from 'react';
import { connect } from 'react-redux';

import { getProjectInfo } from '../_reducers/project.reducer'
import { getUniqueFieldForType } from '../_helpers/itemdetail_functions'
import MultilineEditor from './MultiLine/MultilineEditor';

class UniqueFieldControl extends React.Component {

  render() {
    const { multilinefields } = this.props;

    const currentItem = this.props.task;
    const projectData = getProjectInfo(currentItem.$ProjectID);
    const projectType = projectData[0].Type;
    const uniqueField = getUniqueFieldForType(projectType);
    if (uniqueField === null)
      return (null);

    let fieldData = null;
    const myProjectID = parseInt(currentItem.$ProjectID, 10);
    const myMultilineFields = multilinefields.filter(field => field.projectID === myProjectID);
    for (let iter = 0; iter < myMultilineFields.length; iter++) {
      if (myMultilineFields[iter].id === uniqueField[0]) {
        let fieldValue = '';
        if (myMultilineFields[iter].id in currentItem.fields) {
          fieldValue = currentItem.fields[myMultilineFields[iter].id];
        }
        fieldData = {
          id: currentItem.$ID, 
          fieldID: myMultilineFields[iter].id, 
          fieldName: myMultilineFields[iter].DisplayName,
          readOnly: myMultilineFields[iter].ReadOnly, 
          fieldValue: fieldValue};
      }
    }

    if (fieldData === null)
      return (null)

    return (
      <div style={UniqueControlStyle} id='uniquefield_link'>
        <span>{fieldData.fieldName}</span>
        <div style={{marginTop: "4px"}}>
          <MultilineEditor {...fieldData } />
        </div>
      </div>
    );
  }
}

const UniqueControlStyle = {
  padding: '0 0 16px'
}

const mapStateToProps = state => ({
  multilinefields: state.multilinefields,
  todos: state.todos
})

export default UniqueFieldControl = connect(mapStateToProps)(UniqueFieldControl);