import React from 'react';
import { connect } from 'react-redux';
import { ImgOpenLink, ImgOpenLinkBlack } from '../_images/images';

class HansoftURLLinkControl extends React.Component {
  constructor(props) {
    super(props);
    this.openLink = this.openLink.bind(this);
  }

  getLinkString() {
    let sURL = 'javascript;';
    if ('fields' in this.props.task && 'URL' in this.props.task.fields) {
      sURL = this.props.task.fields.URL;
    }
    return sURL;
  }

  openLink() {
    window.open(this.getLinkString(), "_blank");
  }

  render() {
    if ('task' in this.props === false)
      return null;

    return (
      <div className="hansoftlink" onClick={() => this.openLink()}>
        <img className="hansoftlink-icon" src={this.props.theme === "dark" ? ImgOpenLink : ImgOpenLinkBlack} alt=""/>
        <div className="hansoftlink-text">View in Hansoft</div>
      </div>
    );
  }
}

export default HansoftURLLinkControl = connect()(HansoftURLLinkControl);
