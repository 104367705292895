import React from 'react'
import isEqual from 'lodash/isEqual'

import BaseField from './BaseField'
import {setField, subscribeWithParams, unsubscribeFromSubscriptionId} from '../DDPJS/DDPJS'
import { ImgUserGreen, ImgUserGroup } from '../_images/images'
import { resourceEntryTypes, resourceUserTypes } from '../_constants/resource.constants' 
import { getProjectInfo } from '../_reducers/project.reducer'
import SingleSelectField from './components/SingleSelect'
import { isValidObject } from '../_helpers/validation_functions'

export default class ResourcesField extends BaseField {

  subcriptionParameters = {
    projectID : this.props.task.$ProjectID,
    sprintID : -1,
    bIncludeGroups : true
  };

  state = {
    value: buildResourceValueArray(this.getValue()),
    subscriptionId: subscribeWithParams("ProjectResources", this.subcriptionParameters)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isEqual(prevState.value, buildResourceValueArray(this.getValue())) === false) {
      this.setState({value: buildResourceValueArray(this.getValue())});
    }
  }

  componentWillUnmount() {
		if (this.state.subscriptionId)
			unsubscribeFromSubscriptionId(this.state.subscriptionId);
	}

  onChange = (event, value) => {
    if (this.readOnlyFlag())
      return;
      
    this.setState({value});
    const updateArray = parseSelectionArray(value);
    setField(this.getFieldDefinition().id, this.getItemID(), updateArray);
  }

  render() {
    if (!isValidObject(this.getFieldDefinition())) {
      return (null);
    }
    
    const projectData = getProjectInfo(this.props.task.$ProjectID);
    if (!projectData || !projectData[1])
      return null;

    const resourcesLength = this.props.resources ? this.props.resources.length : 0;
    const groupsLength = this.props.groups ? this.props.groups.length : 0;
    
    let options = [];

    let currentValues = buildResourceValueArray(this.getValue());
    let displayValue = "";
    let userDisplayValues = [];
    let groupsDisplayValues = [];

    let selectedUsersLookup = {};

    for (let index = 0; index < resourcesLength; ++index) {
      if (this.props.resources[index].projectID !== projectData[1].id || 
        (this.props.resources[index].Type !== resourceUserTypes.EPM_ResourceType_Normal && !this.props.task.isQATask()) || 
        (this.props.resources[index].Type !== resourceUserTypes.EPM_ResourceType_Normal && this.props.resources[index].Type !== resourceUserTypes.EPM_ResourceType_QA_Account))
        continue;

      const value = buildResourceValue(resourceEntryTypes.NORMAL, this.props.resources[index].id);
      const isSelected = currentValues.indexOf(value) !== -1;

      options.push({
        image: ImgUserGreen,
        selected: isSelected,
        text: this.props.resources[index].Name,
        sortValue: this.props.resources[index].SortName,
        value: value,
      });

      if (isSelected)
        selectedUsersLookup[value] = this.props.resources[index].Name;
    }

    let selectedGroupsLookup = {};

    for (let index = 0; index < groupsLength; ++index) {
      const value = buildResourceValue(resourceEntryTypes.GROUP, this.props.groups[index].id);
      const isSelected = currentValues.indexOf(value) !== -1;

      options.push({
        image: ImgUserGroup,
        selected: isSelected,
        text: this.props.groups[index].Name,
        sortValue: this.props.groups[index].SortName,
        value: value,
      });

      if (isSelected)
        selectedGroupsLookup[value] = this.props.groups[index].Name;
    }

    for (let index = 0; index < currentValues.length; ++index) {
      const value = currentValues[index];
      if (isValidObject(selectedUsersLookup[value])) {
        userDisplayValues.push(selectedUsersLookup[value]);
      } else if (isValidObject(selectedGroupsLookup[value]))
        groupsDisplayValues.push(selectedGroupsLookup[value]);
    }

    displayValue = userDisplayValues.concat(groupsDisplayValues).join("; ");

    options.sort(function(first, second) {
      const firstSortName = first.sortValue ? first.sortValue : first.text;
      const secondSortName = second.sortValue ? second.sortValue : second.text;

      if (firstSortName.toUpperCase() < secondSortName.toUpperCase()) return -1;
      if (firstSortName.toUpperCase() > secondSortName.toUpperCase()) return 1;
      return 0;
    });

    return (
      <SingleSelectField
        subscriptionId={this.state.subscriptionId}
        text={displayValue}
        fieldName={this.getFieldDefinition().DisplayName}
        disabled={this.readOnlyFlag()}
        isRequiredField={this.isWorkflowRequiredField()}
        multiSelection={true}
        onSelectionChanged={(newValues) => {
          this.onChange(null, newValues);
        }}
        findData={{
          onFindTextChanged : (newFindText) => {
            this.onFindTextChanged(newFindText, "ProjectResources", this.subcriptionParameters);
          }
        }}
        options={options}
      />
    )
  }
}

ResourcesField.UserImage = {
  width: '18px', 
  height: '18px',
  verticalAlign: 'middle'
}

function buildResourceValueArray(array) {
  let returnArray = [];
  if (array) {
    const arrayLen = array.length;
    for (let index = 0; index < arrayLen; ++index) {
      const myString = buildResourceValue(array[index][0], array[index][1]);
      returnArray.push(myString);
    }
    return returnArray;
  } else {
    return [];
  }
}

function buildResourceValue(type, value) {
  return type.toString() + ':' + value.toString();
}

function parseSelectionArray(array) {
  let returnArray = [];
  const arrayLen = array.length;
  for (let index = 0; index < arrayLen; ++index) {
    const myArray = parseSelection(array[index]);
    returnArray.push(myArray);
  }
  return returnArray;
}

function parseSelection(selection) {
  let parsedArray = selection.split(":");
  const arrayLen = parsedArray.length;
  for (let index = 0; index < arrayLen; ++index) {
    parsedArray[index] = parseInt(parsedArray[index], 10);
  }
  return parsedArray;
}
