import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import BaseField from "../BaseField";
import { setField } from "../../DDPJS/DDPJS";
import { ImgNotDone, ImgInProgress, ImgCompleted, ImgBlocked, ImgToBeDeleted, getEnumIcon, ImgWarningTriangleYellow } from "../../_images/images";
import SingleSelectField from "../components/SingleSelect";
import { findWorkflow, getConnectedStatuses, getMappingObject, getStatusToWorkflowStatuses } from "../../_reducers/workflow.reducer";

const statusIcons = [
    ImgNotDone, 
    ImgInProgress,
    ImgCompleted,
    ImgBlocked,
    ImgToBeDeleted,
];

class WorkflowStatusLabel extends React.Component {
	render() {
		return (
			<span>
				<img className="singleselect-icon" src={this.props.workflowIcon} alt={""}/>
				{this.props.workflowText}
				{this.props.statusIcon ?
						<span>
							<span> / </span>
							<img className="singleselect-icon" src={this.props.statusIcon} alt={""}/>
							{this.props.statusText}
						</span>
					:
						null
				}
			</span>
		);
	}
}

WorkflowStatusLabel.propTypes = {
	workflowIcon: PropTypes.string.isRequired,
	workflowText: PropTypes.string.isRequired,
	statusIcon: PropTypes.string,
	statusText: PropTypes.string,
};

class WorkflowStatusFieldContainer extends BaseField { 
	constructor(props) {
		super(props);
		this.onSelectionChanged = this.onSelectionChanged.bind(this);
	}
	
	state = {
		value: this.getValue(),
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.value !== this.getValue())
			this.setState({ value: this.getValue() });
	}
	
	onSelectionChanged(newValue) {
		if (this.readOnlyFlag())
			return;

		let valueParts = newValue.split(":");
		if (valueParts.length !== 2)
			return;

		let value = parseInt(valueParts[1]);

		if (valueParts[0] === "WorkflowStatus") {
			const workflowValue = this.getWorkflowStatus();
			const mapping = getMappingObject(this.getWorkflow(), value, workflowValue);
			if (mapping === null) {
				this.setState({ value });
				setField("Status", this.getItemID(), value);
			} else {
				setField("WorkflowStatus", this.getItemID(), mapping.WorkflowStatusID);
			}	
		} else {
			setField("WorkflowStatus", this.getItemID(), value);
		}

		if (typeof this.props.handler === "function")
			this.props.handler();
	}
	
	getWorkflow() {
		return findWorkflow(this.getPlanningProjectID(), this.props.task.getProperty("Workflow"));
	}

	getWorkflowStatus() {
		return this.props.task.getProperty("WorkflowStatus");
	}

	getWorkflowText(workflow, workflowStatus) {
		if (!workflow)
			return "";
		
		for (let workflowObject of workflow.fields.WorkflowObjects) {
			if (workflowObject.ID === workflowStatus)
				return workflowObject.Name;
		}
		
		return "";
	}

	getWorkflowIcon(workflow, workflowStatus) {
		if (!workflow)
			return -1;

		const workflowObject = workflow.fields.WorkflowObjects.find(workflow => workflow.ID === workflowStatus);
		return workflowObject ? workflowObject.Icon : -1;
	}

	getStatusIcon(value) {
		if (value >= 2 && value <= statusIcons.length + 2)
			return statusIcons[value - 2];
		return null;
	}

	getStatusText(workflow) {
		if (workflow && workflow.hideItemStatus())
			return;

		for (let index = 0; index < this.getFieldDefinition().Enum.length; ++index) {
			if (this.getFieldDefinition().Enum[index][0] === this.state.value)
				return this.getFieldDefinition().Enum[index][1];
		}

		return "";
	}

	getStatusMenuText(workflow, value) {
		const workflowStatus = this.getWorkflowStatus();
		if (!workflowStatus)
			return;
		
		const status = this.getFieldDefinition().Enum.find(item => item[0] === value);
		const mapping = getMappingObject(workflow, value, workflowStatus);
		if (mapping)
			return status[1] + " \u2192 " + mapping.WorkflowStatusName;
		return status[1];
	}

	getWorkflowStatusItems(workflow) {
		let items = [];

		if (!workflow || workflow.hideItemStatus())
			return items;
		
		const enumLength = this.getFieldDefinition().Enum ? this.getFieldDefinition().Enum.length : 0;
		for (let index = 0; index < enumLength; ++index) {
			if (this.getFieldDefinition().Enum[index][0] !== 1) {
				items.push({
					image: this.getStatusIcon(this.getFieldDefinition().Enum[index][0]),
					selected: this.getFieldDefinition().Enum[index][0] === this.state.value,
					text: this.getStatusMenuText(workflow, this.getFieldDefinition().Enum[index][0]),
					value: "WorkflowStatus:" + this.getFieldDefinition().Enum[index][0],
				});
			}
		}

		return items;
	}

	getConnectedStatusMappings(workflow) {
		let items = [];
		const workflowStatus = this.getWorkflowStatus();
		if (!workflowStatus)
			return items;

		const connectedStatuses = getConnectedStatuses(workflow, workflowStatus);
		const connectedLength = connectedStatuses ? connectedStatuses.length : 0;

		for (let index = 0; index < connectedLength; ++index) {
			let statusToWorkflowStatus = getStatusToWorkflowStatuses(workflow, workflowStatus);
			if (!statusToWorkflowStatus)
				statusToWorkflowStatus = [];

			const mapping = statusToWorkflowStatus.find(map => map.WorkflowStatusID === connectedStatuses[index].ConnectedToID);
			if (!mapping || workflow.hideItemStatus()) {
				items.push({
					image: getEnumIcon(this.getWorkflowIcon(workflow, connectedStatuses[index].ConnectedToID)),
					text: this.getWorkflowText(workflow, connectedStatuses[index].ConnectedToID),
					selected: false,
					value: "Status:" + connectedStatuses[index].ConnectedToID,
				});
			}
		}

		return items;
	}

    render() {
		if (!this.getFieldDefinition())
			return null;

		const workflow = this.getWorkflow();
		const isListView = ('isListView' in this.props) ? this.props.isListView : false;

		let options = this.getWorkflowStatusItems(workflow);
		let connectedStatuses = this.getConnectedStatusMappings(workflow);
		if (connectedStatuses.length >= 1) {
			if (options.length >= 1) {
				options.push({
					seperator: true,
				});
			}

			options = options.concat(connectedStatuses);
		}

		if (options.length === 0) {
			options.push({
				image: ImgWarningTriangleYellow,
				text: "There are no transitions from this workflow status",
				selected: false,
				value: "invalid",
			});
		}

        return (
			<SingleSelectField
				fieldName={!isListView ? this.getFieldDefinition().DisplayName : null}
				disabled={this.readOnlyFlag()}
				isRequiredField={this.isWorkflowRequiredField()}
				customDisplayValue={
					<WorkflowStatusLabel
						workflowIcon={getEnumIcon(this.getWorkflowIcon(workflow, this.getWorkflowStatus()))}
						workflowText={this.getWorkflowText(workflow, this.getWorkflowStatus())}
						statusIcon={workflow && !workflow.hideItemStatus() ? this.getStatusIcon(this.state.value) : null}
						statusText={this.getStatusText(workflow)}
					/>
				}
				options={options}
				onSelectionChanged={this.onSelectionChanged}
			/>
        );
    }
}

const mapStateToProps = state => ({
	workflows: state.workflows,
	singlelinefields: state.singlelinefields,
	todos: state.todos,
});

export default connect(mapStateToProps)(WorkflowStatusFieldContainer);