import { stateConstants } from '../_constants/state.constants';
import { CLEAR_CACHE } from '../_constants/general.constants';

const appState = (state = 
    { 
      isconnected: false, 
      currentTaskID: "", 
      currentDashboardID: "",
      editMode: false, 
      loadingMode: false, 
      filterMode: false, 
      hasDashboardsAccess: false,
      featureToggles: [],
      serverVersion: ""  // deliberately not included in CLEAR_CACHE
    }, action) => {
  switch (action.type) {
    case stateConstants.SET_CONNECTED:
      return {...state, isconnected: true };
    case stateConstants.CLEAR_CONNECTED:
      return {...state, isconnected: false };
    case stateConstants.SET_LOADING_MODE:
      return {...state, loadingMode: true };
    case stateConstants.CLEAR_LOADING_MODE:
      return {...state, loadingMode: false }
    case stateConstants.TOGGLE_LOADING_MODE:
      return {...state, loadingMode: state.loadingMode ? false: true };
    case stateConstants.SET_FILTER_MODE:
      return {...state, filterMode: true };
    case stateConstants.CLEAR_FILTER_MODE:
      return {...state, filterMode: false };
    case stateConstants.TOGGLE_FILTER_MODE:
      return {...state, filterMode: state.filterMode ? false: true };
    case stateConstants.SET_DATABASE_GUID_HASH:
      return {...state, databaseGUIDHash: action.databaseGUIDHash, databaseServerURL: action.databaseServerURL }
    case stateConstants.SET_CURRENT_TASK_ID: {
      return {...state, currentTaskID: action.currentTaskID };
    }
    case stateConstants.SET_FEATURE_TOGGLES: {
      return { ...state, featureToggles: action.featureToggles };
    }
    case stateConstants.SET_CURRENT_DASHBOARD_ID: {
      return { ...state, currentDashboardID: action.currentDashboardID };
    }
    case stateConstants.CLEAR_CURRENT_TASK_ID:
      return {...state, currentTaskID: "" };
    case stateConstants.SET_SERVER_VERSION:
      return {...state, serverVersion: action.serverVersion };
    case stateConstants.SET_DASHBOARDS_ACCESS:
      return {...state, hasDashboardsAccess: action.hasDashboardsAccess };
    case stateConstants.CLEAR_SERVER_VERSION:
      return {...state, serverVersion: "" };
    case CLEAR_CACHE:
      return {...state, currentTaskID: "", currentDashboardID: "", editMode: false, loadingMode: false, databaseGUIDHash: "", databaseServerURL: "", featureToggles: []  }
    default:
      return state
  }
}

export default appState