import React from "react";

import { isValidObject } from '../_helpers/validation_functions'

import ImgArrowDown from "./ArrowDown.svg"
import ImgArrowDownWhite from "./ArrowDownWhite.svg"

import ImgNotDone from './Status_NotDone.svg' // relative path to image 
import ImgInProgress from './Status_InProgress.svg' 
import ImgCompleted from './Status_Completed.svg' 
import ImgBlocked from './Status_Blocked.svg' 
import ImgToBeDeleted from './Status_ToBeDeleted.svg' 

import ImgPriorityNone from './priority_none_set.svg' 
import ImgPriorityVeryLow from './priority_very_low.svg' 
import ImgPriorityLow from './priority_low.svg' 
import ImgPriorityMedium from './priority_medium.svg' 
import ImgPriorityHigh from './priority_high.svg' 
import ImgPriorityVeryHigh from './priority_very_high.svg' 

import ImgHelp from './help.svg'
import ImgUser from './user.svg'
import ImgUserGreen from './user_green.svg'
import ImgLogoOpen from './logo_sidebar_open.svg'
import ImgLogoCollapsed from './logo_sidebar_collapsed.svg'
import ImgLoginLogo from './Hansoft_login_logo.svg'
import ImgLoginHelix from './login-helix.png'
import ImgEditComment from './edit_comments.svg'
import ImgUserGroup from './User_group.svg'

import ImgPagingFirstDisabled from './paging_first_disabled.svg'   
import ImgPagingFirstEnabled from './paging_first_enabled.svg'       
import ImgPagingLastDisabled from './paging_last_disabled.svg'         
import ImgPagingLastEnabled from './paging_last_enabled.svg'           
import ImgPagingNextDisabled from './paging_next_disabled.svg'        
import ImgPagingNextEnabled from './paging_next_enabled.svg'
import ImgPagingPreviousDisabled from './paging_previous_disabled.svg'
import ImgPagingPreviousEnabled from './paging_previous_enabled.svg'  
import ImgScreenShiftLeft from './screen_shift_button_left.svg'
import ImgScreenShiftRight from './screen_shift_button_right.svg'

import ImgComment from './comment.svg'
import ImgNoComment from './nocomment.svg'

import ImgWarningMessage from './deep red alert.svg'
import ImgInfoMessage from './TooltipMessage.svg'

import ImgReleaseOrange from './Timeline_ReleaseOrange.svg'

import ImgCheckMark from './CheckMark.svg';

import ImgWhiteBox  from './WhiteBox.svg'
import ImgGreenBox  from './GreenBox.svg'
import ImgYellowBox  from './YellowBox.svg'
import ImgRedBox  from './RedBox.svg'
import ImgBlueBox  from './BlueBox.svg'
import ImgMagentaBox  from './PurpleBox.svg'
import ImgOrangeBox  from './OrangeBox.svg'
import ImgDoneBox  from './Done.svg'
import ImgNotDoneBox  from './NotDone.svg'
import ImgBlueFace  from './BlueFace.svg'
import ImgGreyFace  from './GreyFace.svg'
import ImgOrangeFace  from './OrangeFace.svg'
import ImgGoldFace from './GoldFace.svg'
import ImgLocked from './Locked.svg'
import ImgUnlocked from './Unlocked.svg'
import ImgGroup from './Group.svg'
import ImgGreyGroup from './GreyGroup.svg'
import ImgLightGreyGroup from './LightGreyGroup.svg'
import ImgGreenCircle from './GreenCircle.svg'
import ImgYellowCircle from './YellowCircle.svg'
import ImgRedCircle from './RedCircle.svg'
import ImgBlueCircle from './BlueCircle.svg'
import ImgPurpleCircle from './PurpleCircle.svg'
import ImgOrangeCircle from './OrangeCircle.svg'
import ImgYesBox from './YesBox.svg'
import ImgNoBox from './NoBox.svg'
import ImgQuestionBox from './QuestionBox.svg'
import ImgProject from './Project.svg'
import ImgDeletedResource from './DeletedResource.svg'
import ImgReOpenBug from './ReOpenBug.svg'
import ImgNewBug from './NewBug.svg'
import ImgAssignedBug from './AssignedBug.svg'
import ImgFixedBug from './FixedBug.svg'
import ImgVerifiedBug from './VerifiedBug.svg'
import ImgClosedBug from './ClosedBug.svg'
import ImgWorkflow from './Workflow.svg'
import ImgProgress from './Progress.svg'
import ImgStep from './Step.svg'
import ImgPipeline from './Pipeline.svg'
import ImgReport from './Report.svg'
// priority icons
import ImgPlus from './Plus.svg'
// no icon
import ImgWarningTriangleRed from './WarningTriangleRed.svg'
import ImgWarningTriangleYellow from './WarningTriangleYel.svg'
import ImgExclamationMark from './ExclamationMark.svg'
import ImgInformation from './Information.svg'
import ImgPause from './Pause.svg'
import ImgStop from './StopIconBright.svg'

import ImgSprint from './Sprint.svg';
import ImgOpenLink from './OpenLink.svg';
import ImgOpenLinkBlack from './OpenLinkBlack.svg';
import ImgChain from './Chain.svg';
import ImgAttachment from './Attachment.svg';


import ImgBold from './Bold.svg';
import ImgUnderline from './Underline.svg';
import ImgItalics from './Italics.svg';
import ImgColor from './Color.svg';
import ImgBulletList from './BulletList.svg';
import ImgNumberedList from './NumberedList.svg';
import ImgErase from './Erase.svg';
import ImgLink from './Link.svg';
import ImgCode from './Code.svg';

import ImgFunnel from './funnel.svg';

import ImgEmptyDashboard from './dashboardempty.png';

export { 
  ImgNotDone, 
  ImgInProgress, 
  ImgCompleted, 
  ImgBlocked, 
  ImgToBeDeleted, 
  ImgPriorityNone, 
  ImgPriorityVeryLow, 
  ImgPriorityLow, 
  ImgPriorityMedium, 
  ImgPriorityHigh, 
  ImgPriorityVeryHigh, 
  ImgHelp, 
  ImgUser, 
  ImgLogoOpen, 
  ImgLogoCollapsed, 
  ImgLoginLogo, 
  ImgUserGreen, 
  ImgLoginHelix, 
  ImgEditComment, 
  ImgPagingFirstDisabled, 
  ImgPagingFirstEnabled, 
  ImgPagingLastDisabled, 
  ImgPagingLastEnabled, 
  ImgPagingNextDisabled, 
  ImgPagingNextEnabled, 
  ImgPagingPreviousDisabled, 
  ImgPagingPreviousEnabled,
  ImgScreenShiftLeft,
  ImgScreenShiftRight,
  ImgComment,
  ImgNoComment,
  ImgUserGroup,
  ImgWarningMessage,
  ImgInfoMessage,
  ImgReleaseOrange,
  ImgCheckMark,
  ImgSprint,
  ImgPipeline,
  ImgOpenLink,
  ImgChain,
  ImgAttachment,

  // Toolbar
  ImgBold,
  ImgUnderline,
  ImgItalics,
  ImgColor,
  ImgBulletList,
  ImgNumberedList,
  ImgErase,
  ImgLink,
  ImgCode,

  ImgWarningTriangleYellow,
  ImgFunnel,
  ImgOpenLinkBlack,

  ImgArrowDown,
  ImgArrowDownWhite,

  ImgEmptyDashboard,
}

const EnumImageArray = [
  {id: 2, image: ImgWhiteBox},
  {id: 3, image: ImgGreenBox},
  {id: 4, image: ImgYellowBox},
  {id: 5, image: ImgRedBox},
  {id: 6, image: ImgBlueBox},
  {id: 7, image: ImgMagentaBox},
  {id: 8, image: ImgOrangeBox},
  {id: 9, image: ImgDoneBox},
  {id: 10, image: ImgNotDoneBox},
  {id: 11, image: ImgBlueFace},
  {id: 12, image: ImgGreyFace},
  {id: 13, image: ImgOrangeFace},
  {id: 14, image: ImgGoldFace},
  {id: 15, image: ImgLocked },
  {id: 16, image: ImgUnlocked },
  {id: 23, image: ImgGroup },
  {id: 24, image: ImgGreyGroup },
  {id: 25, image: ImgLightGreyGroup },
  {id: 26, image: ImgGreenCircle },
  {id: 27, image: ImgYellowCircle },
  {id: 28, image: ImgRedCircle },
  {id: 29, image: ImgBlueCircle },
  {id: 30, image: ImgPurpleCircle },
  {id: 31, image: ImgOrangeCircle },
  {id: 32, image: ImgYesBox },
  {id: 33, image: ImgNoBox },
  {id: 34, image: ImgQuestionBox},
  {id: 35, image: ImgProject},
  {id: 36, image: ImgDeletedResource },
  {id: 17, image: ImgReOpenBug },
  {id: 18, image: ImgNewBug },
  {id: 19, image: ImgAssignedBug },
  {id: 20, image: ImgFixedBug},
  {id: 21, image: ImgVerifiedBug },
  {id: 22, image: ImgClosedBug },
  {id: 37, image: ImgWorkflow },
  {id: 38, image: ImgProgress },
  {id: 39, image: ImgStep },
  {id: 40, image: ImgPipeline },
  {id: 41, image: ImgReport },
  {id: 42, image: ImgPriorityVeryHigh},
  {id: 43, image: ImgPriorityHigh},
  {id: 44, image: ImgPriorityMedium}, 
  {id: 45, image: ImgPriorityLow}, 
  {id: 46, image: ImgPriorityVeryLow},
  {id: 47, image: ImgPriorityNone}, 
  {id: 48, image: ImgPlus},
  {id: 49, image: null}, 
  {id: 50, image: ImgWarningTriangleRed},
  {id: 51, image: ImgWarningTriangleYellow},
  {id: 52, image: ImgExclamationMark},
  {id: 53, image: ImgInformation},
  {id: 54, image: ImgPause},
  {id: 55, image: ImgStop}
]

const ConfidenceImageArray = [
  {id: 1, image: ImgWhiteBox},
  {id: 2, image: ImgRedBox},
  {id: 3, image: ImgYellowBox},
  {id: 4, image: ImgGreenBox}
]

const SeverityImageArray = [
  {id: 1, image: ImgWhiteBox},
  {id: 2, image: ImgRedBox},
  {id: 3, image: ImgOrangeBox},
  {id: 4, image: ImgYellowBox},
  {id: 5, image: ImgGreenBox}
];

const RiskImageArray = [
  {id: 1, image: ImgWhiteBox},
  {id: 2, image: ImgGreenBox},
  {id: 3, image: ImgYellowBox},
  {id: 4, image: ImgRedBox},
];

export function getEnumIcon(value, $FieldID) {
  let imageArray = null

  if (isValidObject($FieldID)) {
    if ($FieldID === 'Confidence') {
      imageArray = ConfidenceImageArray
    } else if ($FieldID === 'Severity') {
      imageArray = SeverityImageArray
    } else if ($FieldID === 'Risk') {
      imageArray = RiskImageArray;
    } else {
      // field id not found, default
      imageArray = EnumImageArray
    }
  } else {
    imageArray = EnumImageArray
  }

  const enumImage = imageArray.find(image => image.id === value)
  if (!isValidObject(enumImage)) {
    return null
  }
  return enumImage.image
}

export const CloseIcon = () => (
  <svg
    viewBox="0 0 512 512"
  >
    <path
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M368 368L144 144"
    ></path>
    <path
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M368 144L144 368"
    ></path>
  </svg>
);
export const WatchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="16"
    viewBox="0 0 24 16"
  >
    <g data-name="Group 42" transform="translate(-1 -4)">
      <g data-name="Group 43" transform="translate(1 4)">
        <path
          d="M13 5.333c4.708 0 8.885 4.787 10.344 6.667-1.459 1.88-5.636 6.667-10.344 6.667S4.115 13.88 2.656 12C4.115 10.12 8.292 5.333 13 5.333M13 4C6.372 4 1 12 1 12s5.372 8 12 8 12-8 12-8-5.372-8-12-8z"
          data-name="Path 22"
          transform="translate(-1 -4)"
        ></path>
      </g>
      <circle
        cx="3"
        cy="3"
        r="3"
        data-name="Ellipse 1"
        transform="translate(10 9)"
      ></circle>
    </g>
  </svg>
);