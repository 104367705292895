export const WarningStyle = {
  color: '#910B04',
  backgroundColor: '#FADEDE',
  display: 'flex',            
  alignItems: 'center'
};

export const InfoStyle = {
  color: '#083280',
  backgroundColor: '#DEE9FA',
  display: 'flex',          
  alignItems: 'center'
};

export const InfoIconStyle = {
  width: '21px',
  height: '100%'
}

export const WarningIconStyle = {
  width: '25px',
  height: '100%',
}