import React from "react";
import { connect } from "react-redux";

class ConnectedRoute extends React.Component {
	render() {
		if (!this.props.appState || !this.props.appState.databaseGUIDHash) {
			// FIXME: If disconnected and no user token, redirect to login?
			return null;
		}
	
		return (
			this.props.children
		);
	}
}

const mapStateToProps = state => ({
	appState: state.appState,
});
  

export default ConnectedRoute = connect(mapStateToProps)(ConnectedRoute);