import assignIn from 'lodash/merge'

import { multilineConstants } from '../_constants/multiline.constants';
import { projectConstants } from '../_constants/project.constants';
import { extractFromString } from '../_helpers/projectid_parser';
import { CLEAR_CACHE, REMOVE_FIELD } from '../_constants/general.constants';
import { processUpdateColumnMetaData } from "./fields.reducer";

function createMultiline(action) {
  const projectID = extractFromString("ProjectMeta_", action.collection);
  let newObject = {
    id: action.id,
    projectID: projectID
  };
  assignIn(newObject, action.fields);
  return newObject;
}

const multilinefields = (state = [], action) => {
  let projectID;
  if ('collection' in action) 
    projectID = extractFromString("ProjectMeta_", action.collection);
  switch (action.type) {
    case multilineConstants.ADD_MULTILINE:
      const existingIndex = state.findIndex(field => field.id === action.id && field.projectID === projectID);
      if (existingIndex >= 0) {
        let newState = [
          ...state.slice(0, existingIndex),
          ...state.slice(existingIndex + 1)
        ];

        return [
          ...newState,
          createMultiline(action)
        ];
      } else {
        return [
          ...state,
          createMultiline(action)
        ];
      }
    case projectConstants.UPDATE_PROJECTMETA:
      return processUpdateColumnMetaData(state, action, projectID, createMultiline);  
    case REMOVE_FIELD:
      const myindex = state.findIndex(field => field.id === action.id && field.projectID === projectID)
      if (myindex >= 0) {
        return [
          ...state.slice(0, myindex),
          ...state.slice(myindex + 1)
        ];
      } else {
        return state;
      }
    case projectConstants.REMOVE_PROJECT:
      const newState = state.filter(field => field.projectID !== projectID);
      return newState;
    case multilineConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state;  
    default:
      return state
  }
}

export default multilinefields