import assignIn from 'lodash/merge'

import { groupConstants } from '../_constants/group.constants';
import { CLEAR_CACHE } from '../_constants/general.constants';

function buildObject(group, id, fields) {
  let newObject = {
    ...group,
    id: id
  };
  assignIn(newObject, fields);
  return newObject;
}

const resources = (state = [], action) => {
  switch (action.type) {
    case groupConstants.ADD_GROUP:
      let myGroup = {}
      return [
        ...state,
        buildObject(myGroup, action.id, action.fields)
      ];
    case groupConstants.UPDATE_GROUP:
      return state.map(resource => {
        const myGroup = (resource.id === action.id)
          ? buildObject(resource, action.id, action.fields)
          : resource;
        return myGroup;
      })
    case groupConstants.REMOVE_GROUP:
      const myIndex = state.findIndex(resource => resource.id === action.id)
      if (myIndex >= 0) {
        return [
          ...state.slice(0, myIndex),
          ...state.slice(myIndex + 1)
        ];
      } else {
        return state;
      }
    case groupConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state;  
    default:
      return state
  }
}

export default resources