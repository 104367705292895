import React, { useRef, useEffect, useState } from "react";
import ChartComponent, { Chart, Line, Pie, Scatter, Bubble } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import stacked100 from "chartjs-plugin-stacked100";
import "chartjs-plugin-annotation";
import _ from "underscore";
import moment from "moment";
import assignIn from "lodash/merge";
import { Loader } from "semantic-ui-react";

import "./chart.scss";
import { formatYYYYMMDD } from "../../_helpers/date_time_format";
import ItemView from "./itemview";
import Gauge from "./gauge";

class ChartJS extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chartRef: null,
			customLegend: null,
			windowWidth: null,
		};

		this.updateLegend = this.updateLegend.bind(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	updateWindowDimensions() {
		this.setState({
			windowWidth: window.innerWidth,
		});
	}

	updateLegend() {
		if (!this.chartRef || !this.chartRef.chartInstance)
			return;

		this.setState({
			customLegend: this.chartRef.chartInstance.generateLegend(),
		});		
	}

	componentDidMount() {
		this.updateWindowDimensions();
		this.updateLegend();
		window.addEventListener("resize", this.updateWindowDimensions, false);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions, false);
	}

	componentDidUpdate(prevProps) {
		if (this.props === prevProps)
			return;

		this.updateLegend();
	}

    render() {
		const chartStyling = {display: "flex", flexFlow: "row nowrap", maxHeight: this.props.maxHeight, minHeight: this.props.maxHeight};

		if (ChartDataLabels) {} // Prevent warning
		if (stacked100) {} // Prevent warning

		if (this.props.chartResultSet.error || (this.props.chartResultSet.datasets && this.props.chartResultSet.datasets.length === 0))
			return <div className="chart" style={chartStyling}>{this.props.chartResultSet.error || "No data here"}</div>;
		else if (this.props.chartResultSet.type === "gauge" && this.props.chartResultSet.upperBound === this.props.chartResultSet.lowerBound)
			return <div className="chart" style={chartStyling}>Set bounds to control color</div>;

		const chartType = this.props.chartResultSet.type;
		const isDarkTheme = this.props.mainTheme === "dark";

		if (chartType === "itemview")
			return <ItemView data={this.props.chartResultSet} maxHeight={this.props.maxHeight}/>;
		else if (chartType === "gauge")
			return <Gauge data={this.props.chartResultSet} maxHeight={this.props.maxHeight}/>;

		if (!this.props.chartResultSet.datasets)
			return null;

		const fontColor = isDarkTheme ? "#ffffff" : "#262626";
		const lineColor = isDarkTheme ? "#45474d": "#ebebeb";
		const backgroundColor = isDarkTheme ? "rgb(45,46,50)" : "white";

		const burndownKey = `__IDEAL_BURNDOWN_HIDDEN_KEY_${new Date().getTime()}`;

		let options = {
			maintainAspectRatio: false,
			responsive: true,
			plugins: {},
			legendCallback: (chart) => {
				const numDatasets = this.props.chartResultSet.datasets.length;
				if (this.props.chartResultSet.legend === "notVisible" || numDatasets === 0)
					return null;

				if (chartType !== "pie") {
					let uniqueLabels = {};

					return (
						<div className="legend">
						{
							this.props.chartResultSet.datasets.map((dataset, index) => {
								if (dataset.label === burndownKey)
									return null;

								const displayValue = dataset.label.$date ? formatYYYYMMDD(new Date(dataset.label.$date)) : dataset.label;
								if (chartType === "scatter" && uniqueLabels[displayValue.trim()] !== undefined)
									return null;

								uniqueLabels[displayValue] = true;

								return (
									<div key={index}>
										<div className="entry">
											<div className="icon" style={{ backgroundColor: dataset.borderColor || dataset.backgroundColor }}></div>
											<div className="text" title={displayValue}>{displayValue}</div>
										</div>
									</div>
								);
							})
						}
						</div>
					);
				} else {
					const headerDataset = this.props.chartResultSet.datasets[numDatasets - 1];
					if (!headerDataset)
						return null;
	
					let childElements = {};
	
					if (numDatasets >= 2) {
						const childDataset = this.props.chartResultSet.datasets[numDatasets - 2];
						childDataset.label.forEach((childLabel, childIndex) => {
							const colorIndex = childDataset.firstDimensionLookup[childIndex];
							if (childElements[colorIndex] === undefined)
								childElements[colorIndex] = [];
							
							childElements[colorIndex].push({
								color: childDataset.backgroundColor[childIndex],
								label: childLabel,
							});
						});
					}
	
					return (
						<div className="legend">
						{
							headerDataset.label.map((label, index) => {
								const headerDisplayValue = label.$date ? formatYYYYMMDD(new Date(label.$date)) : label;

								return (
									<div style={{flex: 1}}>
										<div className="entry">
											<div className="icon" style={{ backgroundColor: headerDataset.backgroundColor[index] }}></div>
											<div className="text" style={{ fontWeight: "700" }} title={headerDisplayValue}>{headerDisplayValue}</div>
										</div>
										{
											childElements[index] === undefined ?
												null
											:
												childElements[index].map(childLabel => {
													const displayValue = childLabel.label.$date ? formatYYYYMMDD(new Date(childLabel.label.$date)) : childLabel.label;
													return (
														<div className="entry">
															<div className="icon" style={{ backgroundColor: childLabel.color }}></div>
															<div className="text" title={displayValue}>{displayValue}</div>
														</div>
													);
												})
										}
									</div>
								);
							})
						}
						</div>
					);
				}
			},
			legend: {
				display: false,
			},
			animation: {
				duration: 0,
			},
			responsiveAnimationDuration: 0,
		};

		function addScalesOption(scale, key, value) {
			if (!options.scales)
				options.scales = {};
			if (!options.scales[scale])
				options.scales[scale] = [{}];
			if (typeof value === "object")
				options.scales[scale][0][key] = _.extend(options.scales[scale][0][key] || {}, value);
			else
				options.scales[scale][0][key] = value;
		}

		if (chartType !== "pie") {
			addScalesOption("xAxes", "ticks", { suggestedMin: 0, fontColor: fontColor });
			addScalesOption("yAxes", "ticks", { suggestedMin: 0, fontColor: fontColor });
			
			addScalesOption("xAxes", "gridLines", {
				color: lineColor,
				lineWidth: 0,
				zeroLineColor: lineColor,
			});

			addScalesOption("yAxes", "gridLines", {
				color: lineColor,
				zeroLineColor: lineColor,
			});

			if (chartType === "scatter") {
				if (this.props.chartResultSet.axisLabels) {		
					addScalesOption("xAxes", "scaleLabel", { 
						display: true,
						labelString: this.props.chartResultSet.axisLabels[0] || "",			
					});
					addScalesOption("yAxes", "scaleLabel", { 
						display: true,
						labelString: this.props.chartResultSet.axisLabels[1] || "",			
					});
				}
			} else {
				if (chartType === "bar") {
					if (this.props.chartResultSet.grouping === "stack" || 
						this.props.chartResultSet.grouping === "stackPercentage") {
						addScalesOption("xAxes", "stacked", true);
						addScalesOption("yAxes", "stacked", true);
					}
				} else if (chartType === "area" && this.props.chartResultSet.grouping === "stack") {
					addScalesOption("yAxes", "stacked", true);
				}

				if (this.props.chartResultSet.grouping === "stackPercentage") {
					options.plugins.stacked100 = {
						enable: true,
						replaceTooltipLabel: false
					};
				} else {
					addScalesOption(this.props.chartResultSet.orientation === "horizontal" ? "xAxes" : "yAxes", "ticks", { 
						fontColor: fontColor,
						min: this.props.chartResultSet.yMinValue,
						max: this.props.chartResultSet.yMaxValue,
						suggestedMin: 0,
					});
				}
			}
		} else if (chartType === "pie") {
			options.tooltips = {
				callbacks: {
					label: function(item, data) {
						const label = data.datasets[item.datasetIndex].label[item.index];

						return `${label.$date ? formatYYYYMMDD(new Date(label.$date)) : label}: ${data.datasets[item.datasetIndex].data[item.index]}`;
					},
				},
			};
		}

		if (chartType === "line" || chartType === "area") {
			addScalesOption("xAxes", "type", "time");
			addScalesOption("xAxes", "time", {
				unit: "day",
			});
			addScalesOption("xAxes", "ticks", { autoSkip: true, autoSkipPadding: 8, maxRotation: 0 });
		}

		options.tooltips = {
			displayColors: chartType === "bar",
			callbacks: {
				title: function(item, data) {
					return null;
				},
				label: function(item, data) {
					if (data.datasets[item.datasetIndex].label === burndownKey)
						return null;

					function roundValue(value) {
						if (value === undefined)
							return value;
						if (typeof value !== "number")
							value = parseFloat(value);
						if (`${value}` !== value.toFixed())
							return value.toFixed(1);
						return value;
					}

					if (chartType === "bar" && item.value !== "NaN") {
						let rows = [];
						
						if (data.dimensions.length > 1) {
							rows.push(`${data.measures[0]}: ${roundValue(item.value)}`);
							rows.push(`${data.dimensions[0]}: ${item.label}`);
							rows.push(`${data.dimensions[1]}: ${data.datasets[item.datasetIndex].label}`);
						} else {
							rows.push(`${data.measures[item.datasetIndex]}: ${roundValue(item.value)}`);
							rows.push(`${data.dimensions[0]}: ${item.label}`);
						}

						return rows;
					} else if (chartType === "pie") {
						let rows = [
							`${data.measures[0]}: ${roundValue(data.datasets[item.datasetIndex].data[item.index])}`
						];

						const nDimensions = data.datasets.length;

						let dimensionLookupParts = data.datasets[item.datasetIndex].previousDimensionLookup[item.index].split(",");	
						
						let dimRows = [];

						for (let iDimension = item.datasetIndex; iDimension < nDimensions; ++iDimension) {
							let dimensionLabel = "";
							if (iDimension === item.datasetIndex)
								dimensionLabel = data.datasets[iDimension].label[item.index];
							else
								dimensionLabel = data.datasets[iDimension].label[dimensionLookupParts.pop()];

							if (dimensionLabel.$date)
								dimensionLabel = formatYYYYMMDD(new Date(dimensionLabel.$date));
								
							dimRows.push(`${data.dimensions[nDimensions - 1 - iDimension]}: ${dimensionLabel}`);
						}

						return rows.concat(dimRows.reverse());					
					} else if (chartType === "line" || chartType === "area") {
						let rows = [
							`${data.dimensions[0]}: ${moment(new Date(item.label)).format("MMM D")}`,
						];

						if (data.dimensions.length === 2)
							rows.push(`${data.dimensions[1]}: ${data.datasets[item.datasetIndex].label}`)
						else
							rows.push(`${data.datasets[item.datasetIndex].label}`)

						rows.push(`${data.measures[data.datasets[item.datasetIndex].measureIndex]}: ${roundValue(item.value)}`);

						return rows;
					} else if (chartType === "scatter") {
						let rows = [];

						for (let iMeasure = 0; iMeasure < data.measures.length; ++iMeasure) {
							const measureValueKey = iMeasure === 0 ? "x" : (iMeasure === 1 ? "y" : "r");
							rows.push(`${data.measures[iMeasure]}: ${roundValue(data.datasets[item.datasetIndex].data[0][measureValueKey])}`);
						}

						for (let iDimension = 0; iDimension < data.dimensions.length; ++iDimension) {
							let label = data.datasets[item.datasetIndex].labels[iDimension];
							if (label.$date)
								label = formatYYYYMMDD(new Date(label.$date));

							rows.push(`${data.dimensions[iDimension]}: ${label}`);
						}

						return rows;
					}
				},
			},
		};

		if (this.props.chartResultSet.forecastingIndex > 0) {
			options.annotation = {
				drawTime: "afterDatasetsDraw",
				annotations: [{
					type: "line",
					mode: "vertical",
					scaleID: "x-axis-0",
					borderColor: "rgb(135, 235, 135)",
					value: moment().startOf("day").toDate().getTime(),
				}],
			};
		}

		options.plugins.datalabels = {
			clamp: true,
			display: function(context) {
				if (chartType === "pie")
					return "auto";
				else if (chartType === "area" || chartType === "line" || chartType === "scatter")
					return false;
				return true;
			},
			color: function(context) {
				if (context.chart.data.type === "bar") {
					if (context.dataset.data[context.dataIndex] === 0)
						return fontColor;
					return context.dataset.labelColor;
				} else if (context.chart.data.type === "pie") {
					const labelColor = context.dataset.labelColor[context.dataIndex];
					return labelColor;
				}

				return fontColor;
			},
			formatter: function(value, context) {
				if (value === 0 && context.chart.data.type !== "bar")
					return "";

				function calculatePercentage() {				
					const firstDimension = context.dataset.previousDimensionLookup ? context.dataset.previousDimensionLookup[context.dataIndex] : undefined;				
					let total = 0;
					let iIndex = 0;
					for (let data of context.dataset.data) {
						if (firstDimension === undefined || context.dataset.previousDimensionLookup[iIndex] === firstDimension)
							total += data;
						++iIndex;
					}

					if (total === 0)
						return 0;
					return ((value / total) * 100).toFixed(0);
				}

				const dataLabels = context.chart.data.dataLabels;

				if (context.chart.data.originalData && dataLabels !== "notVisible") {
					if (!context.chart.data.originalData[context.datasetIndex][context.dataIndex])
						return "";

					let totalValue = 0;
					for (let iDatasetIndex = 0; iDatasetIndex < context.chart.data.originalData.length; ++iDatasetIndex)
						totalValue += context.chart.data.originalData[iDatasetIndex][context.dataIndex] || 0;

					return `${((context.chart.data.originalData[context.datasetIndex][context.dataIndex] / totalValue) * 100).toFixed(0)}%`;
				} else {
					if (dataLabels === "name")
						return `${context.chart.data.datasets[context.datasetIndex].label[context.dataIndex]}`;
					else if (dataLabels === "value")
						return `${context.chart.data.datasets[context.datasetIndex].data[context.dataIndex]}`;
					else if (dataLabels === "percentage")
						return `${calculatePercentage()}%`;
					else if (dataLabels === "nameAndValue")
						return `${context.chart.data.datasets[context.datasetIndex].label[context.dataIndex]} (${context.chart.data.datasets[context.datasetIndex].data[context.dataIndex]})`;
					else if (dataLabels === "nameAndPercentage")
						return `${context.chart.data.datasets[context.datasetIndex].label[context.dataIndex]} (${calculatePercentage()}%)`;
				}

				return "";
			},
		};
		
		const chartResultSet = (canvas) => {
			let usedLabels = {};

			function makeUnique(label) {
				// FIXME: chart.js uniques on the display string of dataset label
				// until this is fixed upstream we need to make the display string unique
				if (label.$date)
					label = formatYYYYMMDD(new Date(label.$date));
	
				if (usedLabels[label] !== undefined)
					usedLabels[label]++;
				else
					usedLabels[label] = 1;

				const numUnique = usedLabels[label] - 1;

				for (let iUnique = 0; iUnique < numUnique; ++iUnique)
					label += " ";	
				
				return label;
			}

			let resultSet = assignIn({}, this.props.chartResultSet);

			if (chartType === "area" && resultSet.grouping === "stack") {
				let timeSeries = {};
				for (let dataset of resultSet.datasets) {
					for (let data of dataset.data)
						timeSeries[data.x] = true;
				}
	
				let sortedTimeSeries = Object.keys(timeSeries).sort();
	
				for (let dataset of resultSet.datasets) {
					let interpolatedSeries = [];

					let iData = 0;
					for (let iTimeSeries = 0; iTimeSeries < sortedTimeSeries.length; ++iTimeSeries) {
						const timeSeries = parseInt(sortedTimeSeries[iTimeSeries]);
						if (iData >= dataset.data.length || dataset.data[iData].x > timeSeries) {
							interpolatedSeries.push({ x: timeSeries, y: null });
						} else {
							interpolatedSeries.push(dataset.data[iData]);
							++iData;
						}
					}
	
					dataset.data = interpolatedSeries;
				}
			}

			for (let dataset of resultSet.datasets) {
				dataset.label = makeUnique(dataset.label);

				if (chartType !== "pie") {
					dataset.lineTension = 0;
				} else {
					dataset.borderColor = backgroundColor;
					dataset.borderWidth = 1;
				}
			}

			if (chartType === "pie" && resultSet.datasets.length > 1)
				resultSet.datasets[resultSet.datasets.length - 1].weight = 5;
			else if (chartType === "area")
				resultSet.datasets.reverse();

			if (resultSet.labels) {
				let convertedLabels = [];

				for (let label of resultSet.labels) {
					if (label.$date) {
						const convertedDate = formatYYYYMMDD(new Date(label.$date));
						convertedLabels.push(convertedDate);
					} else {
						convertedLabels.push(label);
					}
				}
				
				resultSet.labels = convertedLabels;
			}

			if (this.props.chartResultSet.idealBurndown && this.props.chartResultSet.idealBurndown.enabled && this.props.chartResultSet.datasets.length >= 1) {
				let idealBurndownDataset = {
					type: "line",
					label: burndownKey,
					data: [],
					lineTension: 0,
					fill: "disabled",
					spanGaps: true,
					pointRadius: 0,
					borderColor: "#cccccc",
					borderWidth: 1,
					pointHitRadius: 0,
				};

				let currentDateIndex = 0;
				const currentDate = moment().startOf("day").toDate().getTime();

				let uniqueDates = {};
				
				for (const dataset of this.props.chartResultSet.datasets) {
					for (const dataPoint of dataset.data)
						uniqueDates[dataPoint.x] = true;
				}

				const sortedDates = Object.keys(uniqueDates).sort();

				for (const date of sortedDates) {
					idealBurndownDataset.data.push({
						x: parseInt(date),
						y: null,
					});
					
					if (parseInt(date) === currentDate)
						currentDateIndex = idealBurndownDataset.data.length - 1;
				}

				let startValue = this.props.chartResultSet.idealBurndown.startValue || 0;
				if (!startValue) {
					for (let dataset of resultSet.datasets) {
						const dataSetValue = dataset.data[currentDateIndex].y;
						if (dataSetValue > startValue)
							startValue = dataSetValue;
					}
				}

				if (this.props.chartResultSet.idealBurndown.startUnit > 0 && this.props.chartResultSet.idealBurndown.startUnit < idealBurndownDataset.data.length)
					idealBurndownDataset.data[this.props.chartResultSet.idealBurndown.startUnit - 1].y = startValue;
				else
					idealBurndownDataset.data[currentDateIndex].y = startValue;

				idealBurndownDataset.data[idealBurndownDataset.data.length - 1].y = 0;
				if (!(idealBurndownDataset.data[0].y === 0 && idealBurndownDataset.data[idealBurndownDataset.data.length - 1].y === 0))
					resultSet.datasets.unshift(idealBurndownDataset);
			}

			return resultSet;
		};

		let chartComponent = null;
		
		if (this.props.chartResultSet.type === "bar") {
			chartComponent = <ChartComponent type={this.props.chartResultSet.orientation === "horizontal" ? "horizontalBar" : "bar"} data={chartResultSet} options={options} ref={(element) => this.chartRef = element} redraw/>;
		} else if (this.props.chartResultSet.type === "line" || this.props.chartResultSet.type === "area") {
			chartComponent = <Line data={chartResultSet} options={options} ref={(element) => this.chartRef = element} redraw/>;
		} else if (this.props.chartResultSet.type === "scatter") {
			if (this.props.chartResultSet.axisLabels.length === 3) {
				chartComponent = <Bubble data={chartResultSet} options={options} ref={(element) => this.chartRef = element} plugins={[{
					beforeDatasetsDraw: function(chart) {
						Chart.helpers.canvas.clipArea(chart.ctx, chart.chartArea);
					},
					afterDatasetsDraw: function(chart) {
						Chart.helpers.canvas.unclipArea(chart.ctx);
					},
				}]}/>;
			} else {
				chartComponent = <Scatter data={chartResultSet} options={options} ref={(element) => this.chartRef = element} redraw/>;
			}
		} else if (this.props.chartResultSet.type === "pie") {
			chartComponent = <Pie data={chartResultSet} options={options} ref={(element) => this.chartRef = element} redraw/>;
		}

		return (
			<div className="chart" style={chartStyling}>
				<div className="chart-wrapper" style={{flex: 3, maxWidth: this.props.maxWidth}}>
					{chartComponent}
				</div>
				{this.state.customLegend ? 
						<div style={{ display: "flex", flexFlow: "column nowrap", justifyContent: "center", maxWidth: "20%" }}>
							{this.state.customLegend}
						</div>
					:
						null
				}					
			</div>
		);
    }
}

const ResultSetChart = (props, state) => {
	const [sizeInfo, setSizeInfo] = useState({
		width: 0,
		height: 0,
	});
	const ref = useRef(null);

	function handleResize() {
		if (!ref.current || !ref.current.getBoundingClientRect().width)
			return;
		setSizeInfo({
			width: ref.current.getBoundingClientRect().width - 32,
			height: ref.current.getBoundingClientRect().width / 2,
		});
	}

	useEffect(() => {
		handleResize();
	}, [ref.current]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className="charttile" ref={ref}>
			<div className="name">{props.name || "(Untitled chart)"}</div>
				{props.chartResultSet ?
						<ChartJS chartResultSet={props.chartResultSet} theme={props.theme} mainTheme={props.mainTheme} maxHeight={sizeInfo.height} maxWidth={sizeInfo.width}/>
					:
						<div style={{ 
							alignItems: "center",
							display: "flex",
							minHeight: sizeInfo.height,
							maxHeight: sizeInfo.height,
							justifyContent: "center"
						}}>
							<Loader active inline/>
						</div>
				}
		</div>
	);
}

export default ResultSetChart;