import React from 'react';
import { connect } from 'react-redux';

import { filterService } from '../_services/filter.service';
import { history } from '../_helpers/history';

import {   
  ImgPagingFirstDisabled, 
  ImgPagingFirstEnabled, 
  ImgPagingLastDisabled, 
  ImgPagingLastEnabled, 
  ImgPagingNextDisabled, 
  ImgPagingNextEnabled, 
  ImgPagingPreviousDisabled, 
  ImgPagingPreviousEnabled
} from '../_images/images'

class ScrollingControl extends React.Component {

  state = { 
    pagingData: this.getPositionDataOfItem(), 
    currentID: this.props.task.$ID
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.todos.length !== this.props.todos.length) 
      || (prevState.currentID !== this.props.task.$ID))  {
      this.setState({
        currentID: this.props.task.$ID,
        pagingData : this.getPositionDataOfItem(),
      });
    }
  }

  getPositionDataOfItem() {
    let posIndex = -1;
    for (let index = 0; index < this.props.todos.length; ++index) {
      if (this.props.task.$ID === this.props.todos[index].$ID) {
        posIndex = index;
        break;
      }
    }
    if (posIndex === -1) {
      // problem, disable scrolling
      return {
        isFirst: true,
        isLast: true,
        firstID: '0',
        lastID: '0',
        prevID: '0',
        nextID: '0',
        pos: 0,
        count: 0
      };
    }
    const isFirst = (posIndex === 0);
    const isLast = this.props.todos.length ? (posIndex ===  this.props.todos.length - 1): true;
    const firstID = this.props.todos.length ? this.props.todos[0].$ID: '0';
    const lastID = this.props.todos.length ? this.props.todos[this.props.todos.length - 1].$ID: '0';
    const prevID = posIndex - 1 >= 0 ? this.props.todos[posIndex - 1].$ID: '0';
    const nextID = posIndex + 1 < this.props.todos.length ? this.props.todos[posIndex + 1].$ID: '0';
    return {
      isFirst: isFirst,
      isLast: isLast,
      firstID: firstID,
      lastID: lastID,
      prevID: prevID,
      nextID: nextID,
      pos: posIndex + 1,
      count: this.props.todos.length
    };
  }

  onButtonClick(event) {
    let todoID = '0';
    if (event.target.id === 'firstImg') {
      todoID = this.state.pagingData.firstID;
    } else if (event.target.id === 'prevImg') {
      todoID = this.state.pagingData.prevID;
    } else if (event.target.id === 'nextImg') {
      todoID = this.state.pagingData.nextID;
    } else if (event.target.id === 'lastImg') {
      todoID = this.state.pagingData.lastID;
    } else {
      return;
    }
    if (todoID === '0') 
      return;
    let path = `/task/${this.props.databaseGUIDHash}/${todoID}`;
    this.setState({currentID: todoID})
    history.push(path);
  }

  firstImage() {
    if (this.state.pagingData.isFirst) {
      return (<img alt='first disabled' style={PagingIconDisabledStyle} src={ImgPagingFirstDisabled} />)
    } else {
      return (<img alt='first enabled' id='firstImg' style={PagingIconEnabledStyle} src={ImgPagingFirstEnabled} onClick={ (e) => this.onButtonClick(e) } />)
    }
  }

  prevImage() {
    if (this.state.pagingData.isFirst) {
      return (<img alt='previous disabled' style={PagingIconDisabledStyle} src={ImgPagingPreviousDisabled} />)
    } else {
      return (<img alt='previous enabled' id='prevImg' style={PagingIconEnabledStyle} src={ImgPagingPreviousEnabled} onClick={ (e) => this.onButtonClick(e) } />)
    }
  }

  nextImage() {
    if (this.state.pagingData.isLast) {
      return (<img alt='next disabled' style={PagingIconDisabledStyle} src={ImgPagingNextDisabled} />)
    } else {
      return (<img alt='next enabled' id='nextImg' style={PagingIconEnabledStyle} src={ImgPagingNextEnabled} onClick={ (e) => this.onButtonClick(e) } />)
    }
  }

  lastImage() {
    if (this.state.pagingData.isLast) {
      return (<img alt='last disabled' style={PagingIconDisabledStyle} src={ImgPagingLastDisabled} />)
    } else {
      return (<img alt='last enabled' id='lastImg' style={PagingIconEnabledStyle} src={ImgPagingLastEnabled} onClick={ (e) => this.onButtonClick(e) } />)
    }
  }

  positionText() {
    return ( <span style={TextStyle}>&nbsp;&nbsp;{this.state.pagingData.pos.toString()}&nbsp;of&nbsp;{this.state.pagingData.count.toString()}&nbsp;&nbsp;</span> )
  }

  render() {

    return (
      <div style={ContainerStyle} >
        {this.firstImage()}
        {this.prevImage()}
        {this.positionText()}
        {this.nextImage()}
        {this.lastImage()}
      </div>
    );
  }
}

const ContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  float: 'right'
}

const PagingIconDisabledStyle = {
  width: '15px',
  height: '15px'
}

const PagingIconEnabledStyle = {
  ...PagingIconDisabledStyle,
  cursor: 'pointer'
}

const TextStyle = {
  fontSize: '13px'
}

const mapStateToProps = state => ({
  databaseGUIDHash: state.appState.databaseGUIDHash,
  todos: filterService.getFilteredTodos(state.todos, state.filteroptions),
})

export default ScrollingControl = connect(mapStateToProps)(ScrollingControl);
