import React from 'react';
import { Image } from 'semantic-ui-react';

import ClickEditBaseField from './ClickEditBaseField'
import FieldStyle from './FieldStyle'
import { ImgEditComment } from '../_images/images'
import { isValidObject } from '../_helpers/validation_functions'

export default class HyperlinkField extends ClickEditBaseField {

  state = {
    ...this.state,
  }
  getHREFValue() {
    const hyperlinkString = this.getValue();
    if (!isValidObject(hyperlinkString) || typeof hyperlinkString !== "string") {
      return ''
    }
    const pos = hyperlinkString.indexOf('|')
    let href = hyperlinkString;
    if (pos > -1) {
      href = hyperlinkString.substr(0, pos);
    } 
    if (href.indexOf('//') === -1) {
      href = '//' + href;
    }
    return href;
  }

  getLinkName() {
    const hyperlinkString = this.getValue();
    if (!isValidObject(hyperlinkString) || typeof hyperlinkString !== "string") {
      return ''
    }
    const pos = hyperlinkString.indexOf('|')
    let name = hyperlinkString;
    if (pos > -1) {
      name = hyperlinkString.substr(pos + 1);
    } 
    return name;
  }

  getViewMode() {
    return (
      <div className="input">
        <span style={FieldStyle.ReadOnlyFieldStyle}>
          <a href={this.getHREFValue()} target='_blank noopener noreferrer' >
          {this.getLinkName()}
          </a>
          {this.readOnlyFlag()
            ?null
            :<Image style={EditHyperlinkStyle} avatar src={ImgEditComment} onClick={(event, value) => {this.toggleEditMode(event)}} />
          }
        </span>
      </div>
    )
  }
}

const EditHyperlinkStyle = {
  width:'14px', 
  height:'14px',
  cursor: 'pointer',
  borderRadius: '0px',
}