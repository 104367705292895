import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ConnectedRoute from '../containers/connection';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? 
                (
                    <ConnectedRoute>
                        <Component {...props} {...rest}/>
                    </ConnectedRoute>
                )
            : 
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)