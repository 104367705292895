import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'

import '../semantic/dist/semantic.min.css'
import './MyWork.scss'

import { dueDateConstants, getDueDateGroupName } from '../_constants/todo.constants'
import { filterService } from '../_services/filter.service';
import FilterBar from './filterbar';
import TaskList from '../tasks/tasklist'
import { determineSortDate } from '../_reducers/todos.reducer'

function dueDateGrouping(todo) {
  let today12am = new Date();
  today12am.setUTCHours(0);
  today12am.setUTCMinutes(0);
  today12am.setUTCSeconds(0);
  today12am.setUTCMilliseconds(0);
  const msToday = today12am.getTime();  

  const sortDate = determineSortDate(todo);

  if (sortDate < msToday) return dueDateConstants.PAST_DUE;
  else if (sortDate === msToday) return dueDateConstants.TODAY;
  else {
    const msDayLength = (60*60*24) * 1000;
    let thisWeekDelta = (6 - today12am.getDay()) * msDayLength;
    let nextWeekDelta = thisWeekDelta + 7 * msDayLength;
    if (sortDate < thisWeekDelta + msToday) {
      return dueDateConstants.THIS_WEEK;
    } else if (sortDate < nextWeekDelta + msToday) {
      return dueDateConstants.NEXT_WEEK;
    } else {
      return dueDateConstants.LATER;
    }
  }
}

class MyWork extends React.Component {

  constructor(props) {
    super(props);

    this.toggleVisibility = this.toggleVisibility.bind(this)
    this.tableRowClickFunc = this.tableRowClickFunc.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  state = {
    visible: false,
    height: 0,
    filterOptions: null
  }

  toggleVisibility() {
     this.setState({ visible: !this.state.visible });
  }

  tableRowClickFunc(id) {
    this.toggleVisibility();
  }

  componentDidMount() {
    document.title = "Hansoft";

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions, false);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions() {
    let height = window.innerHeight;
    this.setState({ height: height });
  }

  render() {
    let groups = [dueDateConstants.PAST_DUE, dueDateConstants.TODAY, dueDateConstants.THIS_WEEK, dueDateConstants.NEXT_WEEK, dueDateConstants.LATER];

    const loadingState = this.props.appState.loadingMode || this.props.appState.filterMode;
    const showCoverImages = this.props.showCoverImages;

    let uniqueFields = {};

    const fieldIds = [
      "Description",
      "DueDate",
      "Status",
      "WorkRemaining",
      "Priority",
    ]; 

    this.props.singlelinefields.forEach(field => {
      if (fieldIds.indexOf(field.id) >= 0)
        uniqueFields[field.id] = field;
    });

    let enabledFields = [];

    const ignoredFields = [
      "LockedType",
      "Type",
      "AttachedDocuments",
      "TimeZones",
      "CommentsOptions",
      "UserStoryFlag",
      "EpicFlag",
      "LinkedTo",
      "Archived",
      "LinkedToSprint",
    ];

    // Special "computed" fields
    if (fieldIds.indexOf("DueDate") !== -1) {
      enabledFields.push({
        id: "DueDate",
        name: "Due Date",
        readOnly: true,
      });
    }
    
    if (fieldIds.indexOf("Priority") !== -1) {
      enabledFields.push({
        id: "Priority",
        name: "Priority",
      });
    }

    for (let id in uniqueFields) {
      const field = uniqueFields[id];
      if (ignoredFields.indexOf(field.id) !== -1 || fieldIds.indexOf(field.id) === -1)
        continue;

      let values = {};
      if (field.Enum) {
        for (const key in field.Enum) {
          const fieldValue = field.Enum[key];
          values[fieldValue[0]] = {
            icon: field.id.indexOf("CC_") === 0 ? fieldValue[2] : fieldValue[0],
            text: fieldValue[1],
            sortOrder: key,
          };
        }
      }

      enabledFields.push({
        id: id,
        type: field.Type,
        name: field.DisplayName,
        values: Object.keys(values).length >= 1 ? values : null,
        readOnly: field.ReadOnly,
      });
    }

    enabledFields = enabledFields.sort(function(fieldA, fieldB) {
      const fieldAIndex = fieldIds.indexOf(fieldA.id);
      const fieldBIndex = fieldIds.indexOf(fieldB.id);

      if (fieldAIndex < fieldBIndex)
        return -1;
      else if (fieldAIndex > fieldBIndex)
        return 1;
      return 0;        
    });

    let taskGroups = groups.map(group => {
      return {
        name: getDueDateGroupName(group),
        tasks: this.props.todos.filter(todo => {
          return dueDateGrouping(todo) === group;
        }),
      };
    });

    return (
      <div className="todo" ref='MyWorkList' >
        <Dimmer active={loadingState} >
          <Loader>Loading</Loader>
        </Dimmer>
        {this.props.collapseState.iscollapsed ?
            null
          :
            <FilterBar/>
        }        
        <TaskList
          databaseGUIDHash={this.props.appState.databaseGUIDHash}
          showCoverImages={showCoverImages}
          enabledFields={enabledFields}
          taskGroups={taskGroups}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentuser: state.currentuser,
  collapseState: state.collapseState,
  appState: state.appState,
  filteroptions: state.filteroptions,
  todos: filterService.getFilteredTodos(state.todos, state.filteroptions),
  projects: state.projects,
  singlelinefields: state.singlelinefields,
  sprints: state.sprints
})

export default MyWork = connect(mapStateToProps)(MyWork);