import React from 'react';

import BaseField from './BaseField'
import {setField} from '../DDPJS/DDPJS';
import { POINTS_MAX, POINTS_MIN, ESTIMATEDDAYS_MAX } from '../_constants/general.constants';
import ItemDetailsField from './components/ItemDetailsField';
import Input from './components/Input';
import {formatWork} from '../_helpers/hours_field_functions';
import { isValidObject, isValidNumber, isValidDecimal } from '../_helpers/validation_functions'

export default class NumberField extends BaseField {
  state = {
    originalValue: this.getNumberFieldValue(this.getValue()),
    value: this.getNumberFieldValue(this.getValue()),
    invalidHoursPerDayMessageShown: false,
    editMode: false
  }

  roundValue(value) {
    return Math.round(value * 100) / 100;
  }

  getNumberFieldValue(input) {
    if (input === null || input === "")
      return "";
    if (typeof input !== "number")
      return input;  
    return this.roundValue(input);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevState.originalValue !== this.getNumberFieldValue(this.getValue())) 
      && !(isNaN(prevState.originalValue) && isNaN(this.getNumberFieldValue(this.getValue())))) {
        this.setState({originalValue: this.getNumberFieldValue(this.getValue()), value: this.getNumberFieldValue(this.getValue())});
    }
  }

  validator(value) {
    if (this.props.$FieldID === 'ComplexityPoints') {
      if (typeof value !== 'number' && isNaN(parseInt(value, 10))) {
        value = this.state.value
      } else if (parseInt(value, 10) > POINTS_MAX) {
        value = POINTS_MAX
      } else if (parseInt(value, 10) < POINTS_MIN) {
        value = POINTS_MIN
      }
    }

    return value;
  }

  saveChanges = (value) => {
    const originalValue = this.getNumberFieldValue(this.state.originalValue);

    if (this.readOnlyFlag()) {
      return originalValue;
    }

    this.setState({editMode: false});

    if (typeof value === "undefined") {
      return originalValue;
    }

    let currentValue = value;
    if (currentValue === "")
      currentValue = null;      

    if (currentValue !== null) {
      currentValue = this.validator(currentValue);

      if (this.getFieldDefinition().Type === "Integer") {
        if (!isValidNumber(currentValue)) {
          this.setState({value: originalValue});
          return originalValue;
        }
      } else if (this.getFieldDefinition().Type === "Float" || this.getFieldDefinition().Type === "Hours") {
        if (!isValidDecimal(currentValue)) {
          this.setState({value: originalValue});
          return originalValue;
        }
      }
    }

    if ((currentValue !== this.state.originalValue) && (value !== this.state.originalValue)) {
      this.setState({originalValue: currentValue});

      let myValue = currentValue;
      if (myValue !== null) {
        if (this.getFieldDefinition().Type === "Integer") {
          myValue = parseInt(currentValue, 10);
        } else if (this.getFieldDefinition().Type === "Float" || this.getFieldDefinition().Type === "Hours") {
          myValue = parseFloat(currentValue);
        }
      }
          
      setField(this.getFieldDefinition().id, this.getItemID(), myValue);
    }

    if ("handler" in this.props && typeof this.props.handler === "function")
      this.props.handler();

    return currentValue;
  }

  getDisplayName() {
    return this.getFieldDefinition().DisplayName;
  }

  getDisplayValue(isDecimal, value) {
    if (isDecimal && isValidObject(value) && value !== "" && !isNaN(value) && (value % 1 === 0)) {
      const myValue = parseFloat(value);
      return myValue.toFixed(1);
    }
    return value;
  }

  render() {
    if (!isValidObject(this.getFieldDefinition())) {
      return (null);
    }

    const isListView = ('isListView' in this.props)?this.props.isListView:false;

    const isDecimal = this.getFieldDefinition().Type === "Hours" || this.getFieldDefinition().Type === "Float";

    let control = this.props.isListView && !this.state.editMode ?
      <div className="numberfield" onClick={() => this.setState({editMode: true})}>{formatWork(this.props.task.getProperty('WorkRemaining')) || "(Not set)"}</div>
    : <Input selectOnFocus={isListView} number={true} decimal={isDecimal} value={this.getDisplayValue(isDecimal, this.state.value)} readOnly={this.readOnlyFlag()} 
        onChange={(newValue) => {
          if (newValue === null)
            newValue = this.getNumberFieldValue(newValue);
          if (isNaN(newValue) || (newValue < 0 && (this.props.$FieldID === "WorkRemaining" || this.props.$FieldID === "EstimatedIdealDays"))) {
            newValue = this.getNumberFieldValue(this.state.value); // Reset to original
            return newValue;
          } else if (newValue > ESTIMATEDDAYS_MAX && this.props.$FieldID === "EstimatedIdealDays") {
            newValue = ESTIMATEDDAYS_MAX;
          }

          newValue = this.getDisplayValue(isDecimal, this.saveChanges(newValue));
          return newValue;
      }}/>
    ;

    if (isListView) {
      return control;
    } else {
      return (
        <ItemDetailsField
          fieldName={this.getFieldDefinition().DisplayName}
          isRequiredField={this.isWorkflowRequiredField()}
          children={control}
        />
      );
    }
  }
}