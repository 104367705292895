export function requestAccess(authToken) {
	if (!authToken)
		return;

	document.cookie = `hsauth=${authToken}`;

	let xhr = new XMLHttpRequest();
	xhr.open("GET", `${window.location.origin}/requestaccess`, true);
	xhr.withCredentials = true;
	xhr.send(null);
}

export function mapFileIDToInfo(task, fileId) {
	if (!task || !task.fields || !task.fields.AttachedDocuments || !task.fields.AttachedDocuments.AttachedDocuments)
		return null;

	const attachments = task.fields.AttachedDocuments.AttachedDocuments.filter(attachment => attachment.FileID === fileId);
	if (!attachments || attachments.length === 0)
		return null;

	return {
		width: attachments[0].ImageWidth,
		url: `${window.location.origin}/versioncontrol/${attachments[0].FilePath}?version=${attachments[0].Version}`,
	};
}

export function mapMainImageToInfo(task) {
	if (!task || !task.fields || !task.fields.AttachedDocuments || !task.fields.AttachedDocuments.MainImage)
		return null;

	const attachments = task.fields.AttachedDocuments.AttachedDocuments.filter(attachment => attachment.FilePath === task.fields.AttachedDocuments.MainImage);
	
	let versionQuery = "";
	if (attachments && attachments.length >= 1)
		versionQuery = `?version=${attachments[0].Version}`;
		
	return {
		url: `${window.location.origin}/versioncontrol/${task.fields.AttachedDocuments.MainImage}${versionQuery}`,
	};
}