import { isValidObject } from '../_helpers/validation_functions'

export function setEJSONDateValue(object, field, value) {
  object[field].$date = value;
}

export function getEJSONDateValue(object, field) {
  if (field in object && object[field] !== null)
    return object[field].$date;
  else
    return 0;
}

export function createEJSONDateObject(value) {
  return {$date: value};
}

export function isValidEJSONDateObject(object) {
  if (isValidObject(object) && typeof object === "object" && '$date' in object) {
    return true;
  } else {
    return false;
  }
}