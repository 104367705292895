const TOKEN_NAME = 'hansoftrefresh';

function createRefreshToken() {
  const subdata = {
    product: 'hansoft'
  };

  localStorage.setItem(TOKEN_NAME, JSON.stringify(subdata));
}

function deleteRefreshToken() {
  localStorage.removeItem(TOKEN_NAME);
}

function doesRefreshTokenExist() {
  const datatoken = localStorage.getItem(TOKEN_NAME);
  if (datatoken) return true;
  return false;
}

export {
  createRefreshToken, 
  deleteRefreshToken, 
  doesRefreshTokenExist
} 