import { userConstants } from '../_constants/user.constants';

export const userActions = {
    login,
    logout
};

function login(username) {
    return dispatch => {
        dispatch(request({ username }));
        dispatch(storeusername(username));

        let user = {username: username, token: 'hansoft-web'};
        localStorage.setItem('user', JSON.stringify(user));

        dispatch(success(user));
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function storeusername(username) { return {type: userConstants.STORE_USERNAME, username: username }}
}

function logout() {
    localStorage.removeItem('user');
    return { type: userConstants.LOGOUT };
}

