import React, { useEffect, useState } from "react";

import Input from "../../Fields/components/Input";
import { setField } from "../../DDPJS/DDPJS";

function TextField(enabledField, task) {
	function getValueFromTask() {
		let value = task.fields[enabledField.id];
		if (enabledField.id === "DatabaseID")
			value = task.$ID;

		if (typeof value === "object")
			value = "";	

		return value;
	}

	let [isEditing, setIsEditing] = useState(false);
	let [value, setValue] = useState(getValueFromTask());

	useEffect(() => {
		let newValue = getValueFromTask();
		if (newValue !== value)
			setValue(newValue);
	}, [task.fields[enabledField.id]])

	return (
		<td key={enabledField.id} class="field" onClick={() => {
			if (enabledField.readOnly)
				return;
			
			setIsEditing(true);
		}}>
			{isEditing ?
					<Input
						value={value === null ? "" : value}
						onChange={(newValue) => {
							setValue(newValue);
							setField(enabledField.id, task.$ID, newValue);
							setIsEditing(false);
							return newValue;
						}}
						readOnly={enabledField.readOnly}
						selectOnFocus={true}
					/>
				:
					value
			}
		</td>
	);
}

export default TextField;