import { store } from '../_helpers/store';
import { stateConstants } from '../_constants/state.constants';
import { determineSortDate } from '../_reducers/todos.reducer'
import findIndex from 'lodash/findIndex'
import { hideInToDoList } from '../_reducers/workflow.reducer'

export const filterService = {
  getFilteredTodos
};

function sortTodos(first, second) {
  if (first.DueDateGroup < second.DueDateGroup) return -1;
  if (first.DueDateGroup > second.DueDateGroup) return 1;

  if ((first.hasPriority()) || (second.hasPriority())) {
    if (!(first.hasPriority()) && (second.hasPriority())) return 1;
    if (!(second.hasPriority()) && (first.hasPriority())) return -1;
    if (first.getPriority() > second.getPriority()) return -1;
    if (first.getPriority() < second.getPriority()) return 1;
  }

  const firstDueDate = determineSortDate(first);
  const secondDueDate = determineSortDate(second);
  if (firstDueDate < secondDueDate) return -1;
  if (firstDueDate > secondDueDate) return 1;

  if (parseInt(first.$ID, 10) < parseInt(second.$ID, 10)) return -1;
  if (parseInt(first.$ID, 10) > parseInt(second.$ID, 10)) return 1;

  return 0;
}

function stringSearch(object, searchValue) {
  let found = false;
  for (var property in object.fields) {
    if (object.fields.hasOwnProperty(property)) {
        if (typeof object.fields[property] === 'string') {
          if (object.fields[property].toUpperCase().includes(searchValue.toUpperCase())) 
            found=true;
        }
    }
    if (found) 
      break;
  }
  return found;
}

function getFilteredTodos(todos, filter) {
  let mytodos = todos;
  const state = store.getState();
  if (state.collapseState.iscollapsed) {
    filter = {
      duedatefilter: [], 
      statusfilter: [], 
      priorityfilter: [], 
      projectfilter: [], 
      stringfilter: ''
    };
  }

  mytodos = mytodos.filter(todo => {
    return todo.getProperty('WorkflowStatus')?!hideInToDoList(todo.$ProjectID, todo.getProperty('Workflow'), todo.getProperty('WorkflowStatus')):true;
  })
  if (filter.duedatefilter && filter.duedatefilter.length > 0) {
    mytodos = mytodos.filter(todo => {
      return findIndex(filter.duedatefilter, function(o) { return o === todo.DueDateGroup; } ) >= 0;
    })
  }
  if (filter.statusfilter && filter.statusfilter.length > 0) {
    mytodos = mytodos.filter(todo => {
      return findIndex(filter.statusfilter, function(o) { return o === todo.getProperty('Status'); } ) >= 0;
    })
  }
  if (filter.priorityfilter && filter.priorityfilter.length > 0) {
    mytodos = mytodos.filter(todo => {
      let myPriorityField = 'SprintPriority';
      if (todo.getProperty('BugPriority') !== null && todo.getProperty('SprintPriority') === null) {
        myPriorityField = 'BugPriority';
      }
      return findIndex(filter.priorityfilter, function(o) { return o === todo.getProperty(myPriorityField); } ) >= 0;
    })
  }
  if (filter.projectfilter && filter.projectfilter.length > 0) {
    const state = store.getState();
    const matchingProjects = state.projects.filter(project => findIndex(filter.projectfilter, function(o) { return o === project.Name; } ) >= 0);
    let projectIDArray = [];
    if (matchingProjects.length > 0) {
      for (let myIndex = 0; myIndex < matchingProjects.length; ++myIndex) {
        projectIDArray.push(matchingProjects[myIndex].id.toString());
      }
    }
    mytodos = mytodos.filter(todo => {
      return (projectIDArray.indexOf( todo.$ProjectID ) >= 0)
    })
  }
  if (filter.stringfilter && filter.stringfilter !== '') {
    mytodos = mytodos.filter(todo => {
      return stringSearch(todo, filter.stringfilter)
    })
  }
  mytodos.sort(sortTodos);
  if (!state.appState.loadingMode && state.appState.filterMode) {
    store.dispatch({type: stateConstants.CLEAR_FILTER_MODE });
  }
  return mytodos;
}
