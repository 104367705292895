import { convertToRaw } from 'draft-js';

import {setField} from '../../DDPJS/DDPJS';
import WYSIWYGEditor from '../../Fields/WYSIWYGEditor'
import { convertRawContentToTaggedText } from"../../_services/TaggedTextToDraftjs";

export default class MultilineEditor extends WYSIWYGEditor {

  getContentsAsTaggedText() {
    return convertRawContentToTaggedText(convertToRaw(this.state.editorState.getCurrentContent()));
  }

  onBlur(event, editorState) {
    const taggedText = convertRawContentToTaggedText(convertToRaw(editorState.getCurrentContent()));
    if (this.state.value === taggedText) {
      return;
    } else {
      this.setState({value: taggedText, originalValue: taggedText})
    }

    if (this.props.onBlur)
      this.props.onBlur(taggedText);
    else
      setField(this.props.fieldID, this.props.id, taggedText);
  }

}
