import { todoConstants } from '../_constants/todo.constants';
import { projectConstants } from '../_constants/project.constants';
import { extractFromString } from '../_helpers/projectid_parser';
import { CLEAR_CACHE } from '../_constants/general.constants';
import { findSprint } from '../_reducers/sprint.reducer'
import { getProjectInfo } from '../_reducers/project.reducer';
import { findWorkflow } from '../_reducers/workflow.reducer';
import { store } from '../_helpers/store';
import { isValidObject } from '../_helpers/validation_functions'

export function determineSortDate(todo) {
  const projectData = getProjectInfo(todo.$ProjectID);
  let mySprint = null;

  if (todo.getProperty('LinkedToSprint') !== null && projectData[1])
    mySprint = findSprint(projectData[1].id, todo.getProperty('LinkedToSprint'));

  if  (mySprint)
    return mySprint.End.$date;
  else {
    const timeZones = todo.getProperty('TimeZones');
    if (timeZones !== null) {
      let latestTimeZone = 0;
      for (let iter = 0; iter < timeZones.length; iter++) {
        if (timeZones[iter].TimeZoneEnd.$date > latestTimeZone) {
          latestTimeZone = timeZones[iter].TimeZoneEnd.$date;
        }
      }
      if (latestTimeZone > 0)
        return latestTimeZone;
      else
        return Number.MAX_VALUE;
    }
    return Number.MAX_VALUE;
  }
}

function buildObject(todo, action) {
  let newObject = {
    ...todo,
    $ID: action.fields.$ID,
    $ProjectID: action.fields.$ProjectID,
    fields: {}
  };
  Object.assign(newObject.fields, action.fields);
  delete newObject.fields["$ID"];
  delete newObject.fields["$ProjectID"];
  newObject.getProperty = function(property) {
    if ('fields' in this) {
      if (isValidObject(this.fields[property]))
        return this.fields[property];
      else
        return null;
    } else {
      return null;
    }
  }
  newObject.hasStatus = function() {
    if ('fields' in this) {
      return 'Status' in this.fields;
    } else {
      return false;
    }
  }
  newObject.hasSprintPriority = function() {
    if ('fields' in this) {
      return 'SprintPriority' in this.fields;
    } else {
      return false;
    }
  }
  newObject.hasBugPriority = function() {
    if ('fields' in this) {
      return 'BugPriority' in this.fields;
    } else {
      return false;
    }
  }
  newObject.hasPriority = function() {
    return this.hasSprintPriority() || this.hasBugPriority()
  }
  newObject.getPriority = function() {
    if (this.hasSprintPriority()) {
      return this.getProperty('SprintPriority')
    } else if (this.hasBugPriority()) {
      return this.getProperty('BugPriority')
    }
  }
  newObject.hasComment = function() {
    if ('fields' in this) {
      return 'Comment' in this.fields;
    } else {
      return false;
    }
  }
  newObject.hasWorkflow = function() {
    if ('fields' in this) {
      if (this.fields.Workflow === undefined || this.fields.Workflow < 0)
        return false;

      const projectInfo = getProjectInfo(this.$ProjectID);
      const workflow = findWorkflow(projectInfo[1] === null ? this.$ProjectID : projectInfo[1].id, this.fields.Workflow);
      if (!workflow || workflow.WorkflowType !== "workflow")
        return !this.isBacklogTask(); // Pipelines not valid on backlog tasks

      return true;
    } else {
      return false;
    }
  }
  newObject.isInSprint = function() {
    if ('fields' in this) {
      return ('LinkedToSprint' in this.fields && this.fields.LinkedToSprint >= 0);
    } else {
      return false;
    }
  }
  newObject.getSprintID = function() {
    if ('fields' in this) {
      if ('LinkedToSprint' in this.fields) {
        return this.fields.LinkedToSprint;
      }
    }
    return -1;
  }
  newObject.isPipelineTask = function() {
    if ('fields' in this) {
      if ('LinkedToPipeline' in this.fields) {
        return this.fields.LinkedToPipeline;
      }
    }
    return false;
  }
  newObject.isDelegatedTo = function() {
    if ('fields' in this) {
      if ('IsDelegatedTo' in this.fields) {
        return this.fields.IsDelegatedTo;
      }
    }
    return false;
  }
  newObject.isUserStory = function() {
    if ('fields' in this) {
      if ('UserStoryFlag' in this.fields) {
        return this.fields.UserStoryFlag;
      }
    }
    return false;
  }
  newObject.isWatching = function(resourceId) {
    if (!this.fields || !this.fields.CommentsOptions)
      return false;

    for (const entry of this.fields.CommentsOptions) {
      if (entry[0] === 1 && entry[1] === resourceId)
        return true;
    }
    
    return false;
  }
  newObject.toggleWatching = function(resourceId) {
    if (this.isWatching(resourceId)) {
      let currentIndex = -1;
      let foundIndex = -1;
      for (const entry of this.fields.CommentsOptions) {
        ++currentIndex;
        if (entry[0] === 1 && entry[1] === resourceId) {
          foundIndex = currentIndex;
          break;
        }
      }
      if (foundIndex > -1) {
        this.fields.CommentsOptions.splice(foundIndex, 1);
      }
    } else {
      this.fields.CommentsOptions.push([1, resourceId])
    }
    return this.fields.CommentsOptions;
  }

  function projectIsOfType(taskId, projectId, type) {
    const projectData = getProjectInfo(projectId);
    if (isValidObject(projectData[0])) {
      const projectType = projectData[0].Type;
      if (projectType === type) {
        return true;
      }
    } else {
      console.log('Could not find project data for todo item with ID: ' + taskId + ' ProjectID:' + projectId)
    }
    return false;   
  }

  newObject.isQATask = function() {
    return projectIsOfType(this.$ID, this.$ProjectID, "QA");
  }
  newObject.isBacklogTask = function() {
    return projectIsOfType(this.$ID, this.$ProjectID, "Backlog");
  }

  return newObject;
}

function updateObject(todo, action) {
  Object.assign(todo.fields, action.fields);
  return todo;
}

function todoNotInStore(todos, id) {
  const indexFound = todos.findIndex(todo => todo.$ID === id)
  return (indexFound === -1)
}

const todos = (state = [], action) => {
  switch (action.type) {
    case todoConstants.ADD_TODO:
      let mytodo = {};
      if (todoNotInStore(state, action.fields.$ID)) {
        return [
          ...state,
          buildObject(mytodo, action)
        ]
      } else {
        return state
      }
    case todoConstants.UPDATE_TODO:
      if (action.fields && action.fields.ChangedColumns) {
        return state.map(todo => {
          let modifiedTask = todo;

          for (let iColumn = 0; iColumn < action.fields.ChangedColumns.length; ++iColumn) {
            const oldId = action.fields.ChangedColumns[iColumn].OldID;
            const newId = action.fields.ChangedColumns[iColumn].NewID;

            modifiedTask.fields[newId] = modifiedTask.fields[oldId];
          }
 
          return modifiedTask;
        });
      } else if (action.cleared && action.cleared.length >= 1) {
        return state.map(todo => {
          for (const clearedField of action.cleared)
            delete todo.fields[clearedField];
          
          return todo;
        });
      }

      return state.map(todo => {
        const mytodo = (todo.$ID === action.id)
          ? updateObject(todo, action)
          : todo;
        return mytodo;
      })
    case projectConstants.REMOVE_PROJECT:
        const projectId = extractFromString("ProjectMeta_", action.collection);
        let todos = [];
        for (let todo of state) {
          if (parseInt(todo.$ProjectID) !== projectId)
            todos.push(todo);
        }
        return todos;   
    case todoConstants.REMOVE_TODO:
      const myindex = state.findIndex(todo => todo.$ID === action.id)
      if (myindex >= 0) {
        return [
          ...state.slice(0, myindex),
          ...state.slice(myindex + 1)
        ];
      } else {
        return state;
      }
    case todoConstants.REMOVE_SPECIFIC_TASK:
      const taskIndex = state.findIndex(todo => todo.$ID === action.id);
      if (taskIndex >= 0) {
        // If we are not assigned to it, then remove it from the cache
        const task = state[taskIndex];
        if (task.fields.ResourceAllocationFirst) {
          if (isTaskVisibleInTodo(task, action.loggedInResourceId))
            return state;

          return [
            ...state.slice(0, taskIndex),
            ...state.slice(taskIndex + 1)
          ];
        }
      }
      return state;
    case todoConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state;  
    default:
      return state
  }
}

export function isTaskVisibleInTodo(task, resourceId) {
  if (task.fields.ResourceAllocationFirst) {
    for (const assignment of task.fields.ResourceAllocationFirst) {
      if (assignment[0] === resourceId && task.fields.Status !== 4 && !task.fields.Archived && (task.fields.CommittedToProjectID !== undefined || !task.isBacklogTask()))
        return true;
    }
  }

  return false;
}

export function isCurrentTodoIsUserStory(id) {
  const state = store.getState()
  const todoFound = state.todos.find(todo => todo.$ID === id)
  if (typeof todoFound === "undefined") 
    return false;
  return todoFound.isUserStory();
}


export default todos