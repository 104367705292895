import React, { Component } from 'react';
import Modal from 'react-modal';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import './semantic/dist/semantic.min.css';
import moment from 'moment'
import './App.css';

import { history } from './_helpers/history';
import { alertActions } from './_actions/alert.constants';
import { PrivateRoute } from './_components/PrivateRoute';
import { DetailsRoute } from './_components/DetailsRoute';
import Login from './Login/Login';
import Layout from './MyWork/Layout';
import IDLayout from './ItemDetail/IDLayout';
import { flagReconnect, reconnectHansoft } from './DDPJS/DDPJS';
import { doesRefreshTokenExist } from './_tokens/refresh.token';

import "./palette.scss";
import DashboardsRoute from './dashboards/dashboardsroute';
import DashboardsContainer from './dashboards/containers/dashboards';

Modal.setAppElement('#root');

export function getThemeName() {
  return localStorage.getItem("theme") || "dark";
}

class App extends Component {
  constructor(props) {
    super(props);

    if (doesRefreshTokenExist()) {
      console.log('Reconnecting to Hansoft')
      reconnectHansoft();
    }

    const { dispatch } = this.props;
    history.listen((location, action) => {
        // clear alert on location change
        dispatch(alertActions.clear());
    });

    
    try {
      require('moment/locale/' + navigator.language);
      moment.locale(navigator.language)
    } catch(error) {
      try {
        if (navigator.language.indexOf("-") !== -1) {
          require('moment/locale/' + navigator.language.split("-")[0]);
          moment.locale(navigator.language.split("-")[0])
        }
      } catch (innerError) {
        
      }
    }

    this.handleRefresh = this.handleRefresh.bind(this);
    this.onToggleTheme = this.onToggleTheme.bind(this);
    this.onToggleCoverImages = this.onToggleCoverImages.bind(this);

    this.state = { 
      flagReconnect: flagReconnect,
      theme: getThemeName(),
      showCoverImages: this.getShowCoverImages(),
    };
  }

  getShowCoverImages() {
    const showCoverImages = localStorage.getItem("showCoverImages");
    if (showCoverImages === undefined || showCoverImages === null)
      return false;
    return showCoverImages === "true";
  }

  onToggleTheme() {
    localStorage.setItem("theme", getThemeName() === "dark" ? "light" : "dark");
    this.setState({
      theme: getThemeName(),
    });
  }

  onToggleCoverImages() {
    localStorage.setItem("showCoverImages", !this.getShowCoverImages());
    this.setState({
      showCoverImages: this.getShowCoverImages(),
    });    
  }

  handleRefresh(e) {
    console.log('Browser refresh');
    this.state.flagReconnect();

    var message = ''; 
    return message;
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleRefresh);
  }

  render() {  
    return (
      <div className={`app theme--${getThemeName()}`}>
        <Router history={history}>
          <div style={{height: "100%"}}>
              <PrivateRoute exact path="/" component={Layout} onToggleTheme={this.onToggleTheme} onToggleCoverImages={this.onToggleCoverImages} showCoverImages={this.state.showCoverImages}/>
              <Route path="/login" component={Login} />
              <DetailsRoute path="/task/:databaseGUIDHash/:id" component={IDLayout} onToggleTheme={this.onToggleTheme} onToggleCoverImages={this.onToggleCoverImages} showCoverImages={this.state.showCoverImages}/>
              <DashboardsRoute exact path="/dashboards" component={DashboardsContainer} theme={this.state.theme} onToggleTheme={this.onToggleTheme} onToggleCoverImages={this.onToggleCoverImages} showCoverImages={this.state.showCoverImages}/>
              <DashboardsRoute path="/dashboards/:databaseGUIDHash/:id" component={DashboardsContainer} theme={this.state.theme} onToggleTheme={this.onToggleTheme} onToggleCoverImages={this.onToggleCoverImages} showCoverImages={this.state.showCoverImages}/>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentuser: state.currentuser
})

export default App = connect(mapStateToProps)(App);
