import { store } from "../_helpers/store";
import { getProjectInfo } from "../_reducers/project.reducer";
import { isCurrentTodoIsUserStory } from '../_reducers/todos.reducer'

export function getDisplayNameForCustomField(fieldID, projectID) {
	const state = store.getState();
	const projectData = getProjectInfo(projectID);

	let mainProjectID = -1;
	if (projectData[0] && projectData[1] && (projectData[0].Type === "Backlog" || projectData[0].Type === "QA"))
		mainProjectID = projectData[1].id;

	const singleLineField = state.singlelinefields.find(field => field.id === fieldID && (field.projectID === projectID || field.projectID === mainProjectID));
	if (singleLineField)
		return singleLineField.DisplayName;
  
	const multiLineField = state.multilinefields.find(field => field.id === fieldID && (field.projectID === projectID || field.projectID === mainProjectID));
	if (multiLineField)
		return multiLineField.DisplayName;
  
	return fieldID;
}

export function getDisplayNameForDefaultField(field) {
	const state = store.getState();
	if (field === "DetailedDescription" && isCurrentTodoIsUserStory(state.appState.currentTaskID)) {
		return "UserStory";
	} else {
		return field;
	}
}