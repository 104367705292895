import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { store } from "../../_helpers/store";
import { history } from '../../_helpers/history';
import { stateConstants } from "../../_constants/state.constants";
import ChartsContainer from "./charts";
import TitleBar from "../../TitleBar/TitleBar";
import DashboardHeaderContainer from "./dashboardheader";
import { subscribeWithParams, unsubscribeFromSubscriptionId } from "../../DDPJS/DDPJS";
import { Loader } from "semantic-ui-react";

class DashboardsContainer extends React.Component {
	constructor(props) {
		super(props);

		let pageId = (this.props.match && this.props.match.params && this.props.match.params.id) || null;
		if (!pageId && this.props.appState)
			pageId = this.props.appState.currentDashboardID || null;
		
		this.state = {
			initialLoadDone: false,
			pageId: pageId,
			databaseGUIDHash: (this.props.match && this.props.match.params && this.props.match.params.databaseGUIDHash) || null,
			subscriptionId: subscribeWithParams("Dashboards", [], {
				onReady: () => {
					this.setCurrentDashboardId(this.state.pageId, this.state.databaseGUIDHash);

					this.setState({
						initialLoadDone: true,
					});
				},
			}),
		};
	}

	setCurrentDashboardId(requestedPageId, databaseGUIDHash) {
		let pageId = requestedPageId ? parseInt(requestedPageId) : null;

		if (this.props.appState.isconnected) {
			if (!pageId || !(this.props.dashboards && this.props.dashboards.find(dashboard => dashboard.id === pageId))) {
				pageId = null;
				if (this.props.dashboards.length > 0)
					pageId = this.props.dashboards[0].id;
			}
		}

		store.dispatch({
			type: stateConstants.SET_CURRENT_DASHBOARD_ID,
			databaseGUIDHash: databaseGUIDHash,
			currentDashboardID: pageId,
		});
	}

	componentWillUnmount() {
		if (this.state.subscriptionId)
			unsubscribeFromSubscriptionId(this.state.subscriptionId);
	}

	componentDidUpdate(prevProps) {
		if (!this.props.match.params.id && this.props.appState.currentDashboardID) {
			history.push(`/dashboards/${this.props.appState.databaseGUIDHash}/${this.props.appState.currentDashboardID}`);
			return;
		}

		if (this.props.match.params.id === prevProps.match.params.id)
			return;

		this.setCurrentDashboardId(this.props.match.params.id, this.props.appState.databaseGUIDHash);
	}

    render() {
		if (!this.props.appState.hasDashboardsAccess)
			return <Redirect to={"/"}/>;

		return (
			<div className="dashboards">
				<TitleBar {...this.props} currentRoute="dashboards"/> 
				{this.state.initialLoadDone ?
						<div style={{
							display: "flex",
							flexFlow: "column nowrap",
							flex: "1 1 auto",
							overflow: "hidden",
						}}>

							<DashboardHeaderContainer 
								dashboards={this.props.dashboards}
								databaseGUIDHash={this.props.appState.databaseGUIDHash}
								pageId={this.props.appState.currentDashboardID}
								theme={this.props.theme}
							/>
							<ChartsContainer 
								dashboard={this.props.dashboards.find(dashboard => dashboard.id === this.props.appState.currentDashboardID)}
								theme={this.props.theme}
							/>
						</div>					
					:
						<Loader/>
				}
			</div>
		);
    }
}

const mapStateToProps = state => ({
	appState: state.appState,
	dashboards: state.dashboards,
});

export default DashboardsContainer = connect(mapStateToProps)(DashboardsContainer);