import React from 'react';
import { EditorState, convertFromRaw, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import { convertTaggedTextToDraftjs } from "../_services/TaggedTextToDraftjs";
import {ImgUnderline, ImgBold, ImgItalics, ImgColor, ImgErase, ImgBulletList, ImgNumberedList, ImgLink, ImgCode } from '../_images/images';
import * as VC from "../_services/versioncontrol";
import { findResource, getResourceArrayForProject } from "../_reducers/resource.reducer";
import { sortUsers } from "../Fields/AllocationsField";

class CodeIcon extends React.Component {
 toggleCode: Function = (): void => {
    const { editorState, onChange } = this.props;
    const newState = RichUtils.toggleInlineStyle(
      editorState,
      'CODEBLOCK',
    );
    if (newState) {
      onChange(newState);
    }
  };

  render() {
    let isActive = this.props.editorState.getCurrentInlineStyle().toList().toJS().indexOf("CODEBLOCK") >= 0;
    let classNames = ["rdw-option-wrapper", "multilinetoolbar-icon"];
    if (isActive)
      classNames.push("rdw-option-active");

    return (
      <div className="rdw-code-wrapper">
        <div className={classNames.join(" ")} aria-selected={isActive ? "true" : "false"} onClick={() => this.toggleCode()}>
          <img src={ImgCode} alt="" title="Code"/>
        </div>
      </div>
    );
  }
}

class AtomicBlock extends React.Component {
  render() {
    const entity = this.props.contentState.getEntity(
      this.props.block.getEntityAt(0),
    );
    const { width } = entity.getData();
    const type = entity.getType();

    if (type === "IMAGE") {
      return (
        <img src={entity.data.src} alt="" style={{ maxWidth: width, width: "100%" }}/>
      );
    }

    return null;
  }
}

export default class WYSIWYGEditor extends React.Component {
  constructor(props) {
    super(props);

    this.versionControlFileInfo = this.versionControlFileInfo.bind(this);

    const rawState = convertTaggedTextToDraftjs(this.props.fieldValue, this.versionControlFileInfo);
    const contentState = rawState.blocks.length >= 1 ? convertFromRaw(rawState) : null;

    this.state = { 
      editorState: contentState ? EditorState.createWithContent(contentState) : undefined,
      value: this.props.fieldValue,
      originalValue: this.props.fieldValue
    };

    this.onEditorStateChange = this.onEditorStateChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.setEditorReference = this.setEditorReference.bind(this)
  }

  versionControlFileInfo(tokenUrl) {
    const urlParts = tokenUrl.split("/");
    return VC.mapFileIDToInfo(this.props.task, parseInt(urlParts[urlParts.length - 1]));
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.fieldID !== this.props.fieldID ) ||
      (prevState.originalValue !== this.props.fieldValue)) {
        const rawState = convertTaggedTextToDraftjs(this.props.fieldValue, this.versionControlFileInfo);
        const contentState = rawState.blocks.length >= 1 ? convertFromRaw(rawState) : null;

        this.setState({ 
          editorState: contentState ? EditorState.createWithContent(contentState) : undefined,
          value: this.props.fieldValue,
          originalValue: this.props.fieldValue
        });
     }
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  }

  setEditorReference(ref) {
    if ('onRef' in this.props && typeof this.props.onRef === 'function') {
      this.props.onRef( ref )
    }
  }

  onBlur(event, editorState) {

  }

  render() {
    const { editorState } = this.state;

    // we don't want to edit the link target in any way, so skip all processing and return
    function myLinkCallback(data) {
      return data
    }

    const iconClassName = "multilinetoolbar-icon";

    let mentionResources = [];
    if (this.props.task) {
      const projectResources = getResourceArrayForProject(this.props.task.$ProjectID);
      for (const projectResource of projectResources) {
        const resource = findResource(this.props.task.$ProjectID, projectResource.ID);
        if (!resource || resource.isSDKUser())
          continue;

        mentionResources.push({
          text: resource.Name,
          value: resource.Name,
          url: resource.id,
          sortValue: resource.sortName, 
        });
      }
    }

    mentionResources.sort(sortUsers);

    return (
      <div className="multiline-editor">
        <Editor
          readOnly={this.props.readOnly}
          toolbarHidden={this.props.readOnly}
          toolbarClassName="multlilinetoolbar"
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          editorStyle={EditorStyle}
          onBlur={this.onBlur}
          editorRef={this.setEditorReference}
          stripPastedStyles={true}
          blockStyleFn={(contentBlock) => {
            const type = contentBlock.getType();
            if (type === "ordered-list-item" || type === "unordered-list-item")
              return "hs-list-item";
          }}
          customBlockRenderFunc={(contentBlock) => {
            const type = contentBlock.getType();
            if (type === "atomic") {
              return {
                component: AtomicBlock,
                editable: false,
              };
            }
          }}
          customStyleMap={{
            CODEBLOCK: {
              fontFamily: "Courier New",
            },
          }}
          mention={{
            separator: ' ',
            trigger: '@',
            suggestions: mentionResources,
          }}
          toolbarCustomButtons={[<CodeIcon/>]}
          toolbar={{
            options: ['inline', 'colorPicker', 'remove', 'list', 'link'], 
            inline: { 
              inDropdown: false, 
              options: ['bold', 'underline', 'italic'],
              bold: { icon: ImgBold, className: iconClassName },
              underline: { icon: ImgUnderline, className: iconClassName },
              italic: { icon: ImgItalics, className: iconClassName },
            },
            colorPicker: {
              icon: ImgColor,
              className: iconClassName,
              colors: [
                "rgb(255,255,255",
                "rgb(38,38,38)",
                "rgb(254,68,47)",
                "rgb(238,126,68)",
                "rgb(244,172,58)",
                "rgb(18,147,111)",
                "rgb(94,223,227)",
                "rgb(38,185,252)",
                "rgb(156,38,252)",
                "rgb(255,112,184)",
                "rgb(157,121,110)",
              ],
            },
            remove: {
              icon: ImgErase,
              className: iconClassName,
            },
            list: { 
              inDropdown: false,
              options: ['unordered', 'ordered'],
              unordered: { icon: ImgBulletList, className: iconClassName },
              ordered: { icon: ImgNumberedList, className: iconClassName }, 
            },
            link: { 
              inDropdown: false, linkCallback:myLinkCallback, options: ['link'],
              link: { icon: ImgLink, className: iconClassName },
            },
          }}
        />
      </div>
    )
  }
}

const EditorStyle = {
  height: '250px',
  margin: "4px",
}