import React from "react";

import BuiltInSingleSelect from "../components/BuiltInSingleSelect";
import { getEnumIcon } from "../../_images/images";
import { setField } from "../../DDPJS/DDPJS";

export default class EnumFieldContainer extends React.Component {     
	getEnumList(field) {
		if (field.getFieldDefinition().Type === "Enum")
			return field.getFieldDefinition().Enum;
		else if (field.getFieldDefinition().Type === "InlineEnum")
			return field.getValue().Enum;
	}

	getIcon(field, $FieldID) {
		const fieldEnum = this.getEnumList(field);
		for (let index = 0; index < fieldEnum.length; ++index) {
			if (fieldEnum[index][0] === field.state.value)
				return getEnumIcon(this.getIconIndex(fieldEnum[index], $FieldID), $FieldID);
		}		
	}

	getText(field) {
		if (!('Enum' in field.getFieldDefinition()))
			return "";

		const fieldEnum = this.getEnumList(field);
		for (let index = 0; index < fieldEnum.length; ++index) {
			if (fieldEnum[index][0] === field.state.value)
				return fieldEnum[index][1];
		}

		return "";
	}

	getIconIndex(enumObject, $FieldID) {
		if ($FieldID.indexOf("CC_") === 0) {
			return enumObject[2];
		} else {
			return enumObject[0];
		}
	}

	render() {
		return (
			<BuiltInSingleSelect
				task={this.props.task}
				$FieldID={this.props.$FieldID}
				getOptions={(field) => {
					const fieldEnum = this.getEnumList(field);
					let options = [];

					for (let index = 0; index < fieldEnum.length; ++index) {
						const value = fieldEnum[index][0];

						options.push({
							value: value,
							selected: value === field.state.value,
							image: getEnumIcon(this.getIconIndex(fieldEnum[index], this.props.$FieldID), this.props.$FieldID),
							text: fieldEnum[index][1],
						});
					}

					return options;
				}}
				getIcon={(field) => {
					return this.getIcon(field, this.props.$FieldID);
				}}
				getText={(field) => {
					return this.getText(field);
				}}
				isListView={this.props.isListView}
				onSelectionChanged={(field, newValue) => {
					if (field.readOnlyFlag())
						return;
		
					field.setState({newValue});

					let sValue = newValue;
					if (typeof newValue !== "string")
						sValue = newValue.toString();

					setField(field.getFieldDefinition().id, field.getItemID(), sValue);
				}}
			/>
		);
	}
}