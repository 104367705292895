import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { history } from "../_helpers/history";

import { logoffHansoft, clearAuthToken, hasDashboardsAccess } from '../DDPJS/DDPJS';
import BackLinkControl from '../ItemDetail/BackLinkControl';
import { deleteRefreshToken } from '../_tokens/refresh.token';
import { ImgLogoCollapsed, ImgFunnel } from "../_images/images";

import "./titlebar.scss";
import TaskCreateModal from '../tasks/taskcreate';
import { getThemeName } from '../App';

class TitleBar extends React.Component {
  constructor(props) {
    super(props);
    this.onLogout = this.onLogout.bind(this);
    this.navigateToRoute = this.navigateToRoute.bind(this);
  }

  state = {
    profileMenuOpen: false,
    createTaskIsOpen: false,
  };

  onLogout() {
    logoffHansoft();
    clearAuthToken();
    deleteRefreshToken();
    this.props.history.push(`/login`)
  }

  onHelpClick() {
    window.open('https://help.perforce.com/hns/web/index.htm');
  }

  navigateToRoute(route) {
    this.props.history.push(route)
  }

  render () {
    const { currentuser } = this.props
    let username = currentuser.username

    const resource = this.props.resources.find(function(resource) { return resource.Name.toUpperCase() === this.username.toUpperCase()}, {username: currentuser.username})
    if (typeof resource !== 'undefined') {
      username = resource.Name
    }

    const filterIsOpen = !this.props.collapseState.iscollapsed;

    return (
      <div className="titlebar">
          <div className="title">
            <img className="logo" src={ImgLogoCollapsed} alt=""/>
          {this.props.backToList ?
              <BackLinkControl/>
            :
              <div style={{display: "flex", flexDirection: "row nowrap"}}>
                <div className={`titlebar-button ${this.props.currentRoute === "todo" ? "is-active" : ""} js-todolist`} onClick={() => this.navigateToRoute("/")}>To-Do List</div>
                {hasDashboardsAccess() ?
                    <div className={`titlebar-button ${this.props.currentRoute === "dashboards" ? "is-active" : ""} js-dashboards`} onClick={() => this.navigateToRoute("/dashboards")}>Dashboards</div>
                  :
                    null
                }
                <div className="titlebar-button is-primary js-create-bug" onClick={() => {
                  this.setState({
                    createTaskIsOpen: true
                  });
                }}>
                  New bug
                </div>
              </div>
          }            
          </div>
          <div className="controls">
            {this.props.onToggleFilter ?
                <div className="titlebar-button" onClick={this.props.onToggleFilter}>
                  <img src={ImgFunnel} alt=""/>{filterIsOpen ? "Close filter" : "Filter" }
                </div>
              :
                null
            }
            <Dropdown className="profilemenu js-account-menu" text={username}>
              <Dropdown.Menu>
                <Dropdown.Item className="js-menu-theme" onClick={this.props.onToggleTheme} text="Change theme"/>
                <Dropdown.Item className="js-menu-coverimages" onClick={this.props.onToggleCoverImages} text={this.props.showCoverImages ? "Hide cover images":  "Show cover images"}/>
                <Dropdown.Item onClick={this.onHelpClick} text="Help..."/>
                <Dropdown.Divider/>
                <Dropdown.Item className="js-menu-logout" onClick={this.onLogout} text="Log out"/>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Modal
            isOpen={this.state.createTaskIsOpen}
            className={`theme--${getThemeName()}`}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                padding: "64px 128px",
                overflow: "auto",
                zIndex: 999,
              },
              content: {
                borderRadius: "8px",
                outline: "none",
                maxWidth: "900px",
                margin: "0 auto",
              },
            }}
            onRequestClose={() => {
              this.setState({
                createTaskIsOpen: false,
              });
            }}
          >
            <TaskCreateModal
              onCancel={() => {
                this.setState({
                  createTaskIsOpen: false,
                });
              }}
              onCreated={(createdTaskId) => {
                this.setState({
                  createTaskIsOpen: false,
                });

                if (createdTaskId)
                  history.push(`/task/${this.props.appState.databaseGUIDHash}/${createdTaskId}`);
              }}
            />
          </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  appState: state.appState,
  currentuser: state.currentuser,
  collapseState: state.collapseState,
  filteroptions: state.filteroptions,
  resources: state.resources
})

export default TitleBar = connect(mapStateToProps)(TitleBar);