import React from 'react';
import { connect } from 'react-redux';

import MyWork from './MyWork'
import TitleBar from "../TitleBar/TitleBar"
import MessageDialog from '../MessageDialog/MessageDialog'
import { triggerMessageDialog } from '../MessageDialog/MessageUtilities'
import { collapseConstants } from '../_constants/collapse.constants';

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.onToggleFilter = this.onToggleFilter.bind(this)
  }

  onToggleFilter(event) {
    const { dispatch } = this.props;
    dispatch({type: collapseConstants.TOGGLE});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errormessage.iserror)
      triggerMessageDialog(this.props.errormessage.iserror)
  }

  componentDidMount() {
    triggerMessageDialog(this.props.errormessage.iserror);
  }

  render() {
     return (
      <div style={{height: "100%"}}>
        <TitleBar {...this.props} currentRoute="todo" callbackFromParent={this.toggleVisibility} onToggleFilter={this.onToggleFilter} onToggleTheme={this.props.onToggleTheme}/>  
        <div style={{display: "flex", overflow: "auto", height: "100%"}}>
          <MessageDialog />
          <MyWork {...this.props}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentuser: state.currentuser,
  collapseState: state.collapseState,
  errormessage: state.errormessage,
  appState: state.appState
})

export default Layout = connect(mapStateToProps)(Layout);