import { store } from '../_helpers/store';

import { errormessageConstants } from '../_constants/errormessage.constants';
import { MessageEnum } from './MessageIndex'

const errorDialogTimer = 5000;

export function triggerMessageDialog(display) {
  if (display) {
    window.setTimeout(function() {clearErrorMessage(); }, errorDialogTimer)
  }
}

function isMessageSet() {
  const state = store.getState();
  return state.errormessage.iserror;
}

export function clearErrorMessage() {
  if (isMessageSet()) { 
    store.dispatch({type: errormessageConstants.CLEAR_ERROR_MESSAGE });
  }
}

export function setErrorMessage(msgLevel, messageID ) {
  if (!isMessageSet()) {
    store.dispatch({type: errormessageConstants.SET_ERROR_MESSAGE, messageID: messageID, msgLevel: msgLevel});
  }
}

export function setCustomMessage(msgLevel, customMessage ) {
  if (!isMessageSet()) {
    store.dispatch({type: errormessageConstants.SET_CUSTOM_MESSAGE, messageID: MessageEnum.CUSTOM_MESSAGE, customMessage: customMessage, msgLevel: msgLevel});
  }
}

