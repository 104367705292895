import React from "react";
import PropTypes from "prop-types";

export default class Relation extends React.Component {
	render() {
		let linkElement = null;
		if (this.props.link) {
			if (this.props.download)
				linkElement = <a className="relation-link" href={this.props.link} download target="_blank noopener noreferrer">{this.props.text}</a>;
			else
				linkElement = <a className="relation-link" href={this.props.link} target="_blank noopener noreferrer">{this.props.text}</a>;
		}

		return (
			<div className="relation js-relation">
				{this.props.icon ?
						<img className="relation-icon" src={this.props.icon} alt=""/>
					:
						null
				}				
				<div className="relation-text-container">
					<div className="relation-text">
						{this.props.link ?							
								linkElement
							:
								this.props.text
						}
					</div>
					{this.props.subText ?
							<div className="relation-subtext">{this.props.subText}</div>
						:
							null
					}
				</div>
			</div>
		);
	}
}

Relation.propTypes = {
	icon: PropTypes.string,
	text: PropTypes.string,
	subText: PropTypes.string,
	link: PropTypes.string,
	download: PropTypes.bool,
};