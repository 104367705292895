import React from "react";
import { connect } from "react-redux";

import { subscribeToChartResultSet, unsubscribeFromSubscriptionId } from "../../DDPJS/DDPJS";
import ResultSetChart from "../components/chart";

class ChartContainer extends React.Component {
    state = {
        subscriptionId: null,
    };

    componentDidMount() {
        this.setState({
            subscriptionId: subscribeToChartResultSet(this.props.id),
        });
    }

    componentWillUnmount() {
        if (this.state.subscriptionId)
            unsubscribeFromSubscriptionId(this.state.subscriptionId);
    }

    render() {
        return <ResultSetChart {...this.props}/>;
    }
}

const mapStateToProps = (state, props) => {
    return {
        chartResultSet: state.chartResultSets.find(chartResultSet => chartResultSet.id === props.id),
    };
}

export default ChartContainer = connect(mapStateToProps)(ChartContainer);