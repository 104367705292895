import { store } from "../_helpers/store";

// EToken
const EToken_None = 0;
const EToken_Text = 1;
const EToken_URL = 2;
const EToken_Image = 3;
const EToken_Attribs = 4;
const EToken_Quote = 5;
const EToken_OrderedList = 6;
const EToken_UnorderedList = 7;
const EToken_ListItem = 8;
const EToken_Code = 9;
// UNUSED: const EToken_Action = 10;
const EToken_NumTypes = 11;

// EToken_Flag
const ETokenFlag_None = 0;
const ETokenFlag_Pop = 1;
const ETokenFlag_Set_Color = 2;
const ETokenFlag_Set_Bold = 4;
const ETokenFlag_Set_Underline = 8;
// UNUSED: const ETokenFlag_Set_Font = 16;
const ETokenFlag_Set_Italic = 32;
const ETokenFlag_Set_BGColor = 64;

// EAttribFlag
const EAttribFlag_None = 0;
const EAttribFlag_Bold = 1;
const EAttribFlag_Underline = 2;
const EAttribFlag_Italic = 3;

const tagToFlag = {
	"bold": ETokenFlag_Set_Bold,
	"italic": ETokenFlag_Set_Italic,
	"underline": ETokenFlag_Set_Underline,
	"color": ETokenFlag_Set_Color,
	"bgcolor": ETokenFlag_Set_BGColor,
};

const tagToToken = {
	"ol": EToken_OrderedList,
	"ul": EToken_UnorderedList,
	"li": EToken_ListItem,
	"code": EToken_Code,
};

const tagToAttrib = {
	"bold": EAttribFlag_Bold,
	"italic": EAttribFlag_Italic,
	"underline": EAttribFlag_Underline,
};

const knownTags = [
	"url",
	"image",
	"color",
	"bold",
	"underline",
	"font",
	"quote",
	"ol",
	"ul",
	"li",
	"italic",
	"bgcolor",
	"code",
	"action",
];

function parseText(options) {
	if (options.parseIndex >= options.taggedText.length)
		return false;

	let endParseIndex = options.parseIndex + 1;
	while ((endParseIndex < options.taggedText.length - 1) && options.taggedText[endParseIndex] !== "<")
		++endParseIndex;

	options.nextToken.type = EToken_Text;
	options.nextToken.flags = ETokenFlag_None;
	options.nextToken.text = options.taggedText.substring(options.parseIndex, endParseIndex);

	options.parseIndex = endParseIndex;

	return true;
}

function parseTag(options) {
	let endParseIndex = options.parseIndex + 1;
	while ((endParseIndex < options.taggedText.length - 1) && options.taggedText[endParseIndex] !== ">")
		++endParseIndex;

	if (endParseIndex === 0)
		return false;

	let tagStartIndex = options.parseIndex + 1;
	let closeTag = false;

	if (options.taggedText[tagStartIndex] === "/") {
		closeTag = true;
		++tagStartIndex;
	}

	const tagContents = options.taggedText.substring(tagStartIndex, endParseIndex);
	const tagName = tagContents.split("=")[0];

	const foundTagIndex = knownTags.indexOf(tagName.toLowerCase());
	if (foundTagIndex === -1)
		return false;

	const foundTag = knownTags[foundTagIndex];

	options.nextToken.text = "";
	options.nextToken.type = EToken_None;
	options.nextToken.attribFlags = EAttribFlag_None;
	options.nextToken.flags = ETokenFlag_None;

	if (!closeTag) {
		switch (foundTag) {
			case "bold":
			case "italic":
			case "underline": {
				options.nextToken.type = EToken_Attribs;
				options.nextToken.flags = tagToFlag[foundTag];
				options.nextToken.attribFlags = tagToAttrib[foundTag];
				break;
			}
			case "color":
			case "bgcolor": {
				options.nextToken.type = EToken_Attribs;
				options.nextToken.flags = tagToFlag[foundTag];
				const colorParts = tagContents.split("=")[1].split(",");

				options.nextToken.colorRed = colorParts[0];
				options.nextToken.colorGreen = colorParts[1];
				options.nextToken.colorBlue = colorParts[2];
				break;
			}
			case "url": {
				options.nextToken.type = EToken_URL;
				options.nextToken.url = tagContents.split("=")[1];
				break;
			}
			case "ul":
			case "ol":
			case "li":
			case "code": {
				options.nextToken.type = tagToToken[foundTag];
				break;
			}
			case "image": {
				options.nextToken.type = EToken_Image;
				options.nextToken.url = tagContents.split("=")[1];
				break;
			}
			default:
				break;
		}
	} else {
		switch(foundTag) {
			case "bold":
			case "italic":
			case "underline": {
				options.nextToken.type = EToken_Attribs;
				options.nextToken.flags = tagToFlag[foundTag] | ETokenFlag_Pop;
				options.nextToken.attribFlags = tagToAttrib[foundTag];
				break;
			}
			case "color":
			case "bgcolor": {
				options.nextToken.type = EToken_Attribs;
				options.nextToken.flags = tagToFlag[foundTag] | ETokenFlag_Pop;
				break;
			}
			case "url": {
				options.nextToken.type = EToken_URL;
				options.nextToken.flags = ETokenFlag_Pop;
				break;
			}
			case "ul":
			case "ol":
			case "li":
			case "code": {
				options.nextToken.type = tagToToken[foundTag];
				options.nextToken.flags = ETokenFlag_Pop;
				break;
			}
			case "image": {
				options.nextToken.type = EToken_Image;
				options.nextToken.flags = ETokenFlag_Pop;
				break;
			}
			default:
				break;
		}
	}

	options.parseIndex = endParseIndex + 1;

	return true;
}

function parseNextToken(options) {
	if (options.parseIndex >= options.taggedText.length)
		return false;

	if (options.taggedText[options.parseIndex] === "<") {
		if (parseTag(options))
			return true;
	}

	return parseText(options);
}

function tokeniseTaggedText(taggedText) {
	if (!taggedText)
		return [];

	let token = {
		type: EToken_None,
		text: "",
	};

	let tokens = [];

	let options = {
		parseIndex: 0,
		nextToken: {
			type: EToken_None,
			text: "",
		},
		taggedText,
	};

	while (parseNextToken(options)) {
		if (token.type === EToken_Text && options.nextToken.type === EToken_Text) {
			token.text += options.nextToken.text;
		} else {
			if (token.type !== EToken_None)
				tokens.push(Object.assign({}, token));

			Object.assign(token, options.nextToken);
		}
	}

	if (token.type !== EToken_None)
		tokens.push(Object.assign({}, token));

	return tokens;
}

export function convertTaggedTextToDraftjs(taggedText, versionControlFileInfo) {
	const blocks = [];
	let entityMap = {};

	let tokens = tokeniseTaggedText(taggedText);
	
	let text = "";
	let formatStack = [];
	let entityRangeStack = [];
	
	let openCounts = new Array(EToken_NumTypes);
	for (let index = 0; index < EToken_NumTypes; ++index)
		openCounts[index] = 0;

	function buildFormat(token) {
		if (token.type === EToken_Code) {
			return {
				offset: text.length,
				style: "CODEBLOCK",
			};
		} else if (token.flags & ETokenFlag_Set_Bold && token.attribFlags & EAttribFlag_Bold) {
			return {
				offset: text.length,
				style: "BOLD",
			};
		} else if (token.flags & ETokenFlag_Set_Underline && token.attribFlags & EAttribFlag_Underline) {
			return {
				offset: text.length,
				style: "UNDERLINE",
			}
		} else if (token.flags & ETokenFlag_Set_Italic && token.attribFlags & EAttribFlag_Italic) {
			return {
				offset: text.length,
				style: "ITALIC",
			};
		} else if (token.flags & ETokenFlag_Set_Color) {
			return {
				offset: text.length,
				style: `color-rgb(${token.colorRed},${token.colorGreen},${token.colorBlue})`,
			};
		} else if (token.flags & ETokenFlag_Set_BGColor) {
			return {
				offset: text.length,
				style: `bgcolor-rgb(${token.colorRed},${token.colorGreen},${token.colorBlue})`,
			};		
		}
	}

	let inlineStyleRanges = [];
	let entityRanges = [];
	let pendingListType = EToken_None;

	function flushBlock(type) {
		if (text !== "") {
			blocks.push({
				text: text,
				type: type ? type : "unstyled",
				depth: 0,
				inlineStyleRanges: inlineStyleRanges,
				entityRanges: entityRanges,
			});

			if (blocks.length > 1 && (type === "unordered-list-item" || type === "ordered-list-item")) {
				const prevBlock = blocks.length - 2;
				if (blocks[prevBlock].type === "unstyled" && blocks[prevBlock].text[blocks[prevBlock].text.length - 1] === "\n")
					blocks[prevBlock].text = blocks[prevBlock].text.substring(0, blocks[prevBlock].text.length - 1);
			}
		}

		text = "";
		inlineStyleRanges = [];
		entityRanges = [];
	}

	for (let token of tokens) {
		if (openCounts[EToken_Quote]) {
			if (token.type === EToken_Quote) {
				if (token.flags & ETokenFlag_Pop)
					--openCounts[EToken_Quote];
				else
					++openCounts[EToken_Quote];
			} else {
				continue;
			}
		}

		if (!(token.flags & ETokenFlag_Pop)) {
			switch (token.type) {
				case EToken_None:
					break;
				case EToken_Text: {
					if (openCounts[EToken_Quote])
						break;
					if (openCounts[EToken_Image]) {
						const entityKey = Object.keys(entityMap).length - 1;
						entityMap[entityKey].data.text += token.text;
						break;
					}
					if (token.text === "\n" && text.length === 0 && blocks.length >= 1) {
						if (blocks[blocks.length - 1].type === "unordered-list-item" || 
							blocks[blocks.length - 1].type === "ordered-list-item") {
								break; // Skip new lines at the end of a <LI> tag.
						}
					}

					text += token.text.replace(/\\>/g, ">").replace(/\u2028/g, "\n");
					break;
				}
				case EToken_Attribs:
				case EToken_Code: {
					formatStack.push(buildFormat(token));
					++openCounts[token.type];
					break;
				}
				case EToken_URL: {
					const entityKey = Object.keys(entityMap).length;

					entityRangeStack.push({
						key: entityKey,
						offset: text.length,
					});

					const isMention = token.url.indexOf("hansoft://") === 0 && token.url.indexOf("/UserID/") !== -1;
					if (isMention) {
						entityMap[entityKey] = {
							data: {
								url: token.url,
								targetOption: "_blank",
							},
							mutability: "IMMUTABLE",
							type: "MENTION",
						};
					} else {
						entityMap[entityKey] = {
							data: {
								url: token.url,
								targetOption: "_blank",
							},
							mutability: "MUTABLE",
							type: "LINK",
						};
					}

					++openCounts[token.type];
					break;
				}
				case EToken_OrderedList:
				case EToken_UnorderedList: {
					flushBlock();
					pendingListType = token.type;
					++openCounts[token.type];
					break;
				}
				case EToken_ListItem: {
					++openCounts[token.type];
					break;
				}
				case EToken_Image: {
					flushBlock();

					const entityKey = Object.keys(entityMap).length;

					blocks.push({
						text: " ",
						type: "atomic",
						depth: 0,
						inlineStyleRanges: [],
						entityRanges: [{
							key: entityKey,
							offset: 0,
							length: 1,
						}],
					});

					const fileInfo = versionControlFileInfo ? versionControlFileInfo(token.url) : null;

					entityMap[entityKey] = {
						data: {
							src: fileInfo ? fileInfo.url : token.url,
							url: token.url,
							alt: "",
							width: fileInfo ? `${fileInfo.width}px` : "0px",
							text: "",
						},
						mutability: "IMMUTABLE",
						type: "IMAGE",
					};

					++openCounts[token.type];
					break;
				}
				default:
					break;
			}
		} else {
			switch (token.type) {
				case EToken_None:
				case EToken_Text:
					break;
				case EToken_Attribs:
				case EToken_Code: {
					if (openCounts[token.type]) {
						--openCounts[token.type];
						let format = formatStack.pop();
						format.length = text.length - format.offset;
						inlineStyleRanges.push(format);
					}
					break;
				}
				case EToken_URL: {
					if (openCounts[token.type]) {
						--openCounts[token.type];
						let entity = entityRangeStack.pop();
						entity.length = text.length - entity.offset;
						entityRanges.push(entity);
					}
					break;
				}
				case EToken_Image: {
					if (openCounts[token.type])
						--openCounts[token.type];
					break;
				}
				case EToken_OrderedList:
				case EToken_UnorderedList: {
					if (openCounts[token.type]) {
						--openCounts[token.type];
						pendingListType = EToken_None;
					}
					break;
				}
				case EToken_ListItem: {
					if (openCounts[token.type]) {
						--openCounts[token.type];
						flushBlock(pendingListType === EToken_UnorderedList ? "unordered-list-item" : "ordered-list-item");
					}
					break;
				}
				default:
					break;
			}
		}
	}

	flushBlock();

	if (Object.keys(entityMap).length === 0) {
		entityMap = {
			data: "",
			mutability: "",
			type: "",
		};
	}

	return {
		blocks,
		entityMap,
	};
}

export function convertRawContentToTaggedText(rawContent) {
	const state = store.getState();

	let taggedText = "";
	
	function extractColorParts(style) {
		let colors = style.split("(")[1];
		return colors.substring(0, colors.length - 1).split(",");
	}

	let pendingListType = EToken_None;
	let previousBlock;

	let tagsToParseIndex = {};
	let closeTagsToParseIndex = {};

	function pushTag(offset, tag, close) {
		let map = close ? closeTagsToParseIndex : tagsToParseIndex;

		if (map[offset] === undefined)
			map[offset] = [];
	
		map[offset].push(tag);			
	}

	function flushTagsForIndex(index) {
		if (closeTagsToParseIndex[index] !== undefined) {
			while (closeTagsToParseIndex[index].length > 0)
				taggedText += closeTagsToParseIndex[index].pop();
		}	

		if (tagsToParseIndex[index] !== undefined) {
			while (tagsToParseIndex[index].length > 0)
				taggedText += tagsToParseIndex[index].pop();
		}			
	}

	function processRange(range, oldIndex) {
		if (range.offset > oldIndex)
			range.offset++;
		if (oldIndex >= range.offset && oldIndex < range.offset + range.length)
			range.length++;
	}
	
	for (let block of rawContent.blocks) {	
		tagsToParseIndex = {};

		let escapedText = block.text.replace(/>/g, "\\>");
		if (escapedText !== block.text) {
			if (block.entityRanges.length > 0 || block.inlineStyleRanges.length > 0) {
				let escapedCount = 0;
				for (let index = 0; index < escapedText.length; ++index) {
					const oldIndex = index - escapedCount;
					if (escapedText[index] === block.text[oldIndex])
						continue;
	
					for (let entity = 0; entity < block.entityRanges.length; ++entity)
						processRange(block.entityRanges[entity], index);
	
					for (let style = 0; style < block.inlineStyleRanges.length; ++style)
						processRange(block.inlineStyleRanges[style], index);
					
					++escapedCount;
				}
			}
		}

		if (pendingListType !== EToken_None) {
			if (block.type !== "unordered-list-item" && block.type !== "ordered-list-item") {
				taggedText += pendingListType === EToken_UnorderedList ? "</UL>" : "</OL>";
				pendingListType = EToken_None;			
			} else if (pendingListType === EToken_OrderedList && block.type === "unordered-list-item") {
				taggedText += "</OL>";
				pendingListType = EToken_None;		
			} else if (pendingListType === EToken_UnorderedList && block.type === "ordered-list-item") {
				taggedText += "</UL>";
				pendingListType = EToken_None;		
			}
		} else if (previousBlock && previousBlock.type === "unstyled" && block.type === "unstyled") {
			taggedText += "\n";
		}

		if (block.type === "unordered-list-item" || block.type === "ordered-list-item") {
			if (pendingListType === EToken_None) {
				taggedText += block.type === "unordered-list-item" ? "<UL>" : "<OL>";
				pendingListType = block.type === "unordered-list-item" ? EToken_UnorderedList : EToken_OrderedList;
			}	
			
			taggedText += "<LI>";
		}

		for (const entityRange of block.entityRanges) {
			let openTag;
			let closeTag;

			const entity = rawContent.entityMap[entityRange.key];
			if (entity.type === "LINK") {
				openTag = `<URL=${entity.data.url}>`;
				closeTag = "</URL>";
			} else if (entity.type === "IMAGE") {
				taggedText += `<IMAGE=${entity.data.url}>${entity.data.text}</IMAGE>`;
			} else if (entity.type === "MENTION") {
				if (entity.data.url.indexOf("hansoft://") === 0)
					openTag = `<URL=${entity.data.url}>`;
				else
					openTag = `<URL=${state.appState.databaseServerURL}UserID/${entity.data.url}>`;
				closeTag = "</URL>";
			}
			
			if (openTag)
				pushTag(entityRange.offset, openTag, false);
			if (closeTag)
				pushTag(entityRange.offset + entityRange.length, closeTag, true);
		}

		for (const style of block.inlineStyleRanges) {
			let openTag;
			let closeTag;

			if (style.style.indexOf("color") === 0) {
				const colorParts = extractColorParts(style.style);
				openTag = `<COLOR=${colorParts[0]},${colorParts[1]},${colorParts[2]}>`;
				closeTag = "</COLOR>";
			} else if (style.style.indexOf("bgcolor") === 0) {
				const colorParts = extractColorParts(style.style);
				openTag = `<BGCOLOR=${colorParts[0]},${colorParts[1]},${colorParts[2]}>`;
				closeTag = "</BGCOLOR>";
			} else {
				switch (style.style) {
					case "BOLD": {
						openTag = "<BOLD>";
						closeTag = "</BOLD>";
						break;
					}
					case "ITALIC": {
						openTag = "<ITALIC>";
						closeTag = "</ITALIC>";
						break;
					}
					case "UNDERLINE": {
						openTag = "<UNDERLINE>";
						closeTag = "</UNDERLINE>";
						break;
					}
					case "CODEBLOCK": {
						openTag = "<CODE>";
						closeTag = "</CODE>";
						break;
					}
					default:
						break;
				}
			}

			if (openTag)
				pushTag(style.offset, openTag, false);

			if (closeTag)
				pushTag(style.offset + style.length, closeTag, true);
		}

		if (block.type !== "atomic" && escapedText !== " ") {
			let parseIndex = 0;
			for (; parseIndex < Array.from(escapedText).length; ++parseIndex) {
			
				flushTagsForIndex(parseIndex);
				taggedText += Array.from(escapedText)[parseIndex];
			}
	
			flushTagsForIndex(parseIndex);
	
			if (block.type === "unordered-list-item" || block.type === "ordered-list-item")
				taggedText += "</LI>\n";
		}

		previousBlock = block;
	}

	if (pendingListType !== EToken_None)
		taggedText += pendingListType === EToken_UnorderedList ? "</UL>" : "</OL>";

	return taggedText;
}