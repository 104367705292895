import React from "react";
import PropTypes from "prop-types";

import BaseField from "../BaseField";
import SingleSelectField from "./SingleSelect";
import { setField } from "../../DDPJS/DDPJS";
import { isValidObject } from '../../_helpers/validation_functions'

export default class BuiltInSingleSelect extends BaseField {
	constructor(props) {
		super(props);
		this.onSelectionChanged = this.onSelectionChanged.bind(this);
	}

	state = {
		value: this.getValue(),
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.value !== this.getValue())
			this.setState({value: this.getValue()});
	}

	onSelectionChanged(newValue) {
		if (this.props.onSelectionChanged) {
			this.props.onSelectionChanged(this, newValue);
			return;
		}

		if (this.readOnlyFlag())
			return;

		this.setState({newValue});
		setField(this.getFieldDefinition().id, this.getItemID(), newValue);
	}

	render() {
		if (!isValidObject(this.getFieldDefinition()))
			return (null);

		const isListView = ('isListView' in this.props) ? this.props.isListView : false;

		return (
			<SingleSelectField
				fieldName={!isListView ? this.getFieldDefinition().DisplayName : null}
				disabled={this.readOnlyFlag()}
				isRequiredField={this.isWorkflowRequiredField()}
				icon={this.props.getIcon(this)}
				text={this.props.getText(this)}
				options={this.props.getOptions(this)}
				onSelectionChanged={this.onSelectionChanged}
			/>
		);
	}
}

BuiltInSingleSelect.propTypes = {
	getOptions: PropTypes.func.isRequired,
	getIcon: PropTypes.func.isRequired,
	getText: PropTypes.func.isRequired,
	isListView: PropTypes.bool,
	onSelectionChanged: PropTypes.func,
};