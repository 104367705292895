import React from 'react';
import { Tab } from 'semantic-ui-react';

import { getProjectInfo } from '../../_reducers/project.reducer'
import { getUniqueFieldForType } from '../../_helpers/itemdetail_functions'
import MultilineEditor from './MultilineEditor';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default class MultilineTab extends React.Component {

  constructor(props) {
    super(props);
    this.handleTabChange = this.handleTabChange.bind(this)
  }


  state = {
    currentTaskID: this.props.task.$ID,
    activeIndex: 0
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.currentTaskID !== this.props.task.$ID) {
      this.setState({currentTaskID: this.props.task.$ID, activeIndex: 0});
    }
  }

  showUserStoryField(fieldID) {
    if (fieldID === 'DetailedDescription') {
      return this.props.task.getProperty('UserStoryFlag');
    }
    return true;
  }

  sortByFieldName(first, second) {
    if (first.id === 'StepsToReproduce') return -1
    if (second.id === 'StepsToReproduce') return 1
    if (first.DisplayName.toUpperCase() < second.DisplayName.toUpperCase()) return -1
    if (first.DisplayName.toUpperCase() > second.DisplayName.toUpperCase()) return 1
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })

  render() {
    const { task, multilinefields } = this.props;
    let panes = [];
   
    const projectData = getProjectInfo(task.$ProjectID);
    let myProjectID = projectData[1] !== null?projectData[1].id:0;
    if ((projectData[0].Type === 'QA' || projectData[0].Type === 'Backlog') && !task.isInSprint()) {
      myProjectID = parseInt(task.$ProjectID, 10)
    }

    let myMultilineFields = multilinefields.filter(field => field.projectID === myProjectID).sort(this.sortByFieldName);
    
    if (projectData[0].Type === 'QA' && task.isInSprint()) {
      const QAProjectID = parseInt(task.$ProjectID, 10);
      myMultilineFields = myMultilineFields.concat(multilinefields.filter(function (field) {
        if (field.projectID !== QAProjectID)
          return false;
        if (myMultilineFields.filter(existingField => existingField.id === field.id).length >= 1)
          return false;

        return true;
      }));
      myMultilineFields.sort(this.sortByFieldName);
    }

    const fieldlen = myMultilineFields.length;

    const projectType = projectData[0].Type;
    const uniqueField = getUniqueFieldForType(projectType);

    for (let iter = 0; iter < fieldlen; iter++) {
      if (uniqueField !== null && myMultilineFields[iter].id === uniqueField[0]) {
        continue;
      }

      const fieldName = myMultilineFields[iter].DisplayName;
      let fieldValue = '';
      if (myMultilineFields[iter].id in task.fields) {
        fieldValue = task.fields[myMultilineFields[iter].id];
      }
      const fieldData = {
        id: task.$ID, 
        fieldID: myMultilineFields[iter].id, 
        readOnly: myMultilineFields[iter].ReadOnly, 
        fieldValue: fieldValue
      };

      if (this.showUserStoryField(myMultilineFields[iter].id)) {
        panes.push({menuItem: fieldName, 
          render: () => <Tab.Pane style={BackgroundStyle} attached={false}><MultilineEditor {...fieldData } /></Tab.Pane>})
      }
    }

    return (
      <div style={MultilineTabStyle} id='multiline_link'>
        {panes.length > 0
          ?<Tab style={BackgroundStyle} menu={MenuStyle} panes={panes} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
          :<br />
        }
      </div>
    );
  }
}

const MultilineTabStyle = {
  width: '100%', 
}

const BackgroundStyle = {
  margin: '0',
  padding: '0',
  backgroundColor: 'transparent',
}

const MenuStyle = {
  color: 'black', 
  inverted: true, 
  secondary: true, 
  pointing: true,
}