import React from "react";
import { Popup } from "semantic-ui-react";

import { ImgArrowDown } from "../../_images/images";
import { ImgArrowDownWhite } from "../../_images/images";
import FloatingSelection from "../../Fields/components/FloatingSelection";
import "./dashboardheader.scss";
import { getLoggedInResourceID } from "../../DDPJS/DDPJS";

class DashboardHeader extends React.Component {
	constructor(props) {
		super(props);
		this.onSelectionChanged = this.onSelectionChanged.bind(this);
		this.toggleDashboardMenu = this.toggleDashboardMenu.bind(this);
		this.state = {
			dashboardMenuOpen: false,
		};
	}

	onSelectionChanged(newValue) {
		this.props.onSelectDashboard(newValue);
		this.toggleDashboardMenu(false);
	}

	toggleDashboardMenu(open) {
		this.setState({
			dashboardMenuOpen: open,
		});
	}

    render() {
		const loggedInUserId = getLoggedInResourceID();
		const selectedDashboard = this.props.dashboards.find(dashboard => dashboard.id === this.props.selectedDashboardId);
		
		function getOptions(dashboards) {
			if (!dashboards || dashboards.length === 0)
				return [];

			let options = dashboards.map(dashboard => {
				return {
					value: dashboard.id,
					text: dashboard.name.length >= 1 ? dashboard.name : "(No name)",
					subText: dashboard.createdBy && dashboard.createdByUserId !== loggedInUserId ? dashboard.createdBy : null,
				};
			});
	
			options.sort(function(dashboardA, dashboardB) {
				return dashboardA.text.localeCompare(dashboardB.text, undefined, { caseFirst: "upper" });
			});
			
			return options;
		};

		let options = getOptions(this.props.dashboards.filter(dashboard => dashboard.createdByUserId === loggedInUserId));
		let sharedDashboards = getOptions(this.props.dashboards.filter(dashboard => dashboard.createdByUserId !== loggedInUserId));

		if (options.length >= 1) {
			if (sharedDashboards.length >= 1) {
				options.push({
					seperator: true,
				});
	
				options = options.concat(sharedDashboards);
			}
		} else {
			options = sharedDashboards;
		}

		return (
			<div className="dashboard-header js-dashboard-header">
				<div 
					className="title js-title"
					onClick={() => this.toggleDashboardMenu(true)}
					ref={element => this.dashboardHeaderRef = element}
				>
					<div className="text">{selectedDashboard ? (selectedDashboard.name.length >= 1 ? selectedDashboard.name : "(No name)") : "Please select a dashboard..."}</div>
					<img src={this.props.theme === "dark" ? ImgArrowDownWhite : ImgArrowDown} alt=""/>				
				</div>
				<div className="spacer"></div>
				<Popup
					open={this.state.dashboardMenuOpen}
					content={<FloatingSelection options={options} onSelectionChanged={this.onSelectionChanged} onClose={() => this.toggleDashboardMenu(false)}/>}
					context={this.dashboardHeaderRef}
					onClose={() => this.toggleDashboardMenu(false)}
					position="bottom center"
				>
				</Popup>
			</div>
		);
    }    
}

export default DashboardHeader;