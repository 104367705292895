import React from 'react';
import isEqual from 'lodash/isEqual'

import BaseField from './BaseField'
import { getEJSONDateValue } from '../_EJSON/EJSON_Date'
import { formatYYYYMMDD } from '../_helpers/date_time_format'
import ItemDetailsField from './components/ItemDetailsField';

export default class TimeZoneField extends BaseField {

  state = {
    value: this.props.task.getProperty('TimeZones')
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isEqual(prevState.value, this.props.task.getProperty('TimeZones')) === false) {
      this.setState({value: this.props.task.getProperty('TimeZones')});
    }
  }

  getDateString() {
    let formattedDueDate = '';

    if (this.props.task.getProperty('TimeZones') !== null && this.props.task.fields.TimeZones.length > 0) {
      let value = 0;
      if (this.props.$FieldID === 'TimeZoneStart')
        value = getEJSONDateValue(this.props.task.fields.TimeZones[0], this.props.$FieldID);
      else if (this.props.$FieldID === 'TimeZoneEnd')
        value = getEJSONDateValue(this.props.task.fields.TimeZones[this.props.task.fields.TimeZones.length - 1], this.props.$FieldID);
      const dueDate = new Date(value)
      formattedDueDate = formatYYYYMMDD(dueDate);
    }

    return formattedDueDate;
  }

  getDisplayName() {
    if (this.props.$FieldID === 'TimeZoneStart') {
      return 'Start'
    } else if (this.props.$FieldID === 'TimeZoneEnd') {
      return 'Finish'
    } else {
      return this.props.$FieldID
    }
  }

  render() {
    return (
      <ItemDetailsField
        fieldName={this.getDisplayName()}
        isRequiredField={this.isWorkflowRequiredField()} 
        children={
          <span className="is-disabled">{this.getDateString()}</span>
        }
      />
    );
  }
}