import { collapseConstants } from '../_constants/collapse.constants';
import { CLEAR_CACHE } from '../_constants/general.constants';

const collapseState = (state = { iscollapsed: true }, action) => {
  switch (action.type) {
    case collapseConstants.COLLAPSE:
    case CLEAR_CACHE:
      return {...state, iscollapsed: true }
    case collapseConstants.EXPAND:
      return {...state, iscollapsed: false }
    case collapseConstants.TOGGLE:
      return {...state, iscollapsed: state.iscollapsed ? false: true }
    default:
      return state
  }
}

export default collapseState