import React from "react";

import "./itemview.scss";
import { formatYYYYMMDD } from "../../_helpers/date_time_format";

class ItemView extends React.Component {
    render() {
		return (
			<div className="itemview" style={{maxHeight: this.props.maxHeight, minHeight: this.props.maxHeight}}>
				{!this.props.data || this.props.data.columns.length === 0 ?
						"No data here"
					:
						<table>
							<tbody>
								<tr className="header">
									{this.props.data.columns.map(column => {
										return (
											<td className="cell">{column}</td>
										);
									})}
								</tr>
								{this.props.data.rows.map(row => {
									return (
										<tr className="row">
											{row.map(cell => {
												const dateValue = cell && cell.$date;
												let roundedCell = cell;
												if (roundedCell && typeof roundedCell === "number") {
													if (parseInt(roundedCell) !== roundedCell)
														roundedCell = roundedCell.toFixed(2);
												}

												return <td className="cell">{
													dateValue ? formatYYYYMMDD(new Date(dateValue)) : (roundedCell !== null ? roundedCell : (this.props.data.renderNullValues ? "null" : ""))
												}</td>;
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
				}


			</div>
		);
    }    
}

export default ItemView;