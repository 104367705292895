import assignIn from 'lodash/merge'

import { sprintConstants } from '../_constants/sprint.constants';
import { CLEAR_CACHE } from '../_constants/general.constants';
import { extractFromString } from '../_helpers/projectid_parser';
import { store } from '../_helpers/store';

function buildObject(sprint, id, fields) {
  let newObject = {
    id: id,
    projectID: sprint.projectID,
  };

  assignIn(newObject, fields);

  newObject.getResources = function() {

    if ('Resources' in this) {
      if (Array.isArray(this.Resources)) {
        return this.Resources;
      }
    }

    return null;
  }

  newObject.getAllowMembersToUpdate = function() {

    if ('AllowMembersToUpdate' in this) {
      return this.AllowMembersToUpdate;
    }

    return true;
  }

  return newObject;
}

const sprints = (state = [], action) => {
  let projectID;
  if ('collection' in action) 
    projectID = extractFromString("ProjectSprints_", action.collection);
  switch (action.type) {
    case sprintConstants.ADD_SPRINT:
      let mySprint = { projectID: projectID };
      return [
        ...state,
        buildObject(mySprint, action.id, action.fields)
      ]
    case sprintConstants.UPDATE_SPRINT:
      return state.map(sprint => {
        const mySprint = (sprint.id === action.id && sprint.projectID === projectID)
          ? buildObject(sprint, action.id, action.fields)
          : sprint;
        return mySprint;
      })
    case sprintConstants.REMOVE_SPRINT:
      const myindex = state.findIndex(sprint => sprint.id === action.id && sprint.projectID === projectID)
      if (myindex >= 0) {
        return [
          ...state.slice(0, myindex),
          ...state.slice(myindex + 1)
        ];
      } else {
        return state;
      }
    case sprintConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state;  // MJT_SPRINT future Mike, make sure the memory actually gets freed by losing reference to objects
    default:
      return state
  }
}

export default sprints;

export function findSprint(projectID, sprintID) {
  const state = store.getState();
  let nProjectID = 0
  let sSprintID = '';

  if (typeof projectID === 'string') {
    nProjectID = parseInt(projectID, 10);
  } else if (typeof projectID === 'number') {
    nProjectID = projectID;
  }

  if (typeof sprintID === 'string') {
    sSprintID = sprintID;
  } else if (typeof sprintID === 'number') {
    sSprintID = sprintID.toString();
  }

  const mySprint = state.sprints.find(sprint => sprint.projectID === nProjectID && sprint.id === sSprintID);
  return mySprint;
}