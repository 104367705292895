import React from 'react';
import PropTypes from 'prop-types';

export default class ItemDetailsField extends React.Component {
    render() {
        let classNames = [ "itemdetails-field" ];
        if (this.props.fieldName)
            classNames.push(`js-field-${this.props.fieldName.toLowerCase().replace(/ |\//g, "-")}`);
        
		let fieldClassNames = ["itemdetailsfield-label"];
        if (this.props.isRequiredField)
            fieldClassNames.push("is-required");

        return (
            <div className={classNames.join(" ")}>
                <div className={fieldClassNames.join(" ")}>
                   {this.props.fieldName}
                </div>
                <div className="itemdetails-field-container js-itemdetails-field-container">
                    {this.props.children}
                </div>
            </div>
        );          
    }
}

ItemDetailsField.propTypes = {
    isRequiredField: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
};