function isNull(object) {
  if (object === null) {
    return true;
  }
  return false;
}

function isUndefined(object) {
  if (typeof object === "undefined") {
    return true;
  }
  return false;
}

export function isValidObject(object) {
  if (!isUndefined(object) && !isNull(object)) {
    return true;
  }

  return false;
}

export function isValidNumber(value) {
  const myValue = value.toString();
  if (myValue.match(/^[-+]?[0-9]*$/)) {    // from fatty client
    return true;
  }
  return false;
}

export function isValidDecimal(value) {
  const myValue = value.toString();
  if (myValue.match(/^[-+]?[0-9]*\.?[0-9]+$/)) {    // from fatty client
    return true;
  }
  return false;
}