import { userConstants } from '../_constants/user.constants';
import { CLEAR_CACHE } from '../_constants/general.constants';

const currentuser = (state = {}, action) => {
  switch (action.type) {
    case userConstants.STORE_USERNAME:
      return {...state, username: action.username}
    case CLEAR_CACHE:
      return {}
    default:
      return state
  }
}

export default currentuser