import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Form, Message, Grid } from 'semantic-ui-react'
import '../semantic/dist/semantic.min.css';
import {loginHansoft} from '../DDPJS/DDPJS';

import { userActions } from '../_actions/user.actions';
import { stringConstants } from '../_constants/strings.constants';
import './Login.css'
import { ImgLoginLogo } from '../_images/images'
import MessageDialog from '../MessageDialog/MessageDialog'
import { triggerMessageDialog } from '../MessageDialog/MessageUtilities'
import { history } from "../_helpers/history";

class Copyright extends React.Component {

  render() {
    return (
      <div style={LoginFooterStyle}>
        <ul style={LoginFooterStyle.List}>
          <li>{stringConstants.COPYRIGHT}</li>
          <li><a href={stringConstants.PRODUCT_URL} target='_blank noopener noreferrer' className='Login-Footer-Links' onMouseEnter={this.someHandler}>{stringConstants.PRODUCT_URL_DISPLAY}</a></li>
          <li><a href={'mailto:' + stringConstants.SUPPORT_EMAIL} className='Login-Footer-Links'>Contact Support</a></li>
        </ul>
      </div>
    )
  }
}

let LoginFooterStyle = {
  position: 'absolute',
  right: '0',
  bottom: '0',
  left: '0',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '11px',
  color: '#8a8a8a'
}

LoginFooterStyle.List = {
  listStyleImage: 'none',
  listStylePosition: 'outside',
  listStyleType: 'none'
}

class LoginPage extends React.Component {
    constructor(props) {
      super(props);

      // reset login status
      this.props.dispatch(userActions.logout());

      this.state = {
        username: '',
        password: '',
        submitted: false,
        message: '',
        error: false
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleError = this.handleError.bind(this);
      this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }

    handleSubmit(e) {
      e.preventDefault();

      this.doLogin();
    }

    doLogin() {
      const from = this.props.location.state ? this.props.location.state.from : null;

      this.setState({ submitted: true });
      const { username, password } = this.state;

      loginHansoft(username, password, () => {
        if (from && from.pathname !== "/")
          history.push(from)
        else 
          history.push("/");
      });
    }

    handleError(e) {
      this.setState({value: e.target.value});
    }

    handleKeyPress(e) {
      // "Enter" key
      if (e.keyCode === 13) {
        this.doLogin();
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.errormessage.iserror)
        triggerMessageDialog(this.props.errormessage.iserror)
    }

    componentDidMount() {
      document.addEventListener('keypress', this.handleKeyPress);
      triggerMessageDialog(this.props.errormessage.iserror);
    }
 
    componentWillUnmount() {
      document.removeEventListener('keypress', this.handleKeyPress);
    }

    render() {
      if (localStorage.getItem("user")) {
        return (
          <Redirect to={"/"}/>
        );
      }
      
        const { username, password } = this.state
        const { loginError } = this.props

        let message
        if (loginError.iserror) {
          message = (

              <Message negative style={LoginMessageStyle} className="js-login-message">
                <Message.Content><span style={LoginMessageStyle.Label}>Login Error:&nbsp;</span>{loginError.message}</Message.Content>
                
              </Message>

          )
        }

        const isLoginDisabled = username.length === 0 || password.length === 0

        return (
          <div>
            <MessageDialog />           

            <div style={LoginPageStyle}>

              <Grid textAlign='center'>
              <Grid.Row />
              <Grid.Row />
              <Grid.Row>
                <img src={ImgLoginLogo} alt={"logo"} />
              </Grid.Row>
              { message }
              <Grid.Row>

                <div style={LoginPageStyle.Center}> 
             
                  <Form name="form" onSubmit={this.handleSubmit} error={this.state.error} >
                    <Form.Field className="Login-Form">
                      <input className="Login-Form js-login-username" type="text" name="username" placeholder="Username" value={username} onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field style={LoginPageStyle.Form}>
                      <input className="Login-Form js-login-password" type="password" name="password" placeholder="Password" value={password} onChange={this.handleChange} />
                    </Form.Field>
                    <Button primary type='submit' className="Login-Form Login-Button js-login" disabled={isLoginDisabled}>Log in</Button>
                  </Form>
                </div>
              </Grid.Row>
              </Grid> 
              <Copyright />           
            </div>
          </div>
        );
    }
}

var backgroundImage = require('../_images/login-helix.png')

const LoginMessageStyle = {
  width: '400px'
}

LoginMessageStyle.Label = {
  fontWeight: 'bold'
}

const LoginPageStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: '500',
  backgroundImage: 'url(' + backgroundImage + ')',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#2b2b2d'
}

LoginPageStyle.Center = {
  outline: '1px solid black',
  paddingTop: '30px',
  paddingRight: '30px',
  paddingBottom: '30px',
  paddingLeft: '30px',
  backgroundColor: 'white',
  borderBottom: '4px solid #12936F',
  borderTop: '34px hidden #2b2b2d'
}

LoginPageStyle.Form = {
  display: 'block',
  marginBottom: '20px'
}

LoginPageStyle.Form.Input = {
  ...LoginPageStyle.Form,
  width: '340px !important',
  border: '1px solid #b4bcc3 !important',
  borderRadius: '2px !important',
  borderColor: '#98a1a7 !important'
}

LoginPageStyle.Form.Button = {
  ...LoginPageStyle.Form,
  textAlign: 'right',
  backgroundColor: '#12936F !important'
}

const mapStateToProps = state => ({
  currentuser: state.currentuser,
  loginError: state.loginError,
  errormessage: state.errormessage
})

const Login = connect(mapStateToProps)(LoginPage);

export default Login