import React from "react";

import BuiltInSingleSelect from "../components/BuiltInSingleSelect";
import { ImgPriorityNone, ImgPriorityVeryLow, ImgPriorityLow, ImgPriorityMedium, ImgPriorityHigh, ImgPriorityVeryHigh } from "../../_images/images";

const priorityIcons = [ 
	ImgPriorityNone, 
	ImgPriorityVeryLow, 
	ImgPriorityLow, 
	ImgPriorityMedium, 
	ImgPriorityHigh, 
	ImgPriorityVeryHigh 
];

export default class PriorityFieldContainer extends React.Component {     
    getIcon(value) {
		if (value >= 1 && value <= priorityIcons.length + 1)
			return priorityIcons[value - 1];
		else 
			return null;
    }

    getText(field) {
		try {
			return field.getFieldDefinition().Enum[field.state.value - 1][1]; 
		} catch (error) {
			return "";
		}
    }

    render() {
        return (
            <BuiltInSingleSelect
                task={this.props.task}
                $FieldID={this.props.$FieldID}
                getOptions={(field) => {
                    const fieldEnum = field.getFieldDefinition().Enum;
                    const numItems = fieldEnum ? fieldEnum.length : 0;

                    let options = [];

                    const value = fieldEnum[0][0];

                    options.push({
                        value: value,
                        selected: value === field.state.value,
                        image: this.getIcon(fieldEnum[0][0]),
                        text: fieldEnum[0][1],
                    });

                    for (let index = numItems - 1; index >= 1; --index) {
                        const value = fieldEnum[index][0];

                        options.push({
                            value: value,
                            selected: value === field.state.value,
                            image: this.getIcon(fieldEnum[index][0]),
                            text: fieldEnum[index][1],
						});
                    }

                    return options;
                }}
                getIcon={(field) => {
                    return this.getIcon(field.state.value);
                }}
                getText={(field) => {
                    return this.getText(field);
                }}
                isListView={this.props.isListView}
            />
        );
    }
}