import React from 'react';
import { Segment } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import moment from 'moment'
import '../../node_modules/react-datetime/css/react-datetime.css';

import BaseField from './BaseField'
import FieldStyle from './FieldStyle'
import {setField} from '../DDPJS/DDPJS';
import { createEJSONDateObject, isValidEJSONDateObject, getEJSONDateValue } from '../_EJSON/EJSON_Date'
import { formatYYYYMMDD, formatTime24Hour, getLocaleDateString } from '../_helpers/date_time_format'
import ItemDetailsField from './components/ItemDetailsField';
import { isValidObject } from '../_helpers/validation_functions'

export default class DateTimeField extends BaseField {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  state = {
    value: getEJSONDateValue(this.props.task.fields, this.props.$FieldID),
    redraw: false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.value !== this.state.value) {
      return  
    } else if (isValidEJSONDateObject(this.getValue()) 
      && prevState.value !== getEJSONDateValue(this.props.task.fields, this.props.$FieldID)  ) {
        this.setState({
          value: getEJSONDateValue(this.props.task.fields, this.props.$FieldID)
        });
    } else if (prevState.value > 0 && !isValidEJSONDateObject(this.getValue())) {
      this.setState({
        value: 0
      });
    } 
  }

  onChange = (moment) => {
    try {
      if (this.readOnlyFlag()) {
        return
      }

      if (!isValidObject(moment) || typeof moment === 'string' || !moment.isValid()) {
        return
      }

      moment.utc()
      const dateValue = moment.toDate()
      this.setState({value: dateValue.valueOf()})
    } catch (error) {
      console.error(error)
    }
  }

  onBlur = (moment) => {
    if (this.readOnlyFlag()) {
      return
    }

    if (typeof moment === 'string' && moment.trim() === '') {
      setField(this.getFieldDefinition().id, this.getItemID(), moment);
      this.setState({ value: 0 });
      this.setState({redraw: true})
      return
    }

    if (typeof moment === 'string' || !moment.isValid()) {
      this.setState({ value: 0 });
      this.setState({redraw: true})
      return
    }

    moment.utc()
    const dateValue = moment.toDate()
    this.setState({value: dateValue.valueOf()})
    setField(this.getFieldDefinition().id, this.getItemID(), createEJSONDateObject(dateValue.valueOf()))
  }

  timeFormat() {
    if (this.getFieldDefinition().Type === 'DateTimeTime') {
      return true;
    }
    return false;
  }

  render() {
    if (!isValidObject(this.getFieldDefinition())) {
      return (null);
    }
    
    const dateValue = new Date(this.state.value)
    const myMoment = moment(dateValue)
    const isValid = myMoment.isValid() && this.state.value > 0
    if (!isValid && this.state.value === 0 && this.state.redraw) {
      this.setState({redraw: false})
    }

    if (this.readOnlyFlag()) {
      return (
        <ItemDetailsField
          fieldName={this.getFieldDefinition().DisplayName}
          isRequiredField={this.isWorkflowRequiredField()} 
          children={
            <div style={FieldStyle.ReadOnlyBackgroundStyle}>
              <span style={{...ReadOnlyDateStyle, ...FieldStyle.ReadOnlyFieldStyle}}>
                {isValid ?
                    `${formatYYYYMMDD(dateValue)} ${this.timeFormat()?' ' + formatTime24Hour(dateValue):''}`
                  :
                    ""
                  }
              </span>
            </div>
          }
        />
      );
    } else {
      return (
        <ItemDetailsField
          fieldName={this.getFieldDefinition().DisplayName}
          children={
            <div>
              <Segment style={FieldStyle.FieldSegment}>
                  { isValid ?
                    <Datetime 
                      timeFormat={this.timeFormat()} 
                      dateFormat={getLocaleDateString()}
                      locale={navigator.language}
                      closeOnSelect={true} 
                      value={dateValue} 
                      onChange={this.onChange} 
                      onBlur={this.onBlur}               
                    />
                    : this.state.redraw ?
                      null
                      :<Datetime 
                        timeFormat={this.timeFormat()} 
                        dateFormat={getLocaleDateString()}
                        locale={navigator.language}
                        closeOnSelect={true} 
                        onChange={this.onChange} 
                        onBlur={this.onBlur}
                      />
                  }
              </Segment>
            </div>
          }
        />
      )
    }
  }
}

const ReadOnlyDateStyle = {
  fontFamily: 'Roboto',
  color: '#FFFFFF',
  fontSize: '1rem'
}