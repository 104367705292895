import React from 'react';

import {workflowService} from '../_services/workflow.service'
import {findFieldByProject} from '../_reducers/singleline.reducer'
import { getProjectInfo } from '../_reducers/project.reducer'
import { isValidObject } from '../_helpers/validation_functions'
import { store } from '../_helpers/store';
import { unsubscribeFromSubscriptionId, subscribeWithParams } from "../DDPJS/DDPJS";

export default class BaseField extends React.Component {

  getItemID() {
    return this.props.task.$ID;
  }

  getProjectID() {
    return this.props.task.$ProjectID;
  }

  getPlanningProjectID() {
    const projectInfo = getProjectInfo(this.props.task.$ProjectID);
    if (projectInfo[1] === null)
      return -1;
    else
      return projectInfo[1].id;
  }

  getFieldDefinition() {
    if ('task' in this.props) {
      return findFieldByProject(this.props.$FieldID, this.props.task.$ProjectID, this.getPlanningProjectID());
    } else 
      return {};
  }

  getValue() {
    return this.props.task.getProperty(this.props.$FieldID);
  }

  isWorkflowRequiredField(bCheckForReadOnly) {
    if ('$FieldID' in this.props && 'task' in this.props) {
      return workflowService.markFieldAsRequiredForWorkflowState(
        this.props.$FieldID, 
        this.props.task.$ID, 
        this.props.task.$ProjectID, 
        this.props.task.fields.Workflow,
        typeof bCheckForReadOnly === "undefined"?false:bCheckForReadOnly
      ); 
    }
    return false; 
  }

  readOnlyFlag() {

    // if workflow column this is always read only
    if ('$FieldID' in this.props && this.props.$FieldID === 'Workflow') {
      return true;
    }

    if ('$FieldID' in this.props && this.props.$FieldID === 'Description') {
      if (this.props.task.fields.LinkedToPipelineCreatedFromWorkflowObject)
        return true;
    }

    if (this.props.$FieldID !== "Status" && !this.props.task.isDelegatedTo()) {
      const sprintId = this.props.task.getSprintID();
      if (sprintId) {
        const state = store.getState();
        const sprint = state.sprints.find(sprint => sprint.id === sprintId.toString());
        if (sprint && !sprint.getAllowMembersToUpdate())
          return true;
      }
    }
    
    // if this is board column this is always read only
    if (this.isBoardColumn()) {
      return true;
    }

    // otherwise, if this is required for Workflow, then NOT read only
    if (this.isWorkflowRequiredField(true)) {
      return false;
    }
    
    if ('$FieldID' in this.props && this.props.$FieldID === 'SprintPriority') {
      const projectInfo = getProjectInfo(this.props.task.$ProjectID)
      // if SprintPriority and the CanChangePriorityInToDo is not set, make the field read only
      if (projectInfo[1] !== null && !projectInfo[1].canChangePriorityInToDo()) {
        return true
      }
      // display priority for pipeline tasks, but should not be editable
      if (this.props.task.isPipelineTask()) {
        return true
      }
    }
    
    // otherewise check for generic read only flag on fields
    let bReadOnly = 'ReadOnly' in this.getFieldDefinition()?this.getFieldDefinition().ReadOnly:false;
    if (bReadOnly) {
      if (this.doesUserHaveAccessAndDelegationPrivileges()) {
        return false
      }
    }

    const bIsMilestone = this.props.task.fields.Type === 2;
    const bIsSprint = this.props.task.fields.Type === 1 && this.props.task.fields.LockedType === 3;
    const bIsSubProject = this.props.task.fields.HasChildren;
    const fieldId = this.props.$FieldID;

    switch (fieldId) {
      case "Status":
      case "ResourceAllocationFirst":
      case "WorkRemaining":
      case "ComplexityPoints":
      case "EstimatedIdealDays":
         return bIsMilestone || bIsSprint || bIsSubProject;
      case "LinkedToMilestone":
          return bIsMilestone;
      default: {
        // Fall through
      }
    }

    if (fieldId.indexOf("CC_") === 0) {
      const fieldType = this.getFieldDefinition().Type;
      switch (fieldType) {
        case "Integer":
        case "Float":
        case "Hours":
          return bIsMilestone || bIsSprint || bIsSubProject;
        default: {
          // Fall through
        }
      }
    }

    return bReadOnly;
  }

  doesUserHaveAccessAndDelegationPrivileges() {
    if (this.props.$FieldID.startsWith('CC')) {
      const fieldDef = this.getFieldDefinition()
      if (isValidObject(fieldDef)) {
        if ('AccessByMainManagersAndDelegatedUsers' in fieldDef) {
          if (fieldDef.AccessByMainManagersAndDelegatedUsers) {
            return true
          }
        } else {
          console.log('AccessByMainManagersAndDelegatedUsers not defined in field definition ' + this.props.$FieldID + '.')
        }
      } else {
        console.log('Field definition for ' + this.props.$FieldID + ' not available.')
      }
    }

    return false
  }

  isBoardColumn() {
    if ('$FieldID' in this.props && (this.props.$FieldID === 'BoardCommit_ColumnID' || this.props.$FieldID === 'BoardCommit_LaneID'))
      return true;
    return false;
  }
  onFindTextChanged = (newFindText, subscriptionName, subcriptionParameters) => {
    if (this.state.subscriptionId)
      unsubscribeFromSubscriptionId(this.state.subscriptionId);

    subcriptionParameters.findText = newFindText;
    this.setState( {
      subscriptionId : subscribeWithParams(subscriptionName, subcriptionParameters)
    })
  }
}
