const FieldStyle = {}

FieldStyle.FieldSegment = {
  borderStyle:'none', 
  padding:0, 
}

FieldStyle.RegularField = {
  color: 'white'
}

FieldStyle.RequiredField = {
  color: '#F74F5D'
}

FieldStyle.getFieldStyle = function(workflowRequired) {
  if (workflowRequired)
    return FieldStyle.RequiredField;
  return FieldStyle.RegularField;
}


FieldStyle.RegularControlBorder = {
  width: '100%',
  fontSize: '12px'
}

FieldStyle.RequiredControlBorder = {
  ...FieldStyle.RegularControlBorder,
  borderStyle: 'solid',
  borderColor: '#F74F5D',
  borderWidth: '2px'
}

FieldStyle.getControlBorderStyle = function(workflowRequired) {
  if (workflowRequired)
    return FieldStyle.RequiredControlBorder;
  return FieldStyle.RegularControlBorder;
}

FieldStyle.ReadOnlyFieldStyle = {
  fontFamily: 'Roboto',
  color: "#CCCCCC",
  fontSize: '1rem',
}

FieldStyle.ReadOnlyBackgroundStyle = {
  height: '30px', 
  width: '100%', 
  display: 'flex', 
  alignItems: 'center', 
  paddingLeft: '10px'
}

export default FieldStyle;